import { all } from 'redux-saga/effects';

import dialogData from './dialogDetran';

function* watch() {
  yield all([dialogData.saga.watch()]);
}

export default {
  watch,
};
