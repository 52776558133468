import { createReducer } from '@reduxjs/toolkit';

import initialState from './list.initialState';

import { ACTION_TYPES } from './list.constant';

const handleReset = () => initialState;

const handleResetFilters = (state) => {
  state.filters = initialState.filters;
};

const handleResetPartner = (state) => {
  state.isPartner = initialState.isPartner;
};

const handleResetSelected = (state) => {
  state.selected = initialState.selected;
};

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchEnd = (state, action) => {
  state.loading = false;
  state.data = action.payload;
  state.selected = action.payload?.results || initialState.selected;
  state.error = [false, null];
};

const handleFetchError = (state, action) => {
  state.loading = false;
  state.error = [true, action.payload];
  state.data = initialState.data;
};

const handleUpdateFilters = (state, action) => {
  state.loading = true;
  state.filters = Object.assign({}, state.filters, action.filters);
};

const handleUpdateSelected = (state, action) => {
  state.selected = action.selected;
};

const handleUpdateInstallment = (state, action) => {
  state.installment = action.payload;
};

const handleUpdatPartner = (state, action) => {
  state.isPartner = action.payload;
};

const handleSelect = (state, action) => {
  state.selected = [...state.selected, action.payload];
};

const handleSelectAll = (state) => {
  state.selected = [...(state?.data?.results || [])];
};

const handleUnSelect = (state, action) => {
  state.selected = [
    ...state.selected.filter((s) => s.reference !== action.payload.reference),
  ];
};

const handleUnSelectAll = (state) => {
  state.selected = [];
};

const handlePayDetran = (state) => {
  state.mode = 'detran';
};

const handlePayBillet = (state) => {
  state.mode = 'billet';
};

const handlePayBilletPartner = (state) => {
  state.mode = 'partner';
};

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.STATE]: handleReset,
  [ACTION_TYPES.RESET.FILTERS]: handleResetFilters,
  [ACTION_TYPES.RESET.SELECTED]: handleResetSelected,
  [ACTION_TYPES.RESET.PARTNER]: handleResetPartner,
  [ACTION_TYPES.SELECT]: handleSelect,
  [ACTION_TYPES.UNSELECT]: handleUnSelect,
  [ACTION_TYPES.SELECT_ALL]: handleSelectAll,
  [ACTION_TYPES.UNSELECT_ALL]: handleUnSelectAll,
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.END]: handleFetchEnd,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
  [ACTION_TYPES.UPDATE.FILTERS]: handleUpdateFilters,
  [ACTION_TYPES.UPDATE.SELECTED]: handleUpdateSelected,
  [ACTION_TYPES.UPDATE.PARTNER]: handleUpdatPartner,
  [ACTION_TYPES.UPDATE.INSTALLMENT]: handleUpdateInstallment,
  [ACTION_TYPES.PAY.DETRAN]: handlePayDetran,
  [ACTION_TYPES.PAY.BILLET]: handlePayBillet,
  [ACTION_TYPES.PAY.PARTNER]: handlePayBilletPartner,
});
