import auth from '@giro/shared-store/auth';

import modules from './modules';

const rootSubscribe = (store) => () => {
  modules.subscriber.subscribe(store);
  auth.subscriber.subscribe(store);
};

export default rootSubscribe;
