import reducer from './dialogConfirmEmail.reducer';
import * as action from './dialogConfirmEmail.action';
import * as selector from './dialogConfirmEmail.selector';
import * as constant from './dialogConfirmEmail.constant';
import initialState from './dialogConfirmEmail.initialState';
import subscriber from './dialogConfirmEmail.subscriber';
import saga from './dialogConfirmEmail.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
