import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import AppLayout from '@giro-app/layouts/App.layout';

import PaymentScreen from '@giro-app/modules/payment/screens/Payment.screen';

import ROUTES from '@giro-app/constants/routes.constant';

import checkout from '../checkout/store/checkout';

export default function PaymentModule() {
  const selectorCheckoutRedux = {
    paymentType: useSelector(checkout.selector.selectPaymentType),
    currentStep: useSelector(checkout.selector.selectCurrentStep),
  };

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [selectorCheckoutRedux.currentStep, selectorCheckoutRedux.paymentType]);

  return (
    <AppLayout>
      <Switch>
        <Route exact path={ROUTES.PAYMENT.ROOT} component={PaymentScreen} />
      </Switch>
    </AppLayout>
  );
}
