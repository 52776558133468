import { useMemo } from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';
import { set } from 'lodash';

import * as authCookie from '@giro/shared-utils/authCookie.util';

import rootReducer from './root.reducer';
import rootSubscribe from './root.subscriber';
import rootSaga from './root.saga';

const checkTokenExpirationMiddleware = () => (next) => (action) => {
  const { exp }: any = authCookie.getAuthCookieObj() || {};

  const hasTokenExpired = exp < Date.now() / 1000;

  if (hasTokenExpired) {
    authCookie.deleteAuthCookie();
    window.location.reload();
  }

  next(action);
};

const initSaga = () => {
  const composeWithReduxDevTools =
    (window as any).window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    sagaMiddleware,
    checkTokenExpirationMiddleware,
    LogRocket.reduxMiddleware({
      stateSanitizer: (state) => {
        const newState = { ...state };

        const formDataObj = 'modules.payment.checkout.formData.payment';
        const firstCard = `${formDataObj}.firstCard`;
        const secondCard = `${formDataObj}.secondCard`;

        [firstCard, secondCard].map((c) => {
          set(newState, `${c}.number`, undefined);
          set(newState, `${c}.document`, undefined);
          set(newState, `${c}.exp`, undefined);
          set(newState, `${c}.security_code`, undefined);
          set(newState, `${c}.name`, undefined);
        });

        return newState;
      },
      actionSanitizer: function (action) {
        if (action.type === 'MODULE::PAYMENT::CHECKOUT::UPDATE::FORMDATA') {
          return null;
        }

        return action;
      },
    }),
  ];

  const composedEnhancers = composeWithReduxDevTools(
    applyMiddleware(...middlewares)
  );

  const store = createStore(rootReducer, composedEnhancers);

  sagaMiddleware.run(rootSaga);

  return store;
};

export function useStore() {
  const currentStore = useMemo(() => initSaga(), []);

  currentStore.subscribe(rootSubscribe(currentStore));

  return currentStore;
}
