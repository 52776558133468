import React, { useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import PaginationComponent from '@giro-app/modules/debts/components/Pagination.component';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import BoxHistoryOrder from '../Box/BoxHistoryOrder.component';

import useDialog from '@giro-app/hooks/useDialogRedux.hook';

import { listsPropTypes } from '@giro-app/hooks/useListRedux.hook';
import { Typography } from '@mui/material';
import { borderRadius } from '@mui/system';
import drawerOrderSearch from '../../store/drawerOrderSearch';

const ListHistoryOrderComponent = ({
  data,
  loading,
  handleFetch,
  isFetched,
  results,
  metadata,
  handleUpdateFilters,
  ...props
}: listsPropTypes) => {
  useEffect(() => {
    handleFetch();
  }, []);
  const { handleOpen } = useDialog(drawerOrderSearch);

  return (
    <Box display="grid" gap={'40px'} gridTemplateColumns="1fr">
      <Box display="flex" alignItems="start" justifyContent="space-between">
        <Typography variant="trasso_site_h3" color="trasso_site.roxo">
          Meus Pedidos
        </Typography>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          alignSelf="top"
          gap={1}
        >
          <IconButton
            disabled={loading}
            sx={{
              border: '1px solid',
              borderColor: 'trasso.purple.60',
              borderRadius: '10px',
            }}
            onClick={() => handleOpen()}
          >
            <FilterAltOutlinedIcon sx={{ color: 'trasso.purple.60' }} />
          </IconButton>
        </Box>
      </Box>

      {loading && (
        <Box
          display="grid"
          gridTemplateColumns={{ md: '1fr 1fr', xs: '1fr' }}
          gap={3}
          {...props}
        >
          {[...Array(4)].map((_, index) => (
            <Skeleton
              width="100%"
              height={162}
              variant="rectangular"
              key={`${index}-skeleton-listhistory`}
            />
          ))}
        </Box>
      )}

      {!loading && (
        <>
          <Box
            display="grid"
            gridTemplateColumns={{ md: '1fr 1fr', xs: '1fr' }}
            gap={3}
          >
            {results?.map((a, index) => (
              <BoxHistoryOrder
                amount={a.amount}
                created_at={a.created_at}
                status={a.status}
                reference_id={a.reference_id}
                description={a?.description}
                details={a?.details}
                key={`transaction-${index}`}
                authorization_number={a?.authorization_number}
              />
            ))}
          </Box>

          <Box justifySelf="center">
            <PaginationComponent
              metadata={metadata?.resultset}
              loading={loading}
              handleUpdateFilters={handleUpdateFilters}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ListHistoryOrderComponent;
