import reducer from './dialogSelectAllDebits.reducer';
import * as action from './dialogSelectAllDebits.action';
import * as selector from './dialogSelectAllDebits.selector';
import * as constant from './dialogSelectAllDebits.constant';
import initialState from './dialogSelectAllDebits.initialState';
import subscriber from './dialogSelectAllDebits.subscriber';
import saga from './dialogSelectAllDebits.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
