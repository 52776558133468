import reducer from './history.reducer';
import * as selector from './history.selector';
import * as constant from './history.constant';
import * as action from './history.action';
import saga from './history.saga';
import initialState from './history.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
