import { createReducer } from '@reduxjs/toolkit';

import initialState from './drawerOrderSearch.initialState';
import { ACTION_TYPES } from './drawerOrderSearch.constant';

const handleOpen = (state, action) => {
  state.visible = true;
  state.payload = action.payload;
};

const handleClose = (state) => {
  state.visible = false;
  state.payload = null;
};

const handleStartFetch = (state) => {
  state.loading = true;
};

const handleFinishFetch = (state, action) => {
  state.loading = false;
  state.payload = action.payload;
};

const handleReset = () => initialState;

export default createReducer(initialState, {
  [ACTION_TYPES.OPEN]: handleOpen,
  [ACTION_TYPES.CLOSE]: handleClose,
  [ACTION_TYPES.RESET]: handleReset,
  [ACTION_TYPES.FETCH.START]: handleStartFetch,
  [ACTION_TYPES.FETCH.FINISH]: handleFinishFetch,
});
