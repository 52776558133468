import reducer from './payment.reducer';
import * as selector from './payment.selector';
import * as constant from './payment.constant';
import * as action from './payment.action';
import saga from './payment.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
};
