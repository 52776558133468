import React from 'react';

import useList from '@giro-app/hooks/useListRedux.hook';

import LISTS from '@giro-app/constants/lists.constant';

type paymentLists = 'paymentDetran' | 'paymentDebits';
type profileLists = 'profileHistory';

type listsVariantTypes = paymentLists | profileLists;

type Props = {
  variant: listsVariantTypes;
  [key: string]: any;
};

const ListFactory = ({ variant, ...restProps }: Props) => {
  const configs: any = {};

  if (variant === 'profileHistory') {
    configs.scroll = '#anchor-search-history-list';
  }

  const listProps = useList(LISTS.STORES[variant], configs);

  const props = {
    ...listProps,
    ...restProps,
  };

  return React.createElement(LISTS.COMPONENTS[variant], props);
};

export default ListFactory;
