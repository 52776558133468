import flow from 'lodash/flow';
import React, { useEffect } from 'react';
import qs from 'qs';
import { Box } from '@mui/system';
import { Formik, useFormikContext, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from 'formik-mui';
import Button from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { useMediaQuery, useTheme } from '@mui/material';

import ROUTES from '@giro-app/constants/routes.constant';

import router from '@giro/shared-store/router';

import messages from '@giro/shared-constants/messagesSchema.constant';

import useQuery from '@giro/shared-hooks/useQuery.hook';

import apps from '../../../../store/apps';
import debits from '../../store/list';

import FormPlacaRenavam, {
  validationSchema as validationSchemaPlacaRenavam,
} from './FormPlacaRenavam.component';

import FormRenavam, {
  validationSchema as validationSchemaRenavam,
} from './FormRenavam.component';

import FormDocument, {
  validationSchema as validationSchemaDocument,
} from './FormPlacaDocument.component';

import FormPlate, {
  validationSchema as validationSchemaPlate,
} from './FormPlaca.component';

const FormSearchDebitContainer = ({
  type,
  setDetran,
}: {
  type: any;
  setDetran?: any;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const query = useQuery();
  const dispatch = useDispatch();
  const { errors, values, setValues, validateForm }: any = useFormikContext();

  const dispatchDebitsRedux = {
    UPDATE_FILTERS: flow(debits.action.updateFilters, dispatch),
  };

  const dispatchRouterRedux = {
    replace: flow(router.action.replace, dispatch),
    push: flow(router.action.push, dispatch),
  };

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
  };

  const selectorDebitsRedux = {
    loading: useSelector(debits.selector.selectLoading),
  };

  useEffect(() => {
    if (!isMobile) {
      return undefined;
    }

    if (selectorDebitsRedux.loading === false) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [selectorDebitsRedux.loading, isMobile]);

  useEffect(() => {
    if (setDetran) {
      setDetran(values.integration_id);
    }
  }, [values.integration_id]);

  useEffect(() => {
    const newValues: any = { ...values };

    if (Object.prototype.hasOwnProperty.call(newValues, 'renavam')) {
      newValues.renavam = query.get('renavam') || undefined;
    }

    if (Object.prototype.hasOwnProperty.call(newValues, 'placa')) {
      newValues.placa = query.get('placa') || undefined;
    }

    if (Object.prototype.hasOwnProperty.call(newValues, 'document_number')) {
      newValues.document_number = query.get('document_number') || undefined;
    }

    setValues(newValues);

    setTimeout(() => validateForm());
  }, []);

  const handleSubmit = () => {
    dispatchDebitsRedux.UPDATE_FILTERS({
      ...debits.initialState.filters,
      placa: values?.placa,
      renavam: values?.renavam,
      document_number: values?.document_number,
      integration_id: values?.integration_id,
    });

    const valuesQs = qs.stringify({ ...values });

    const path = [ROUTES.DEBTS.DETRAN, valuesQs].join('?');

    dispatchRouterRedux.push(path);
  };

  return (
    <FormSearchDebitComponent
      errors={errors}
      type={type}
      apps={selectorAppsRedux.data}
      handleSubmit={handleSubmit}
      loading={selectorDebitsRedux.loading}
      setDetran={setDetran}
    />
  );
};

const FormSearchDebitComponent = ({
  errors,
  type,
  apps,
  handleSubmit,
  loading,
  setDetran,
}) => {
  return (
    <Box display="flex" gap={3} width="100%" flexDirection="column">
      <Box display="grid" gap={3} gridTemplateColumns="1fr">
        {setDetran && apps?.length > 0 && (
          <Field
            component={TextField}
            name="integration_id"
            fullWidth
            label="Escolha o Detran"
            variant="outlined"
            select
            SelectProps={{
              native: true,
            }}
          >
            <option value=""></option>
            {apps
              ?.filter((a) => a.status === 'ACTIVE' && a.filter_type !== 3)
              .map((a) => (
                <option value={a.id} key={a.id}>
                  {a.description}
                </option>
              ))}
          </Field>
        )}

        {type === 'placaRenavam' && <FormPlacaRenavam />}

        {type === 'renavam' && <FormRenavam />}
        {type === 'document' && <FormDocument />}
        {type === 'plate' && <FormPlate />}
        {type === 'none' && <Box />}
      </Box>

      <Button
        fullWidth
        variant="outlined"
        onClick={handleSubmit}
        disabled={Object.keys(errors).length > 0}
        loading={loading}
      >
        Nova busca
      </Button>
    </Box>
  );
};

export default function FormSearchDebitFormik({
  currentDetran,
  setCurrentDetran,
}: {
  currentDetran: any;
  setCurrentDetran?: any;
}) {
  const dispatch = useDispatch();

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
  };

  const currentDetranObj = selectorAppsRedux?.data?.find(
    (s) => s.id === currentDetran
  );

  let type = 'placaRenavam';

  if (!currentDetranObj) {
    type = 'none';
  }

  if (currentDetranObj?.filter_type === 1) {
    type = 'renavam';
  }

  if (currentDetranObj?.filter_type === 2) {
    type = 'document';
  }

  if (currentDetranObj?.filter_type === 5) {
    type = 'plate';
  }

  const schemaWithDetran = Yup.object({
    integration_id: Yup.string()
      .required(messages.REQUIRED)
      .default(currentDetran),
  });

  const validationTypes = {
    placaRenavam: validationSchemaPlacaRenavam.concat(schemaWithDetran),
    renavam: validationSchemaRenavam.concat(schemaWithDetran),
    document: validationSchemaDocument.concat(schemaWithDetran),
    plate: validationSchemaPlate.concat(schemaWithDetran),
    none: validationSchemaPlacaRenavam.concat(schemaWithDetran),
  };

  return (
    <Formik
      key={currentDetran}
      initialValues={{ ...validationTypes[type].cast(undefined) }}
      onSubmit={() => {}}
      validationSchema={validationTypes[type]}
      validateOnMount
    >
      <FormSearchDebitContainer type={type} setDetran={setCurrentDetran} />
    </Formik>
  );
}
