const ANTIFRAUD_ADDRESS = 'antifraud';
const PAYMENT = 'payment';
const VERIFY = 'verify';
const FINISH = 'finish';

export const CHECKOUT_STEPS = {
  ANTIFRAUD_ADDRESS,
  PAYMENT,
  VERIFY,
  FINISH,
};

export const FORMCHECKOUT_STEPS = [
  CHECKOUT_STEPS.ANTIFRAUD_ADDRESS,
  CHECKOUT_STEPS.PAYMENT,
  CHECKOUT_STEPS.VERIFY,
  CHECKOUT_STEPS.FINISH,
];
