import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField as TextFieldMaterial,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import { Field, useFormikContext } from 'formik';
import { TextField, Select } from 'formik-mui';
import set from 'lodash/set';
import { flow } from 'lodash';

import messages from '@giro/shared-constants/messagesSchema.constant';

import validateCPF from '@giro/shared-utils/validateCPF.util';

import postApiInstallmentsService from '@giro/shared-services/checkout/postApiInstallments.service';

import checkout from '@giro-app/modules/checkout/store/checkout';

import useListDebitsHook from '@giro-app/modules/debts/hooks/useListDebits.hook';

export const FormCheckoutPaymentCardSchema = Yup.object({
  description: Yup.string().notRequired().default(''),
  number: Yup.string().required(messages.REQUIRED).default(''),
  amount: Yup.string().required(messages.REQUIRED),
  installments: Yup.number().required(messages.REQUIRED),
  document: Yup.string()
    .required(messages.REQUIRED)
    .default('')
    .test('document_number', messages.DOCUMENT_INVALID, validateCPF),
  exp: Yup.string().required(messages.REQUIRED).default(''),
  security_code: Yup.string().required(messages.REQUIRED).default(''),
  name: Yup.string().required(messages.REQUIRED).default(''),
});

const getInstallmentFormatted = (installment) => `${installment}x`;

const getAmountFormatted = (installment, amount) =>
  Number(amount / installment).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

const getTaxFormatted = (value) =>
  Number(value).toLocaleString('pt-br', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

type Props = {
  name: string;
  amount?: number;
  unique?: boolean;
  max?: number;
  disabledAmount?: boolean;
  handleGetInstallments?: any;
  installments: any[];
};

const FormCheckoutPaymentCardComponent = ({
  name,
  max,
  disabledAmount,
  amount,
  handleGetInstallments,
  installments,
  unique,
}: Props) => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    updateDebit: flow(checkout.action.updateDebit, dispatch),
  };

  const selectorRedux = {
    debit: useSelector(checkout.selector.selectDebit),
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={3}>
        {!unique && (
          <Box>
            <Typography color="trasso.pink" variant="trasso_site_caption">
              VALOR
            </Typography>
            <Field name={`${name}.amount`}>
              {({
                field,
                form: { setFieldValue, setFieldTouched },
                meta: { error, touched },
              }) => (
                <NumberFormat
                  customInput={TextFieldMaterial}
                  prefix={'R$ '}
                  isNumericString
                  variant="outlined"
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  fixedDecimalScale
                  fullWidth
                  type="tel"
                  disabled={disabledAmount}
                  value={field.value}
                  error={touched && !!error}
                  helperText={touched && !!error && error}
                  onBlur={() => {
                    setFieldTouched(field.name, true);
                    if (handleGetInstallments) {
                      handleGetInstallments(field.value);
                    }
                  }}
                  onValueChange={(values: any) => {
                    const value = max
                      ? values.floatValue > max
                        ? max
                        : values.value
                      : values.value;
                    setFieldValue(field.name, value);
                  }}
                />
              )}
            </Field>
          </Box>
        )}

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography color="trasso.pink" variant="trasso_site_caption">
            PARCELAS
          </Typography>
          <Field
            component={Select}
            name={`${name}.installments`}
            fullWidth
            variant="outlined"
            helperText="parcele em até 18x"
            onBlur={({ target: { value } }) => {
              const findObjInstallment = installments.find(
                (i) => i.installments == value
              );

              dispatchRedux.updateDebit({
                ...selectorRedux.debit,
                installment: findObjInstallment,
              });
            }}
          >
            {installments.map((i, index) => (
              <MenuItem key={`${name}.${index}`} value={index + 1}>
                {getInstallmentFormatted(index + 1)}{' '}
                {getAmountFormatted(i.installments, i.amount_total)}
                {i.tax_cet
                  ? `- ${Number(i.tax_cet / 100).toLocaleString('pt-BR', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}`
                  : ''}
              </MenuItem>
            ))}
          </Field>
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography color="trasso.pink" variant="trasso_site_caption">
            NÚMERO DO CARTÃO
          </Typography>
          <Field name={`${name}.number`}>
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }) => (
              <NumberFormat
                variant="outlined"
                customInput={TextFieldMaterial}
                fullWidth
                type="tel"
                format="#### #### #### ####"
                value={field.value}
                error={touched && !!error}
                helperText={touched && !!error && error}
                inputProps={{
                  'data-private': true,
                }}
                onBlur={() => {
                  setFieldTouched(field.name, true);
                }}
                onValueChange={(values: any) =>
                  setFieldValue(field.name, values.value)
                }
              />
            )}
          </Field>
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography color="trasso.pink" variant="trasso_site_caption">
            NOME DO TITULAR DO CARTÃO
          </Typography>
          <Field name={`${name}.name`}>
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }) => (
              <TextFieldMaterial
                variant="outlined"
                fullWidth
                value={field.value}
                error={touched && !!error}
                helperText={touched && !!error && error}
                inputProps={{
                  'data-private': true,
                }}
                onBlur={() => {
                  setFieldTouched(field.name, true);
                }}
                onChange={({ target: { value } }) =>
                  setFieldValue(field.name, String(value).toUpperCase())
                }
              />
            )}
          </Field>
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography color="trasso.pink" variant="trasso_site_caption">
            CPF DO TITULAR DO CARTÃO
          </Typography>
          <Field name={`${name}.document`}>
            {({
              field,
              form: { setFieldValue, setFieldTouched },
              meta: { error, touched },
            }) => (
              <NumberFormat
                variant="outlined"
                customInput={TextFieldMaterial}
                fullWidth
                type="tel"
                format="###.###.###-##"
                inputProps={{
                  'data-private': true,
                }}
                value={field.value}
                error={touched && !!error}
                helperText={touched && !!error && error}
                onBlur={() => {
                  setFieldTouched(field.name, true);
                }}
                onValueChange={(values: any) =>
                  setFieldValue(field.name, values.value)
                }
              />
            )}
          </Field>
        </Box>

        <Box display="flex" gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso.pink" variant="trasso_site_caption">
              VALIDADE DO CARTÃO
            </Typography>
            <Field name={`${name}.exp`}>
              {({
                field,
                form: { setFieldValue, setFieldTouched },
                meta: { error, touched },
              }) => (
                <NumberFormat
                  variant="outlined"
                  customInput={TextFieldMaterial}
                  fullWidth
                  format="##/####"
                  type="tel"
                  placeholder="MM/YYYY"
                  inputProps={{
                    'data-private': true,
                  }}
                  mask={['M', 'M', 'Y', 'Y', 'Y', 'Y']}
                  value={field.value}
                  error={touched && !!error}
                  helperText={touched && !!error && error}
                  onBlur={() => {
                    setFieldTouched(field.name, true);
                  }}
                  onValueChange={(values: any) => {
                    const valuesFormattedSplut =
                      values.formattedValue.split('/');
                    const month = Number(valuesFormattedSplut[0]);
                    const year = valuesFormattedSplut[1];
                    if (!isNaN(month) && month > 12) {
                      return setFieldValue(field.name, `12${year}`);
                    }
                    return setFieldValue(field.name, values.value);
                  }}
                />
              )}
            </Field>
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso.pink" variant="trasso_site_caption">
              CVV
            </Typography>
            <Field
              component={TextField}
              name={`${name}.security_code`}
              variant="outlined"
              fullWidth
              FormHelperTextProps={{
                color: 'trasso.pink',
              }}
              type="tel"
              inputProps={{
                'data-private': true,
                maxLength: 4,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

const FormCheckoutPaymentCardContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [installments, setInstallments] = useState([]);

  const { values, setValues, setFieldValue }: any = useFormikContext();

  const currentInstallment = values[props.name]?.installments;
  const currentAmount = values[props.name]?.amount;

  const selectorRedux = {
    debit: useSelector(checkout.selector.selectDebit),
  };

  const handleGetInstallments = async (value) => {
    setLoading(true);

    try {
      const { integration_id } = selectorRedux.debit;

      const [success, result] = await postApiInstallmentsService({
        integration_id,
        amount: value,
        type: 'CARTAO',
      });

      if (success) {
        const resultParsed = result?.map((r) => ({
          ...r,
          tax_percent: getTaxFormatted((r.amount_total - value) / value),
        }));

        setInstallments(resultParsed);
      }
    } catch {
      return false;
    }

    setLoading(false);
  };

  useEffect(() => {
    if (currentInstallment) {
      const newValues = { ...values };

      const installmentObj: any = installments[currentInstallment - 1];

      set(
        newValues,
        `${props.name}.amount_total`,
        installmentObj?.amount_total || ''
      );

      set(newValues, `${props.name}.charge_id`, installmentObj?.id || '');

      setValues(newValues);
    }
  }, [currentInstallment]);

  useEffect(() => {
    if (currentAmount) {
      handleGetInstallments(currentAmount);
    }
  }, []);

  useEffect(() => {
    if (props.unique) {
      setFieldValue('firstCard.amount', props.max);
      handleGetInstallments(props.max);
    }
  }, [props.unique]);

  return (
    <>
      <FormCheckoutPaymentCardComponent
        {...props}
        handleGetInstallments={handleGetInstallments}
        installments={installments}
      />

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          position="absolute"
          top={0}
          left={0}
          bgcolor="#ffffffb8"
          zIndex={1}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default FormCheckoutPaymentCardContainer;
