import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { flow } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { TextField, CircularProgress } from '@mui/material';
import { LoadingButton as Button } from '@mui/lab';

import CloseIcon from '@mui/icons-material/Close';

import toaster from '@giro/shared-store/toaster';

import theme from '@giro/client-app/src/constants/theme.constant';

import email from '../../assets/email.png';

import postApiOnboardingsConfirmationsService from '@giro/shared-services/onboarding/postApiOnboardingsConfirmations.service';
import patchApiOnboardingsConfirmationsService from '@giro/shared-services/onboarding/patchApiOnboardingsConfirmations.service';

import checkout from '../../store/checkout';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import authState from '@giro/shared-store/auth';

const DialogConfirmEmailComponent = ({ handleClose, visible }) => {
  const auth = useAuthHook();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const codeRef = useRef('');

  const dispatchToasterRedux = {
    show: flow(toaster.action.show, dispatch),
    setAuth: flow(authState.action.setToken, dispatch),
  };

  const selectorRedux = {
    data: useSelector(checkout.selector.selectData),
  };

  useEffect(() => {
    if (visible) {
      handleSendCode();
    }
  }, [visible]);

  const handleSendCode = async () => {
    setLoading(true);

    const [success, result] = await postApiOnboardingsConfirmationsService({
      type: 'email',
      email: auth?.user?.user?.email,
    } as any);

    setLoading(false);
  };

  const handleConfirmCode = async () => {
    setLoading(true);

    const [success, result] = await patchApiOnboardingsConfirmationsService({
      type: 'email',
      email: auth?.user?.user?.email,
      token: codeRef.current,
    } as any);

    setLoading(false);

    if (success) {
      handleClose();

      dispatchToasterRedux.setAuth(result.token);
    }
  };

  return (
    <Dialog open={visible} maxWidth={'xs'}>
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box height={52} />
          <Box display="flex" flexDirection="column" gap={2}>
            <Box alignSelf="center">
              <img src={email} />
            </Box>
            <Typography
              align="center"
              variant="body"
              color="black"
              fontWeight={600}
            >
              Para continuar, precisamos confirmar o seu email. Digite o código
              que você recebeu no seu email para confirmar.
            </Typography>
            <TextField
              label="Código"
              onChange={({ target: { value } }) => (codeRef.current = value)}
            />
            <Button onClick={handleSendCode} loading={loading}>
              Reenviar código
            </Button>
            <Button
              color="info"
              variant="contained"
              fullWidth
              loading={loading}
              disabled={loading}
              onClick={() => handleConfirmCode()}
            >
              <span>Confirmar</span>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogConfirmEmailComponent;
