import reducer from './user.reducer';
import * as selector from './user.selector';
import * as constant from './user.constant';
import * as action from './user.action';
import saga from './user.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
};
