import React from 'react';
import {
  Box,
  Typography,
  TextField as TextFieldMaterial,
  Button,
  MenuItem,
  CircularProgress,
  Divider,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Formik, Field, useFormikContext, Form } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import theme from '@giro/client-app/src/constants/theme.constant';

import viaCepService from '@giro/shared-services/viaCep.service';

import messages from '@giro/shared-constants/messagesSchema.constant';
import states from '@giro/shared-constants/states.constant';
import ROUTES from '@giro-app/constants/routes.constant';

export const validationSchema = Yup.object({
  street: Yup.string().required(messages.REQUIRED).default(''),
  neighborhood: Yup.string().required(messages.REQUIRED).default(''),
  number: Yup.string().default(''),
  complement: Yup.string(),
  country: Yup.string().required(messages.REQUIRED).default('BR'),
  countryCode: Yup.string().required(messages.REQUIRED).default('55'),
  city: Yup.string().required(messages.REQUIRED).default(''),
  state: Yup.string().required(messages.REQUIRED).default(''),
  postalCode: Yup.string().required(messages.REQUIRED).default(''),
});

export const FormCheckoutAntiFraudAddress = ({
  handleNextUpdateValues,
  handleFetchZipcode,
  handleBack,
  name,
}: any) => {
  const { errors, submitForm, setFieldValue } = useFormikContext();

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" gap={1} flexDirection="column">
        <Typography variant="trasso_site_h3">Endereço</Typography>
      </Box>
      <Divider />

      <Box display="flex" flexDirection="column" gap={3}>
        <Field name={`${name}.postalCode`}>
          {({
            field,
            form: { setFieldValue, setFieldTouched },
            meta: { error, touched },
          }) => (
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography color="trasso.pink" variant="trasso_site_caption">
                CEP
              </Typography>
              <NumberFormat
                customInput={TextFieldMaterial}
                fullWidth
                variant="outlined"
                format="##.###-###"
                type="tel"
                value={field.value}
                error={touched && !!error}
                helperText={touched && !!error && error}
                onBlur={async ({ target: { value } }) => {
                  const onlyDigits = value.replace(/\D/g, '');

                  if (onlyDigits.length === 8) {
                    const [success, result] = await viaCepService(onlyDigits);

                    if (success) {
                      setFieldValue(`${name}.street`, result?.logradouro);
                      setFieldValue(`${name}.neighborhood`, result?.bairro);
                      setFieldValue(`${name}.city`, result?.localidade);
                      setFieldValue(`${name}.state`, result?.uf);
                    }
                  }

                  setFieldTouched(field.name, true);
                }}
                onValueChange={(values: any) =>
                  setFieldValue(field.name, values.value)
                }
              />
            </Box>
          )}
        </Field>

        <Box
          display="grid"
          gridTemplateColumns={{ md: '1fr 185px', xs: '1fr' }}
          gap={2}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso.pink" variant="trasso_site_caption">
              RUA
            </Typography>
            <Field
              component={TextField}
              variant="outlined"
              fullWidth
              name={`${name}.street`}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso.pink" variant="trasso_site_caption">
              NÚMERO
            </Typography>
            <Field
              component={TextField}
              variant="outlined"
              fullWidth
              name={`${name}.number`}
              type="tel"
            />
          </Box>
        </Box>
        <Box display="flex" gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso.pink" variant="trasso_site_caption">
              COMPLEMENTO
            </Typography>
            <Field
              component={TextField}
              variant="outlined"
              name={`${name}.complement`}
              fullWidth
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso.pink" variant="trasso_site_caption">
              {' '}
              BAIRRO
            </Typography>
            <Field
              component={TextField}
              variant="outlined"
              name={`${name}.neighborhood`}
              fullWidth
            />
          </Box>
        </Box>
        <Box display="flex" gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso.pink" variant="trasso_site_caption">
              CIDADE
            </Typography>
            <Field
              component={TextField}
              variant="outlined"
              name={`${name}.city`}
              fullWidth
            />
          </Box>
          <Box display="flex" flexDirection="column" flex={1} gap={1}>
            <Typography color="trasso.pink" variant="trasso_site_caption">
              ESTADO
            </Typography>
            <Field
              component={TextField}
              variant="outlined"
              select
              name={`${name}.state`}
              fullWidth
            >
              {states.map((s) => (
                <MenuItem key={s.id_uf} value={s.sigla}>
                  {s.nome}
                </MenuItem>
              ))}
            </Field>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default function FormCheckoutAntiFraudAddressComponent(props) {
  const history = useHistory();

  const { handleUpdateAddress, formData, loading } = props;

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Formik
      initialValues={{ ...formData.antiFraud.address }}
      onSubmit={async (values) => {
        handleUpdateAddress(values);

        return true;
      }}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      <Form>
        <FormCheckoutAntiFraudAddress {...props} handleBack={handleBack} />

        {loading && (
          <Box
            position="fixed"
            top={0}
            left={0}
            bottom={0}
            right={0}
            width="100%"
            height="100%"
            zIndex={999}
            bgcolor="#ffffff6e"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Form>
    </Formik>
  );
}
