import reducer from './receipts.reducer';
import * as selector from './receipts.selector';
import * as constant from './receipts.constant';
import subscriber from './receipts.subscriber';
import saga from './receipts.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
