import React from 'react';
import { Dialog, Box, TextField, Typography, IconButton } from '@mui/material';
import Button from '@mui/lab/LoadingButton';

import FormUpdatePasswordComponent from '../Form/FormUpdatePassword.component';

import CloseIcon from '@mui/icons-material/Close';

import lockImg from '../../assets/lock.png';
import successImg from '../../assets/success.png';

const DialogChangePasswordComponent = ({
  handleClose,
  visible,
  variant,
  handleUpdatePassword,
  loading,
}) => {
  const defaultRender = (
    <Box
      display="grid"
      gridTemplateColumns="1fr"
      padding={3}
      gap={3}
      width={450}
    >
      <Box justifySelf="center">
        <img src={lockImg} />
      </Box>

      <FormUpdatePasswordComponent
        handleUpdatePassword={handleUpdatePassword}
        loading={loading}
      />
    </Box>
  );

  const successRender = (
    <Box
      display="grid"
      gridTemplateColumns="1fr"
      padding={3}
      gap={3}
      width={450}
    >
      <Box justifySelf="center">
        <img src={successImg} />
      </Box>
      <Typography
        align="center"
        variant="h2"
        fontWeight={700}
        color="#000"
        lineHeight={1}
      >
        Senha atualizada com sucesso!
      </Typography>
      <Box mt={3}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => handleClose()}
          sx={{
            backgroundColor: 'gray.200',
            color: 'info.dark2',
            '&:hover': {
              backgroundColor: 'gray.300',
            },
          }}
        >
          Fechar
        </Button>
      </Box>
    </Box>
  );

  return (
    <Dialog open={visible} onClose={handleClose}>
      <Box position="relative">
        <Box position="absolute" right={16} top={16}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon
              sx={{
                width: 24,
                height: 24,
              }}
            />
          </IconButton>
        </Box>

        {variant === 'default' && defaultRender}
        {variant === 'success' && successRender}
      </Box>
    </Dialog>
  );
};

export default DialogChangePasswordComponent;
