import { all } from 'redux-saga/effects';

import auth from '@giro/shared-store/auth';
import router from '@giro/shared-store/router';

import configs from './configs';
import apps from './apps';
import modules from './modules';

function* rootSaga() {
  yield all([
    apps.saga.watch(),
    configs.saga.watch(),
    modules.saga.watch(),
    auth.saga.watch(),
    router.saga.watch(),
  ]);
}

export default rootSaga;
