import { useEffect, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import qs from 'qs';
// import { hotjar } from 'react-hotjar';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import router from '@giro/shared-store/router';

import useAuth from '@giro/shared-hooks/useAuth.hook';
import useNotification from '@giro/shared-hooks/useNotification.hook';

import PaymentModule from '@giro-app/modules/payment/Payment.module';
import ProfileModule from '@giro-app/modules/profile/Profile.module';
import OrdersModule from '@giro-app/modules/orders/Orders.module';
import ReceiptsModule from '@giro-app/modules/receipts/Receipts.module';
import HomeModule from '@giro-app/modules/home/Home.module';
import DebtsModule from '@giro-app/modules/debts/Debts.module';
import CheckoutModule from '@giro-app/modules/checkout/Checkout.module';

import ROUTES from '@giro-app/constants/routes.constant';

import {
  getRedirectInLocalStorage,
  deleteRedirectInLocalStorage,
} from '@giro-app/utils/redirectLocalStorage.util';
import addScriptUtil from '@giro-app/utils/addScript.util';

import apps from './store/apps';
import checkout from './modules/checkout/store/checkout';

import DialogContactUsComponent from './components/DialogContactUs.component';

export default function Routes() {
  useNotification();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { user, signedIn } = useAuth();

  const dispatchAppsRedux = {
    fetchStart: flow(apps.action.fetchStart, dispatch),
    updateState: flow(checkout.action.updateState, dispatch),
  };

  const dispatchRouterRedux = {
    routeChange: flow(router.action.routeChange, dispatch),
  };

  const selectorRouterRedux = {
    pathname: useSelector(router.selector.selectPathname),
  };

  useEffect(() => {
    dispatchAppsRedux.fetchStart();
  }, []);

  useEffect(() => {
    const [pathname, params] = getRedirectInLocalStorage();

    if (!pathname) {
      return undefined;
    }

    const paramsString = qs.stringify(params);

    const url = [String(pathname).replace(/"/g, ''), `?${paramsString}`].join(
      ''
    );

    window.location.href = url;

    deleteRedirectInLocalStorage();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR === 'true') {
      // hotjar.initialize(
      //   process.env.REACT_APP_HOTJAR_ID as any,
      //   process.env.REACT_APP_HOTJAR_SV as any
      // );
    }
  }, []);

  useEffect(() => {
    if (signedIn && process.env.REACT_APP_HOTJAR === 'true') {
      const { user: userState } = user || {};

      // if (hotjar.initialized()) {
      //   hotjar.identify(userState.id, {
      //     name: userState.name,
      //     email: userState.email,
      //   });
      // }
    }
  }, [signedIn]);

  useEffect(() => {
    if (selectorRouterRedux.pathname !== location.pathname) {
      dispatchRouterRedux.routeChange(location);
    }

    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (process.env.REACT_APP_CHAT === 'false') {
      return undefined;
    }

    addScriptUtil('https://code.jivosite.com/widget/RsVmaCffny');
  }, []);

  return (
    <Fragment>
      <Switch>
        <Route exact path={ROUTES.HOME} component={HomeModule} />
        <Route path={ROUTES.PAYMENT.ROOT} component={PaymentModule} />
        <Route path={ROUTES.PROFILE.ROOT} component={ProfileModule} />
        <Route path={ROUTES.ORDERS.ROOT} component={OrdersModule} />
        <Route path={ROUTES.RECEIPTS.ROOT} component={ReceiptsModule} />
        <Route path={ROUTES.DEBTS.ROOT} component={DebtsModule} />
        <Route path={ROUTES.CHECKOUT.ROOT} component={CheckoutModule} />

        <Redirect to={ROUTES.HOME} />
      </Switch>

      <DialogContactUsComponent />
    </Fragment>
  );
}
