import { all } from 'redux-saga/effects';

import payment from '@giro-app/modules/payment/store';
import profile from '@giro-app/modules/profile/store';
import receipts from '@giro-app/modules/receipts/store';
import debts from '@giro-app/modules/debts/store';
import checkout from '@giro-app/modules/checkout/store';

function* watch() {
  yield all([
    payment.saga.watch(),
    profile.saga.watch(),
    checkout.saga.watch(),
    debts.saga.watch(),
    receipts.saga.watch(),
  ]);
}

export default {
  watch,
};
