import { Fragment } from 'react';
import { useEffect } from 'react';
import flow from 'lodash/flow';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import useListHook from '@giro-app/hooks/useListRedux.hook';

import BoxDetailOrder from '../Box/BoxDetailOrder.component';
import ActionsOrder from '../ActionsOrder.component';
import ListDetailsOrder from '../List/ListDetailsOrder.component';

import drawerOrder from '../../store/drawerOrder';
import payment from '../../store/payment';

const DrawerHistoryOrderComponent = () => {
  const { handleFetch, loading, data } = useListHook(payment);

  const dispatch = useDispatch();

  const selectorRedux = {
    VISIBLE: useSelector(drawerOrder.selector.selectVisible),
    PAYLOAD: useSelector(drawerOrder.selector.selectPayload),
  };

  const dispatchRedux = {
    CLOSE: flow(drawerOrder.action.close, dispatch),
  };

  useEffect(() => {
    if (selectorRedux?.PAYLOAD?.reference_id) {
      handleFetch(selectorRedux?.PAYLOAD?.reference_id);
    }
  }, [selectorRedux?.PAYLOAD?.reference_id]);

  const totalAmount = data?.cards?.reduce((acc, curr) => acc + curr.amount, 0);

  const status = data?.status;

  const cards = data?.cards;

  return (
    <Drawer
      open={selectorRedux.VISIBLE}
      onClose={() => dispatchRedux.CLOSE()}
      anchor="right"
    >
      <Box
        sx={{ width: '100vw', maxWidth: 390 }}
        p={2}
        pt={4}
        display="flex"
        flexDirection="column"
        gap={2}
        position="relative"
      >
        {loading == false && (
          <Fragment>
            <Box
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                },
              }}
            >
              <Box>
                <IconButton onClick={() => dispatchRedux.CLOSE()}>
                  <ChevronRightIcon />
                </IconButton>
              </Box>

              <Box mt={2}>
                <Divider />
              </Box>
            </Box>

            <BoxDetailOrder
              amount={totalAmount}
              status={data?.status}
              status_description={data?.status_description}
              cards={cards}
              created_at={data?.created_at}
            />

            {status === 'PAGO' && <ActionsOrder />}

            <ListDetailsOrder />
          </Fragment>
        )}

        {loading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100vh"
            bgcolor="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerHistoryOrderComponent;
