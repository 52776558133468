import { CHECKOUT_STEPS } from '../../constants/stepsFormCheckout.constant';
import { PAYMENT_TYPE } from '../../constants/paymentType.constant';
import { VERIFY_STEPS } from '../../constants/verifySteps.constant';

export const cardSchema = {
  number: '',
  amount: '',
  installments: '',
  document: '',
  exp: '',
  security_code: '',
  name: '',
};

const initialState = {
  loading: null,
  error: [null, null],
  data: null,
  app: {
    id: null,
    description: null,
    logo_path: null,
    filter_type: null,
    status: null,
    order: null,
    validation_org_id: null,
    validation_session_prefix: null,
  },
  debit: {
    integration_id: null,
    reference_id: null,
    debits: [],
  },
  sessionId: `${+new Date()}`,
  currentStep: CHECKOUT_STEPS.PAYMENT,
  paymentType: PAYMENT_TYPE.CARD,
  verifyStep: VERIFY_STEPS.PHONE,
  formData: {
    payment: {
      description: '',
      firstCard: cardSchema,
      secondCard: cardSchema,
      pix: null,
      address: {
        postalCode: '',
        state: '',
        city: '',
        countryCode: '55',
        country: 'BR',
        number: '',
        neighborhood: '',
        street: '',
      },
    },
    verify: {
      phone_number: null,
    },
  },
};

const state = {
  loading: false,
  error: [false, null],
  data: null,
  app: {
    id: '64926db6-b622-468d-9aa2-aeda40c79599',
    description: 'Espírito Santo',
    logo_path: '/assets/detran_ES.png',
    filter_type: 0,
    html_description:
      'OBS: O boleto pode levar até 2 dias úteis para ser compensado após o pagamento.',
    status: 'ACTIVE',
    order: 2,
    external_code: 'detranes',
    require_user: true,
    validation_org_id: 'k8vif92e',
    validation_session_prefix: 'giropagamentos',
  },
  debit: {
    integration_id: '64926db6-b622-468d-9aa2-aeda40c79599',
    reference_id: 'H_NR4Sq5yyjLB5IM0Ls',
    debits: [
      {
        reference: '64926DB6-B622-468D-9AA2-AEDA40C79599;MULTA;202112294;',
        description: 'DETRAN-ES-108100-BS00044203-7340/00',
        due_date: '2021-12-23T03:00:00.000Z',
        type: 'MULTA',
        amount: 138.36,
        installments: 0,
        owner: '',
        renavam: '',
        plate: 'ODJ6877',
        year: '2021',
      },
    ],
  },
  sessionId: '1665707287692',
  currentStep: 'payment',
  paymentType: 'card',
  verifyStep: 'phone',
  formData: {
    payment: {
      address: {
        postalCode: '04727-150',
        state: 'SP',
        city: 'São Paulo',
        countryCode: '55',
        country: 'BR',
        number: '123',
        neighborhood: 'Vila Cruzeiro',
        street: 'Rua Joaquim dos Reis',
      },
      description: '',
      firstCard: {
        number: '4111111111111111',
        amount: '',
        installments: '',
        document: '27608830000',
        exp: '072025',
        security_code: '123',
        name: 'Matheus Loureiro',
      },
      secondCard: {
        number: '',
        amount: '',
        installments: '',
        document: '',
        exp: '',
        security_code: '',
        name: '',
      },
      pix: null,
    },
    verify: {
      phone_number: null,
    },
  },
};

export default initialState;
