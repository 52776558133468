import reducer from './dialogContactUs.reducer';
import * as action from './dialogContactUs.action';
import * as selector from './dialogContactUs.selector';
import * as constant from './dialogContactUs.constant';
import initialState from './dialogContactUs.initialState';
import subscriber from './dialogContactUs.subscriber';
import saga from './dialogContactUs.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
