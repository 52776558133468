import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import FormSearchDebtBillet from '../components/Form/FormSearchDebtBillet.component';

import useProtectedByAuth from '@giro-app/hooks/useProtectedByAuth.hook';

import apps from '@giro-app/store/apps';

const Layout = ({ children }) => {
  const params: any = useParams();

  const selectRedux = {
    apps: useSelector(apps.selector.selectData),
  };

  const currentPartner = selectRedux.apps?.find(
    (e) => e.external_code === params.partner
  );

  return (
    <Box display="flex" minHeight="calc(100vh - 412px)" width={'100%'}>
      <Box
        width="100%"
        display="flex"
        gap={10}
        bgcolor="white"
        borderRadius={'4px'}
        flexDirection="column"
      >
        {children}
      </Box>
    </Box>
  );
};

const BoxCategory = ({ label, img, onClick }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="trasso_site.base"
      p={4}
      borderRadius="30px"
      gap={3}
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        onClick({
          label,
          img,
        });
      }}
    >
      <Box alignSelf="center" display="block">
        <img src={img} />
      </Box>
      <Typography
        align="center"
        variant="trasso_site_h3_light"
        color="trasso_site.roxo2"
      >
        {label}
      </Typography>
    </Box>
  );
};

const BilletSelector = () => {
  useProtectedByAuth();

  const [category, setCategory] = React.useState<any | undefined>(undefined);

  const params: any = useParams();

  const selectRedux = {
    apps: useSelector(apps.selector.selectData),
    loading: useSelector(apps.selector.selectLoading),
  };

  const handleSetCategory = (val) => {
    setCategory(val);
  };

  const withCategory = (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      width={{
        xs: '100%',
        md: 600,
      }}
      alignSelf="center"
    >
      <Box
        display="flex"
        bgcolor="trasso_site.base"
        alignItems="center"
        justifyContent="center"
        py="50px"
        borderRadius="30px"
        flexDirection="column"
        gap="20px"
      >
        <Box display="block">
          <img src={category?.img} />
        </Box>
        <Typography
          color="trasso_site.roxo2"
          variant="trasso_site_h3_light"
          align="center"
        >
          {category?.label}
        </Typography>
      </Box>
      <Typography
        align="center"
        variant="trasso_site_corpo"
        color="trasso_site.roxo2"
        sx={{ maxWidth: 450, alignSelf: 'center' }}
      >
        Insira abaixo o código do boleto
      </Typography>
      <Box width="100%" alignSelf="center">
        <FormSearchDebtBillet
          integrationId={'f34fd740-9731-446a-91a5-90e05f5a7226'}
        />
      </Box>

      <Typography variant="subtitle2" color="primary">
        * Não é permitido o pagamento de fatura de cartão de crédito.
      </Typography>
    </Box>
  );

  const withoutCategory = (
    <Box display="flex" flexDirection="column" width={'100%'} gap={3}>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: '1fr',
          md: 'repeat(4, 1fr)',
        }}
        gap={3}
      >
        <BoxCategory
          label="Água, luz, gás, internet"
          img="/assets/billet/luz.png"
          onClick={handleSetCategory}
        />
        <BoxCategory
          label="Aluguel/Condomínio"
          img="/assets/billet/aluguel.png"
          onClick={handleSetCategory}
        />
        <BoxCategory
          label="Escola/Faculdade"
          img="/assets/billet/escola.png"
          onClick={handleSetCategory}
        />
        <BoxCategory
          label="Impostos da Secretaria da Fazenda"
          img="/assets/billet/imposto.png"
          onClick={handleSetCategory}
        />
        <BoxCategory
          label="Boletos diversos"
          img="/assets/billet/boletos.png"
          onClick={handleSetCategory}
        />
      </Box>
    </Box>
  );

  return (
    <Box position="relative">
      <Layout>
        {category && withCategory}
        {!category && withoutCategory}
      </Layout>
    </Box>
  );
};

export default BilletSelector;
