import { ACTION_TYPES } from './history.constant';

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchFinish = (payload) => ({
  type: ACTION_TYPES.FETCH.END,
  payload,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const updateFilters = (filters) => ({
  type: ACTION_TYPES.UPDATE.FILTERS,
  filters,
});

export const resetFilters = () => ({
  type: ACTION_TYPES.RESET.FILTERS,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});
