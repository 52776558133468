import reducer from './checkout.reducer';
import * as constant from './checkout.constant';
import * as action from './checkout.action';
import * as selector from './checkout.selector';
import saga from './checkout.saga';
import initialState from './checkout.initialState';
import subscriber from './checkout.subscriber';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
  subscriber,
};
