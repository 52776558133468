import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';

import useAuth from '@giro/shared-hooks/useAuth.hook';

import AppLayout from '@giro-app/layouts/App.layout';

import ROUTES from '@giro-app/constants/routes.constant';

import CheckoutScreen from './screens/Checkout.screen';

import Dialog from '@giro-app/factories/Dialog.factory';

import dialogConfirmEmail from './store/dialogs/dialogConfirmEmail';

export default function DebtsModule() {
  return (
    <AppLayout>
      <Switch>
        <Route exact path={ROUTES.CHECKOUT.ROOT} component={CheckoutScreen} />
      </Switch>

      <Dialog variant="paymentCardDetail" />
      <Dialog variant="paymentErrorPayment" />
      <Dialog variant="paymentSendReceipt" />
      <Dialog variant="paymentPreAuth" />
      <Dialog variant="paymentConfirmEmail" />
    </AppLayout>
  );
}
