import { ACTION_TYPES } from './checkout.constant';

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchStartZipcode = (zipcode) => ({
  type: ACTION_TYPES.FETCH.START_ZIPCODE,
  zipcode,
});

export const fetchEnd = (payload) => ({
  type: ACTION_TYPES.FETCH.END,
  payload,
});

export const fetchUpdate = () => ({
  type: ACTION_TYPES.FETCH.UPDATE,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const updateFormdata = (formData) => ({
  type: ACTION_TYPES.UPDATE.FORMDATA,
  formData,
});

export const updateCurrentstep = (currentStep) => ({
  type: ACTION_TYPES.UPDATE.CURRENTSTEP,
  currentStep,
});

export const updatePaymenttype = (paymentType) => ({
  type: ACTION_TYPES.UPDATE.PAYMENTTYPE,
  paymentType,
});

export const updateVerifystep = (step) => ({
  type: ACTION_TYPES.UPDATE.VERIFYSTEP,
  step,
});

export const updateDebit = (debit: {
  integration_id: string;
  reference_id: string;
  installment?: any;
  debits: any[];
}) => ({
  type: ACTION_TYPES.UPDATE.DEBIT,
  debit,
});

export const updateApp = (app) => ({
  type: ACTION_TYPES.UPDATE.APP,
  app,
});

export const updatePhone = (phone) => ({
  type: ACTION_TYPES.UPDATE.PHONE,
  phone,
});

export const updateState = (payload) => ({
  type: ACTION_TYPES.UPDATE.STATE,
  payload,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});

export const sessionNew = () => ({
  type: ACTION_TYPES.SESSION.NEW,
});

export const confirmAccount = (token) => ({
  type: ACTION_TYPES.CONFIRM.ACCOUNT,
  token,
});

export const confirmTransaction = (token) => ({
  type: ACTION_TYPES.CONFIRM.TRANSACTION,
  token,
});
