export const NAME = 'checkout';

const CONTEXT = 'MODULE::CHECKOUT';

const SESSION_NEW = `${CONTEXT}::SESSION::NEW`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_START_ZIPCODE = `${CONTEXT}::FETCH::START::ZIPCODE`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;
const FETCH_UPDATE = `${CONTEXT}::FETCH::UPDATE`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const UPDATE_FORMDATA = `${CONTEXT}::UPDATE::FORMDATA`;
const UPDATE_CURRENTSTEP = `${CONTEXT}::UPDATE::CURRENTSTEP`;
const UPDATE_VERIFYSTEP = `${CONTEXT}::UPDATE::VERIFYSTEP`;
const UPDATE_PAYMENTTYPE = `${CONTEXT}::UPDATE::PAYMENTTYPE`;
const UPDATE_DEBIT = `${CONTEXT}::UPDATE::DEBIT`;
const UPDATE_APP = `${CONTEXT}::UPDATE::APP`;
const UPDATE_PHONE = `${CONTEXT}::UPDATE::PHONE`;
const UPDATE_STATE = `${CONTEXT}::UPDATE::STATE`;

const CONFIRM_ACCOUNT = `${CONTEXT}::CONFIRM::ACCOUNT`;
const CONFIRM_TRANSACTION = `${CONTEXT}::CONFIRM::TRANSACTION`;
const CONFIRM_RESERVE = `${CONTEXT}::CONFIRM::RESERVE`;

const CONFIRM = {
  ACCOUNT: CONFIRM_ACCOUNT,
  TRANSACTION: CONFIRM_TRANSACTION,
  RESERVE: CONFIRM_RESERVE,
};

const SESSION = {
  NEW: SESSION_NEW,
};

const UPDATE = {
  FORMDATA: UPDATE_FORMDATA,
  CURRENTSTEP: UPDATE_CURRENTSTEP,
  PAYMENTTYPE: UPDATE_PAYMENTTYPE,
  DEBIT: UPDATE_DEBIT,
  PHONE: UPDATE_PHONE,
  VERIFYSTEP: UPDATE_VERIFYSTEP,
  APP: UPDATE_APP,
  STATE: UPDATE_STATE,
};

const RESET = {
  STATE: RESET_STATE,
};

const FETCH = {
  START: FETCH_START,
  START_ZIPCODE: FETCH_START_ZIPCODE,
  UPDATE: FETCH_UPDATE,
  END: FETCH_END,
  ERROR: FETCH_ERROR,
};

export const ACTION_TYPES = {
  RESET,
  FETCH,
  UPDATE,
  SESSION,
  CONFIRM,
};
