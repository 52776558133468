import { flow } from 'lodash';

import { NAME } from './misc.constant';

import { selectState as selectStateConfigs } from '../configs.selector';

const selectStateKey = (state) => state[NAME];
const selectRemoveLoginKey = (state) => state.removeLogin;
const selectIntegrationIdKey = (state) => state.currentIntegrationId;

export const selectState = flow(selectStateConfigs, selectStateKey);
export const selectRemoveLogin = flow(selectState, selectRemoveLoginKey);
export const selectIntegrationId = flow(selectState, selectIntegrationIdKey);
