import { all } from 'redux-saga/effects';

import dialogSelectAllDebits from './dialogSelectAllDebits';

function* watch() {
  yield all([dialogSelectAllDebits.saga.watch()]);
}

export default {
  watch,
};
