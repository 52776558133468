import reducer from './list.reducer';
import * as constant from './list.constant';
import * as action from './list.action';
import * as selector from './list.selector';
import saga from './list.saga';
import initialState from './list.initialState';
import subscriber from './list.subscriber';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
  subscriber,
};
