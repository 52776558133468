import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import theme from '@giro/client-app/src/constants/theme.constant';

import { PAYMENT_TYPE } from '@giro-app/modules/checkout/constants/paymentType.constant';

import { ReactComponent as PixSvg } from '../../assets/pix.svg';
import { ReactComponent as CreditCardSvg } from '../../assets/credit-card.svg';

const buttonStyle = (type) => ({
  padding: '10px 20px',
  borderRadius: '10px',
  borderColor: type ? 'trasso.pink' : 'trasso_site.roxo',
  border: 1,
  backgroundColor: 'white',
  color: type ? 'trasso.pink' : 'trasso_site.roxo',
  '&:hover': {
    color: 'trasso.pink',
    backgroundColor: 'trasso.gray.20',
  },
  svg: {
    fill: '#253858',
    width: 16,
    height: 16,
    marginRight: '6px',
  },
  ...theme.typography.body,
});

const SelectorPaymentTypeComponent = ({
  handleUpdatePaymentType,
  isCard,
  isCardTwo,
  isCardUnique,
  isPix,
}) => {
  return (
    <Box display="flex" gap={3} p={1}>
      <Button
        onClick={() => handleUpdatePaymentType(PAYMENT_TYPE.CARD)}
        sx={buttonStyle(isCardUnique)}
      >
        <Box component={CreditCardSvg} />
        CARTÃO
      </Button>
      <Button
        onClick={() => handleUpdatePaymentType(PAYMENT_TYPE.CARD_ONE)}
        sx={buttonStyle(isCard || isCardTwo)}
      >
        <Box component={CreditCardSvg} />
        PAGAR C/ 2 CARTÕES
      </Button>
    </Box>
  );
};

export default SelectorPaymentTypeComponent;
