import React from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { format } from 'date-fns';
import { PDFDocument } from 'pdf-lib';

import useAuth from '@giro/shared-hooks/useAuth.hook';

import useCurrentUrl from '@giro-app/hooks/useCurrentUrl.hook';

import ROUTES from '@giro-app/constants/routes.constant';

import { saveRedirectInLocalStorage } from '@giro-app/utils/redirectLocalStorage.util';

import useQuery from '@giro/shared-hooks/useQuery.hook';

import useListHook from '@giro-app/hooks/useListRedux.hook';

import Logo from '@giro/shared-components/Logo.component';

import PDFViewerComponent from '../components/PDFViewer.component';

import payment from '../store/payment';

const Item = ({ label, value }: { label: string; value: string }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    borderBottom="1px solid black"
    alignItems="flex-end"
  >
    <Typography fontWeight="bold" variant="caption">
      {label}:
    </Typography>
    <Typography variant="body_md">{value}</Typography>
  </Box>
);

const Debts = ({ debts }) => (
  <Box
    display="grid"
    gridTemplateColumns="1fr"
    p={2}
    border="1px solid black"
    gap={1}
  >
    <Typography fontWeight="bold" variant="caption">
      Débito(s):
    </Typography>
    <Typography variant="body_sm">
      {debts
        ?.map(
          (d) =>
            `${d.description} - ${Number(d.amount).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}`
        )
        .join(', ')}
    </Typography>
  </Box>
);

const ReceiptsScreen = () => {
  const currentUrl = useCurrentUrl();

  const { signedIn } = useAuth();

  React.useEffect(() => {
    if (!signedIn) {
      setTimeout(() => {
        saveRedirectInLocalStorage();

        (
          window as any
        ).location.href = `${ROUTES.EXTERNAL.AUTH.LOGIN}?redirectUrl=${currentUrl}`;
      });
    }
  }, [signedIn]);

  const query = useQuery();

  const { handleFetch, data } = useListHook(payment);

  const ref = query.get('ref');

  const details = data?.details?.[0];

  React.useEffect(() => {
    if (ref) {
      handleFetch(ref);
    }
  }, []);

  const downloadDocument = async () => {
    let pdfDocMerge;

    const input: any = document.getElementById('divToPrint');

    const imgData = await html2canvas(input);

    const pdf = new jsPDF('p', 'mm', 'a4');
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    const widthRatio = width / imgData.width;
    const heightRatio = height / imgData.height;

    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    pdf.addImage(
      imgData,
      'PNG',
      0,
      0,
      imgData.width * ratio,
      imgData.height * ratio
    );

    const blob = pdf.output('arraybuffer');

    const linkMergePdf = data?.metadata_settlement_receipt;
    const base64LinkMergePdf = `data:application/pdf;base64,${linkMergePdf}`;

    const pdfDocGiro = await PDFDocument.load(blob);

    if (linkMergePdf) {
      pdfDocMerge = await PDFDocument.load(base64LinkMergePdf);
    }

    const pdfNew = await PDFDocument.create();

    if (linkMergePdf) {
      const copiedPagesMergePdf = await pdfNew.copyPages(
        pdfDocMerge,
        pdfDocMerge.getPageIndices()
      );

      copiedPagesMergePdf.forEach((page) => pdfNew.addPage(page));
    }

    const copiedPagesGiro = await pdfNew.copyPages(
      pdfDocGiro,
      pdfDocGiro.getPageIndices()
    );

    copiedPagesGiro.forEach((page) => pdfNew.addPage(page));

    const linkNewPdf = await pdfNew.saveAsBase64({ dataUri: true });
    const nameSource = `comprovante_${ref}_${+Date.now()}.pdf`;

    const downloadLink = document.createElement('a');

    downloadLink.href = linkNewPdf;
    downloadLink.download = nameSource;
    downloadLink.click();
  };

  console.log(
    'data?.integration_application?.logo_path',
    data?.integration_application?.logo_path
  );

  const renderReceiptGiro = (
    <Box display="grid" gridTemplateColumns="1fr" gap={4}>
      <Box display="grid" gridTemplateColumns="1fr" gap={4}>
        <Box justifySelf="center">
          {data?.integration_application?.logo_path ? (
            <img
              src={data?.integration_application?.logo_path}
              css={{ maxHeight: 100 }}
            />
          ) : (
            <Logo width="200" variant={'new'} />
          )}
        </Box>
        <Typography align="center" fontWeight="bold">
          Código de autorização: {data?.authorization_number}
        </Typography>
        <Box borderBottom="1px dashed black">
          <Typography>
            <strong>recebido em:</strong>{' '}
            {data?.authorization_date &&
              format(new Date(data?.authorization_date), 'dd/MM/yyyy HH:mm:ss')}
          </Typography>
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap={2}>
        {details?.plate && <Item label="Placa" value={details?.plate} />}

        {details?.renavam && <Item label="Renavam" value={details?.renavam} />}

        <Item label="Forma de Pagamento" value="Crédito" />

        {data?.cards.map((c, idx) => (
          <Item
            key={idx}
            label="Parcelas"
            value={`${c.number}, ${c.installments}x de ${Number(
              c.amount / c.installments
            ).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })} `}
          />
        ))}

        <Item
          label="Valor total do pagamento"
          value={Number(
            data?.cards.reduce((acc, curr) => acc + curr.amount, 0)
          ).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        />

        {details?.barcode && (
          <Item label="Código de barras" value={details?.barcode} />
        )}
      </Box>

      <Debts debts={data?.details} />

      <div
        dangerouslySetInnerHTML={{
          __html: data?.integration?.html_description,
        }}
      />

      <Box display="flex" flexDirection="column">
        <Typography align="center" variant="body_sm">
          Copyright © 2022 Giro Pagamentos. Todos os direitos reservados.
        </Typography>
        <Typography align="center" variant="body_sm">
          atendimentodetran@giropagamentos.com.br | (11) 5049-2097
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography align="center" variant="body_sm">
          Desenvolvido por:
        </Typography>

        <Box alignSelf="center">
          <Logo width="200" variant={'new'} />
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Container maxWidth={'lg'} css={{ position: 'relative' }} disableGutters>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={2}
          borderBottom="1px solid #0000000f"
          pb={3}
          gap={2}
        >
          <Button onClick={downloadDocument} variant="contained">
            Baixar comprovante
          </Button>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={
            data?.metadata_settlement_receipt ? '1fr 1fr' : '1fr'
          }
          gap={3}
          mt={5}
          px={4}
        >
          <Box>{renderReceiptGiro}</Box>

          {!!data?.metadata_settlement_receipt && (
            <Box>
              <PDFViewerComponent
                fileBase64={`data:application/pdf;base64,${data?.metadata_settlement_receipt}`}
              />
            </Box>
          )}
        </Box>

        <Box position="absolute" top="-9999999px" width="210mm">
          <Box bgcolor="white" p={4} id="divToPrint">
            {renderReceiptGiro}
          </Box>
        </Box>

        {!data?.authorization_number && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            width="100%"
            height="100%"
            bgcolor="white"
            zIndex={9999}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </>
  );
};

export default ReceiptsScreen;
