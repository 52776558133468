import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import debits from '../store/list';

import ListDebitsComponent from '../components/ListDebits.component';

import { listsPropTypes } from '@giro-app/hooks/useListRedux.hook';

const ListDebitsContainer = (props: listsPropTypes) => {
  const { results, metadata, loading, handleUpdateFilters } = props;

  const dispatch = useDispatch();

  const selectorRedux = {
    selected: useSelector(debits.selector.selectSelected),
  };

  const dispatchRedux = {
    SELECT: flow(debits.action.select, dispatch),
    UNSELECT: flow(debits.action.unselect, dispatch),
  };

  return (
    <ListDebitsComponent
      selected={selectorRedux.selected}
      results={results}
      metadata={metadata}
      loading={loading}
      handleUpdateFilters={handleUpdateFilters}
      handleSelect={dispatchRedux.SELECT}
      handleUnselect={dispatchRedux.UNSELECT}
    />
  );
};

export default ListDebitsContainer;
