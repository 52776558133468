import { ACTION_TYPES } from './misc.constant';

export const updateRemoveLogin = (payload: boolean) => ({
  type: ACTION_TYPES.UPDATE.REMOVE_LOGIN,
  payload,
});

export const updateIntegrationId = (payload: string) => ({
  type: ACTION_TYPES.UPDATE.INTEGRATION_ID,
  payload,
});
