import { createReducer } from '@reduxjs/toolkit';

import initialState from './misc.initialState';
import { ACTION_TYPES } from './misc.constant';

const handleUpdateRemoveLogin = (state, action) => {
  state.removeLogin = action.payload;
};

const handleUpdateIntegrationId = (state, action) => {
  state.currentIntegrationId = action.payload;
};

const handleReset = () => initialState;

export default createReducer(initialState, {
  [ACTION_TYPES.UPDATE.REMOVE_LOGIN]: handleUpdateRemoveLogin,
  [ACTION_TYPES.UPDATE.INTEGRATION_ID]: handleUpdateIntegrationId,
  [ACTION_TYPES.RESET]: handleReset,
});
