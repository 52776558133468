import fetch from '@giro/shared-utils/fetch.util';

type BodyType = {
  token: string;
  phone_number: string;
};

export default async function postApiAccountsConfirmPhoneService(
  body: BodyType
) {
  return fetch('accounts/confirm-phone', {
    method: 'POST',
    ms: 'USERS',
    auth: true,
    body: JSON.stringify(body),
  });
}
