import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import checkout from '../store/checkout';

import CheckoutFinishComponent from './CheckoutFinish.component';
import CheckoutVerifyComponent from './CheckoutVerify.component';

import FormCheckoutFactory from '../factories/FormCheckout.factory';

import { CHECKOUT_STEPS } from '@giro-app/modules/checkout/constants/stepsFormCheckout.constant';

const CheckoutComponent = () => {
  const selectorRedux = {
    currentStep: useSelector(checkout.selector.selectCurrentStep),
  };

  const isFirstStep = selectorRedux.currentStep === null;

  return (
    <Box
      height="100%"
      display="grid"
      gridTemplateColumns="1fr"
      gap={4}
      minHeight="calc(100vh - 410px)"
      alignItems="start"
      width="100%"
    >
      {(isFirstStep ||
        selectorRedux.currentStep === CHECKOUT_STEPS.ANTIFRAUD_ADDRESS) && (
        <Box>
          <FormCheckoutFactory variant="antiFarudAddress" />
        </Box>
      )}

      {selectorRedux.currentStep === CHECKOUT_STEPS.PAYMENT && (
        <Box>
          <FormCheckoutFactory variant="payment" />
        </Box>
      )}

      {selectorRedux.currentStep === CHECKOUT_STEPS.VERIFY && (
        <CheckoutVerifyComponent />
      )}

      {selectorRedux.currentStep === CHECKOUT_STEPS.FINISH && (
        <CheckoutFinishComponent />
      )}
    </Box>
  );
};

export default CheckoutComponent;
