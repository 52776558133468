import { Fragment } from 'react';
import { useEffect } from 'react';
import flow from 'lodash/flow';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormSearchTransactionsComponent from '../Form/FormSearchTransactions.component';

import useListHook from '@giro-app/hooks/useListRedux.hook';

import drawerOrderSearch from '../../store/drawerOrderSearch';
import payment from '../../store/payment';

const DrawerOrderSearchComponent = () => {
  const { handleFetch, loading, data } = useListHook(payment);

  const dispatch = useDispatch();

  const selectorRedux = {
    VISIBLE: useSelector(drawerOrderSearch.selector.selectVisible),
    PAYLOAD: useSelector(drawerOrderSearch.selector.selectPayload),
  };

  const dispatchRedux = {
    CLOSE: flow(drawerOrderSearch.action.close, dispatch),
  };

  useEffect(() => {
    if (selectorRedux?.PAYLOAD?.reference_id) {
      handleFetch(selectorRedux?.PAYLOAD?.reference_id);
    }
  }, [selectorRedux?.PAYLOAD?.reference_id]);

  const totalAmount = data?.cards?.reduce((acc, curr) => acc + curr.amount, 0);

  const status = data?.status;

  const cards = data?.cards;

  return (
    <Drawer
      open={selectorRedux.VISIBLE}
      onClose={() => dispatchRedux.CLOSE()}
      anchor="right"
    >
      <Box
        sx={{ width: '100vw', maxWidth: 390 }}
        p={2}
        pt={4}
        display="flex"
        flexDirection="column"
        gap={2}
        position="relative"
      >
        {!loading && (
          <Fragment>
            <Box
              p={4}
              boxShadow="0px 0px 8px rgba(0, 33, 82, 0.08)"
              bgcolor="white"
            >
              <FormSearchTransactionsComponent />
            </Box>
          </Fragment>
        )}

        {loading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100vh"
            bgcolor="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerOrderSearchComponent;
