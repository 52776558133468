import React from 'react';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import { Typography, Divider, IconButton } from '@mui/material';
import flow from 'lodash/flow';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import theme from '@giro/client-app/src/constants/theme.constant';

import useDialog from '@giro-app/hooks/useDialogRedux.hook';

import drawerOrder from '../../store/drawerOrder';

const handleColorStatus = (status) => {
  const statusObj = {
    REJEITADO: 'error.light',
    PAGO: 'success.main',
    'EM VERIFICAÇÃO': 'warning.main',
    'EM PROCESSAMENTO': 'warning.main',
  };

  return statusObj[status] || status;
};

const handleNormalizeStatus = (status) => {
  const statusObj = {
    REJEITADO: 'RECUSADO',
    PAGO: 'APROVADO',
    'EM VERIFICAÇÃO': 'EM VERIFICAÇÃO',
    'EM PROCESSAMENTO': 'EM PROCESSAMENTO',
  };

  return statusObj[status] || status;
};

const BoxHistoryOrderComponent = ({
  amount,
  details,
  created_at,
  reference_id,
  status,
  description,
  authorization_number,
}) => {
  const { handleOpen } = useDialog(drawerOrder);

  return (
    <Box
      borderRadius={1}
      p={3}
      border={`1px solid ${theme.palette.gray[100]}`}
      boxShadow="0px 4px 8px rgba(0, 33, 82, 0.08)"
      display="flex"
      flexDirection="column"
      gap={2}
      bgcolor="trasso.gray.20"
      onClick={() =>
        handleOpen({
          reference_id,
        })
      }
      sx={{
        cursor: 'pointer',
      }}
    >
      <Box
        display="flex"
        gap={2}
        justifyContent="space-between"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
      >
        <Typography
          color="trasso_site.rosa"
          variant="subtitle1"
          fontWeight="bold"
        >
          ID: {authorization_number || reference_id}
        </Typography>

        <Box display="flex" flexDirection="column" gap={1}>
          <Box bgcolor={handleColorStatus(status)} p={1} borderRadius="10px">
            <Typography color="white" variant="subtitle2" align="center">
              {handleNormalizeStatus(status)}
            </Typography>
          </Box>
          <Typography color="info.dark2" variant="subtitle1" align="right">
            {format(new Date(created_at), 'dd/MM/yyyy HH:mm')}
          </Typography>
        </Box>
      </Box>
      {description && (
        <>
          <Divider />
          <Box flex={1} display="flex" flexDirection="column">
            <Typography color="gray.400" variant="subtitle2">
              Descrição do pagamento
            </Typography>
            <Typography color="info.dark2" variant="subtitle1">
              {description}
            </Typography>
          </Box>
        </>
      )}
      <Divider />
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        gap={2}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body" color="info.dark2" fontWeight={600}>
            Valor:
          </Typography>
          <Typography variant="body" color="trasso.pink">
            {Number(amount).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={{ md: '1fr 1fr', xs: '1fr' }}
          gap={2}
        >
          <Box
            display="flex"
            justifyContent={{ md: 'flex-end', xs: 'center' }}
            alignItems={{ md: 'flex-end', xs: 'center' }}
            alignSelf="center"
          >
            <Typography
              variant="subtitle1"
              color="#360067"
              sx={{
                textDecoration: 'underline',
                '&:hover': { color: 'trasso.pink' },
              }}
            >
              Preciso de Ajuda
            </Typography>
          </Box>
          <IconButton
            size="small"
            sx={{
              borderRadius: '10px',
              border: '1px solid',
              borderColor: '#360067',
            }}
          >
            <Typography variant="subtitle2" color="#360067">
              Recibo
            </Typography>
            <FileDownloadOutlinedIcon sx={{ fill: '#360067' }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BoxHistoryOrderComponent;
