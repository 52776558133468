import { DEBTS_MODE_KEY } from '@giro-app/configs/misc.config';

const initialState = {
  loading: null,
  data: null,
  error: [null, null],
  selected: [],
  installment: {},
  isPartner: window.sessionStorage.getItem('isPartner') || null,
  filters: {
    offset: 0,
    limit: 999999999,
  },
};

const state = {
  loading: false,
  isPartner: null,
  data: {
    metadata: {
      count: 6,
      limit: 10,
      offset: 0,
    },
    results: [
      {
        reference:
          '2A007D0E-33B0-4CF8-AECD-E886CB48B139;MULTA;01037737560-334418173-5N9780144;',
        description:
          'Multa - CAMPINAS - 08/11/2021 - Multa por nao Identific.Condutor Infrat.Imposta Pessoa Jurid.',
        due_date: '2022-01-07T16:57:52.289Z',
        type: 'MULTA',
        amount: 788.76,
        owner: 'CATIA REGINA SI',
        renavam: '01037737560',
        plate: 'FEA1082',
      },
      {
        reference:
          '2A007D0E-33B0-4CF8-AECD-E886CB48B139;MULTA;01037737560-334426080-5N9856286;',
        description:
          'Multa - CAMPINAS - 08/11/2021 - Multa por nao Identific.Condutor Infrat.Imposta Pessoa Jurid.',
        due_date: '2022-01-07T16:57:52.289Z',
        type: 'MULTA',
        amount: 788.76,
        owner: 'CATIA REGINA SI',
        renavam: '01037737560',
        plate: 'FEA1082',
      },
      {
        reference:
          '2A007D0E-33B0-4CF8-AECD-E886CB48B139;MULTA;01037737560-334496470-5N9789714;',
        description:
          'Multa - CAMPINAS - 08/11/2021 - Multa por nao Identific.Condutor Infrat.Imposta Pessoa Jurid.',
        due_date: '2022-01-07T16:57:52.289Z',
        type: 'MULTA',
        amount: 657.3,
        owner: 'CATIA REGINA SI',
        renavam: '01037737560',
        plate: 'FEA1082',
      },
      {
        reference:
          '2A007D0E-33B0-4CF8-AECD-E886CB48B139;MULTA;01037737560-334536005-5N9984524;',
        description:
          'Multa - CAMPINAS - 08/11/2021 - Multa por nao Identific.Condutor Infrat.Imposta Pessoa Jurid.',
        due_date: '2022-01-07T16:57:52.289Z',
        type: 'MULTA',
        amount: 197.18,
        owner: 'CATIA REGINA SI',
        renavam: '01037737560',
        plate: 'FEA1082',
      },
      {
        reference:
          '2A007D0E-33B0-4CF8-AECD-E886CB48B139;MULTA;01037737560-334987234-5N9600976;',
        description:
          'Multa - CAMPINAS - 08/11/2021 - Multa por nao Identific.Condutor Infrat.Imposta Pessoa Jurid.',
        due_date: '2022-01-07T16:57:52.289Z',
        type: 'MULTA',
        amount: 131.46,
        owner: 'CATIA REGINA SI',
        renavam: '01037737560',
        plate: 'FEA1082',
      },
      {
        reference:
          '2A007D0E-33B0-4CF8-AECD-E886CB48B139;MULTA;01037737560-345821774-5Z0519145;',
        description:
          'Multa - CAMPINAS - 24/11/2021 - Multa por nao Identific.Condutor Infrat.Imposta Pessoa Jurid.',
        due_date: '2022-01-07T16:57:52.289Z',
        type: 'MULTA',
        amount: 657.3,
        owner: 'CATIA REGINA SI',
        renavam: '01037737560',
        plate: 'FEA1082',
      },
    ],
  },
  error: [false, null],
  selected: [
    {
      reference:
        '2A007D0E-33B0-4CF8-AECD-E886CB48B139;MULTA;01037737560-334418173-5N9780144;',
      description:
        'Multa - CAMPINAS - 08/11/2021 - Multa por nao Identific.Condutor Infrat.Imposta Pessoa Jurid.',
      due_date: '2022-01-07T16:57:52.289Z',
      type: 'MULTA',
      amount: 788.76,
      owner: 'CATIA REGINA SI',
      renavam: '01037737560',
      plate: 'FEA1082',
    },
  ],
  filters: {
    offset: 0,
    limit: 10,
    renavam: '01037737560',
    integration_id: '2a007d0e-33b0-4cf8-aecd-e886cb48b139',
  },
};

export default initialState;
