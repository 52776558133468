import { ACTION_TYPES } from './dialogChangePassword.constant';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const open = () => ({
  type: ACTION_TYPES.OPEN,
});

export const close = () => ({
  type: ACTION_TYPES.CLOSE,
});

export const changeVariant = (variant: 'default' | 'success') => ({
  type: ACTION_TYPES.CHANGE.VARIANT,
  variant,
});
