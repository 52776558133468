import theme, { breakpoints } from '@giro/shared-constants/theme.constant';

const themeConstants = {
  ...theme,
  components: {
    ...theme.components,
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '59px !important',
          borderRadius: '6px !important',
          backgroundColor: 'white !important',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {},
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          legend: {},
        },
      },
    },
  },
};

export default themeConstants;
