import reducer from './dialogDetran.reducer';
import * as action from './dialogDetran.action';
import * as selector from './dialogDetran.selector';
import * as constant from './dialogDetran.constant';
import initialState from './dialogDetran.initialState';
import subscriber from './dialogDetran.subscriber';
import saga from './dialogDetran.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
