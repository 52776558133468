import React from 'react';

import DebtDetranComponent from '../components/DebtDetran.component';

const DebitsDetranScreen = () => {
  return (
    <>
      <DebtDetranComponent />
    </>
  );
};

export default DebitsDetranScreen;
