export const NAME = 'apps';

const CONTEXT = 'APPS';

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_FINISH = `${CONTEXT}::FETCH::FINISH`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_ALL = `${CONTEXT}::FETCH::RESET::ALL`;

const FETCH = {
  START: FETCH_START,
  FINISH: FETCH_FINISH,
  ERROR: FETCH_ERROR,
};

const RESET = {
  ALL: RESET_ALL,
};

export const ACTION_TYPES = {
  FETCH,
  RESET,
};
