import { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import ROUTES from '@giro-app/constants/routes.constant';

import ReceiptsScreen from './screens/Receipts.screen';

export default function Routes() {
  return (
    <Fragment>
      <Switch>
        <Route exact path={ROUTES.RECEIPTS.ROOT} component={ReceiptsScreen} />
      </Switch>
    </Fragment>
  );
}
