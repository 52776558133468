import React from 'react';
import { Box, Typography } from '@mui/material';
import { Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InputAdornment from '@mui/material/InputAdornment';

import messages from '@giro/shared-constants/messagesSchema.constant';

export const validationSchema = Yup.object({
  renavam: Yup.string().required(messages.REQUIRED),
});

const FormPlacaRenavamComponent = (props) => {
  return (
    <Field name="renavam">
      {({
        field,
        form: { setFieldValue, setFieldTouched },
        meta: { error, touched },
      }) => (
        <Box
          display="grid"
          gridTemplateColumns="1fr"
          {...props}
          gap={1}
          alignItems="start"
        >
          <Typography color="trasso_site.rosa" variant="trasso_site_caption">
            Renavam
          </Typography>
          <Field
            component={TextField}
            name="renavam"
            fullWidth
            placeholder="Renavam"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!error ? (
                    <CheckCircleIcon css={{ fill: '#9DCC12' }} />
                  ) : (
                    <CancelIcon css={{ fill: '#FF5832' }} />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    </Field>
  );
};

export default FormPlacaRenavamComponent;
