import flow from 'lodash/flow';
import React, { useEffect } from 'react';
import qs from 'qs';
import { Box } from '@mui/system';
import { Formik, useFormikContext, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from 'formik-mui';
import Button from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

import ROUTES from '@giro-app/constants/routes.constant';

import router from '@giro/shared-store/router';

import messages from '@giro/shared-constants/messagesSchema.constant';

import useQuery from '@giro/shared-hooks/useQuery.hook';
import useAuthHook from '@giro/shared-hooks/useAuth.hook';
import useCurrentUrl from '@giro-app/hooks/useCurrentUrl.hook';

import apps from '../../../../store/apps';
import debits from '../../store/list';

import FormPlacaRenavam, {
  validationSchema as validationSchemaPlacaRenavam,
} from './FormPlacaRenavam.component';

import FormRenavam, {
  validationSchema as validationSchemaRenavam,
} from './FormRenavam.component';

import FormDocument, {
  validationSchema as validationSchemaDocument,
} from './FormPlacaDocument.component';

import FormPlate, {
  validationSchema as validationSchemaPlate,
} from './FormPlaca.component';

const FormSelectorDebitContainer = ({
  type,
  setDetran,
}: {
  type: any;
  setDetran?: any;
}) => {
  const { signedIn } = useAuthHook();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const query = useQuery();
  const dispatch = useDispatch();
  const currentUrl = useCurrentUrl();
  const { errors, values, setValues, validateForm }: any = useFormikContext();

  const dispatchDebitsRedux = {
    UPDATE_FILTERS: flow(debits.action.updateFilters, dispatch),
  };

  const dispatchRouterRedux = {
    replace: flow(router.action.replace, dispatch),
    push: flow(router.action.push, dispatch),
  };

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
  };

  const selectorDebitsRedux = {
    loading: useSelector(debits.selector.selectLoading),
  };

  const getItemSession = (item) => window.sessionStorage.getItem(item);

  useEffect(() => {
    if (!isMobile) {
      return undefined;
    }

    if (selectorDebitsRedux.loading === false) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [selectorDebitsRedux.loading, isMobile]);

  useEffect(() => {
    if (setDetran) {
      setDetran(values.integration_id);
    }
  }, [values.integration_id]);

  useEffect(() => {
    const newValues: any = { ...values };

    if (Object.prototype.hasOwnProperty.call(newValues, 'renavam')) {
      newValues.renavam =
        getItemSession('renavam') || query.get('renavam') || undefined;
    }

    if (Object.prototype.hasOwnProperty.call(newValues, 'placa')) {
      newValues.placa =
        getItemSession('placa') || query.get('placa') || undefined;
    }

    if (Object.prototype.hasOwnProperty.call(newValues, 'document_number')) {
      newValues.document_number =
        getItemSession('document_number') ||
        query.get('document_number') ||
        undefined;
    }

    if (getItemSession('integration_id')) {
      newValues.integration_id = getItemSession('integration_id');
    }

    setValues(newValues);

    setTimeout(() => validateForm(), 500);
  }, []);

  const handleSubmit = () => {
    const fieldsToSave = [
      'renavam',
      'placa',
      'document_number',
      'integration_id',
    ];

    if (!signedIn) {
      fieldsToSave.map((f) => {
        if (values[f]) {
          window.sessionStorage.setItem(f, values[f]);
        }
      });

      window.location.href = `${ROUTES.EXTERNAL.AUTH.LOGIN}?redirectUrl=${currentUrl}`;

      return undefined;
    }

    if (signedIn) {
      fieldsToSave.map((f) => {
        window.sessionStorage.removeItem(f);
      });
    }

    dispatchDebitsRedux.UPDATE_FILTERS({
      ...debits.initialState.filters,
      placa: values?.placa,
      renavam: values?.renavam,
      document_number: values?.document_number,
      integration_id: values?.integration_id,
    });

    const valuesQs = qs.stringify({ ...values });

    const path = [ROUTES.DEBTS.DETRAN, valuesQs].join('?');

    dispatchRouterRedux.push(path);
  };

  return (
    <FormSelectorDebitComponent
      errors={errors}
      type={type}
      apps={selectorAppsRedux.data}
      handleSubmit={handleSubmit}
      loading={selectorDebitsRedux.loading}
      setDetran={setDetran}
    />
  );
};

const FormSelectorDebitComponent = ({
  errors,
  type,
  apps,
  handleSubmit,
  loading,
  setDetran,
}) => {
  return (
    <Box display="flex" width="100%" flexDirection="column" gap="20px">
      <Box
        display="grid"
        gridTemplateColumns="1fr"
        alignItems="start"
        gap="20px"
      >
        {setDetran && apps?.length > 0 && (
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso_site.rosa" variant="trasso_site_caption">
              Selecione o Detran
            </Typography>

            <Field
              component={TextField}
              name="integration_id"
              fullWidth
              variant="outlined"
              select
              SelectProps={{
                native: true,
              }}
            >
              <option value=""></option>
              {apps
                ?.filter(
                  (a) =>
                    a.status === 'ACTIVE' &&
                    a.filter_type !== 3 &&
                    a.filter_type !== 4
                )
                .map((a) => (
                  <option value={a.id} key={a.id}>
                    DETRAN - {a.description}
                  </option>
                ))}
            </Field>
          </Box>
        )}

        {type === 'placaRenavam' && <FormPlacaRenavam />}
        {type === 'renavam' && <FormRenavam />}
        {type === 'document' && <FormDocument />}
        {type === 'plate' && <FormPlate />}
      </Box>

      <Button
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        disabled={Object.keys(errors).length > 0}
        loading={loading}
        color="secondary"
        sx={{
          color: 'white',
          height: 59,
          fontSize: '20px',
          borderRadius: '15px',
        }}
      >
        Avançar
      </Button>
    </Box>
  );
};

export default function FormSelectorDebitFormik({
  currentDetran,
  setCurrentDetran,
}: {
  currentDetran: any;
  setCurrentDetran?: any;
}) {
  const dispatch = useDispatch();

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
  };

  const currentDetranObj = selectorAppsRedux?.data?.find(
    (s) => s.id === currentDetran
  );

  let type = 'placaRenavam';

  if (!currentDetranObj) {
    type = 'none';
  }

  if (currentDetranObj?.filter_type === 1) {
    type = 'renavam';
  }

  if (currentDetranObj?.filter_type === 2) {
    type = 'document';
  }

  if (currentDetranObj?.filter_type === 5) {
    type = 'plate';
  }

  const schemaWithDetran = Yup.object({
    integration_id: Yup.string()
      .required(messages.REQUIRED)
      .default(currentDetran),
  });

  const validationTypes = {
    placaRenavam: validationSchemaPlacaRenavam.concat(schemaWithDetran),
    renavam: validationSchemaRenavam.concat(schemaWithDetran),
    document: validationSchemaDocument.concat(schemaWithDetran),
    plate: validationSchemaPlate.concat(schemaWithDetran),
    none: validationSchemaPlacaRenavam.concat(schemaWithDetran),
  };

  return (
    <Formik
      key={currentDetran}
      initialValues={{ ...validationTypes[type].cast(undefined) }}
      onSubmit={() => {}}
      validationSchema={validationTypes[type]}
      validateOnMount
    >
      <FormSelectorDebitContainer type={type} setDetran={setCurrentDetran} />
    </Formik>
  );
}
