import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/lab/LoadingButton';
import { useFormikContext, Field } from 'formik';
import { Typography } from '@mui/material';
import { TextField } from 'formik-mui';

import theme from '@giro/client-app/src/constants/theme.constant';

import useAuth from '@giro/shared-hooks/useAuth.hook';

import useDialog from '@giro-app/hooks/useDialogRedux.hook';

import { CHECKOUT_STEPS } from '@giro-app/modules/checkout/constants/stepsFormCheckout.constant';
import { PAYMENT_TYPE } from '@giro-app/modules/checkout/constants/paymentType.constant';

import dialogCardDetail from '@giro-app/modules/checkout/store/dialogs/dialogCardDetail';

import useCheckoutHook from '../../hooks/useCheckout.hook';

import FormCheckoutPaymentCard from '../Form/FormCheckout/FormCheckoutPaymentCard.component';

import BoxInfosPaymentCardComponent from './BoxInfosPaymentCard.component';

const BoxPaymentConfirmationComponent = ({
  isCardTwo,
  totalSelected,
  handleBack,
  loading,
  handleUpdatePaymentType,
}) => {
  const checkout = useCheckoutHook();

  const { values, errors, submitForm } = useFormikContext();

  const firstCardAmount = Number((values as any)?.firstCard.amount || 0);

  const isConfimPayment = firstCardAmount === totalSelected || isCardTwo;
  const isAddAnotherCard = firstCardAmount < totalSelected && !isCardTwo;

  const handleUpdatesCards = () => {
    checkout.handleUpdateCard(values as any);
  };

  const handleAddSecondCard = () => {
    handleUpdatesCards();

    setTimeout(() => {
      handleUpdatePaymentType(PAYMENT_TYPE.CARD_TWO);
    });
  };

  const handleFinishPayment = () => {
    handleUpdatesCards();

    setTimeout(() => {
      submitForm();
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      {isConfimPayment && (
        <Button
          variant="contained"
          onClick={() => handleFinishPayment()}
          loading={loading}
          sx={{
            fontWeight: 500,
            height: '59px',
            fontSize: '20px',
            backgroundColor: 'trasso_site.rosa',
            borderRadius: '15px',
            color: 'white',
            '&:hover': {
              backgroundColor: 'trasso.purple.60',
            },
          }}
        >
          Pagar Agora
        </Button>
      )}

      {isAddAnotherCard && (
        <Button
          variant="contained"
          onClick={() => handleAddSecondCard()}
          disabled={Object.keys(errors).length > 0}
          color="info"
          sx={{
            fontWeight: 500,
            height: '59px',
            fontSize: '20px',
            borderRadius: '15px',
            color: 'white',
            '&:hover': {
              backgroundColor: 'trasso.purple.60',
            },
          }}
        >
          Adicionar em outro cartão:{' '}
          {Number(totalSelected - firstCardAmount).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Button>
      )}
    </Box>
  );
};

export default BoxPaymentConfirmationComponent;
