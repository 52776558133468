import React, { Fragment } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { format, addMinutes } from 'date-fns';

import themes from '@giro/client-app/src/constants/theme.constant';

import useListHook from '@giro-app/hooks/useListRedux.hook';

import payment from '../../store/payment';

function formatDate(date, formatter) {
  return format(addMinutes(date, date.getTimezoneOffset()), formatter);
}

const maskDocument = (document) => {
  const stringDocument = String(document);

  if (stringDocument.length === 11) {
    return stringDocument.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
      '$1.$2.$3-$4'
    );
  }
  if (stringDocument.length === 14) {
    return stringDocument.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
      '$1.$2.$3/$4-$5'
    );
  }
};

const ListDetailsOrderComponent = () => {
  const { data } = useListHook(payment);

  const details = data?.details || [];

  const beneficiary = (d) =>
    !d?.beneficiary?.document?.number
      ? d.payer?.name
      : `${maskDocument(d?.beneficiary?.document?.number)} - ${
          d?.beneficiary?.name
        }`;

  return (
    <Box display="grid" gridTemplateColumns="100%">
      <Box p={2} rowGap={2} display="grid" gridTemplateColumns="100%">
        {data?.description && (
          <Box display="flex" flexDirection="column">
            <Typography color="gray.400" variant="subtitle2">
              Descrição do pagamento
            </Typography>
            <Typography color="info.dark2" variant="subtitle1">
              {data?.description}
            </Typography>
          </Box>
        )}

        {details.map((d, index) => (
          <Fragment key={`box-detail-order-${index}`}>
            <Box
              sx={{
                borderTop: `1px solid ${
                  index !== 0 ? themes.palette.gray[200] : 'transparent'
                }`,
              }}
              display="grid"
              rowGap={2}
              gridTemplateColumns="100%"
            >
              <Typography color="info.dark2" variant="body" fontWeight={600}>
                Valor:{' '}
                {Number(d.amount).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
              <Box display="flex" flexDirection="column">
                <Typography color="gray.400" variant="subtitle2">
                  Número registro
                </Typography>
                <Typography
                  color="info.dark2"
                  variant="subtitle1"
                  css={{
                    wordWrap: 'break-word',
                  }}
                >
                  {d.reference.split(';').slice(-2)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography color="gray.400" variant="subtitle2">
                  Detalhes
                </Typography>
                <Typography color="info.dark2" variant="subtitle1">
                  {d.description}
                </Typography>
              </Box>
              {d?.payer && (
                <>
                  <Box>
                    <Divider />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography color="gray.400" variant="subtitle2">
                      Vencimento
                    </Typography>
                    <Typography color="info.dark2" variant="subtitle1">
                      {formatDate(new Date(d?.due_date), 'dd/MM/yyyy')}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography color="gray.400" variant="subtitle2">
                      Beneficiário
                    </Typography>
                    <Typography color="info.dark2" variant="subtitle1">
                      {beneficiary(d)}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default ListDetailsOrderComponent;
