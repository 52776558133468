import { ACTION_TYPES } from './list.constant';

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchEnd = (payload) => ({
  type: ACTION_TYPES.FETCH.END,
  payload,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const updateFilters = (filters) => ({
  type: ACTION_TYPES.UPDATE.FILTERS,
  filters,
});

export const updateSelected = (selected) => ({
  type: ACTION_TYPES.UPDATE.SELECTED,
  selected,
});

export const updateInstallment = (payload) => ({
  type: ACTION_TYPES.UPDATE.INSTALLMENT,
  payload,
});

export const updatePartner = (payload: boolean) => ({
  type: ACTION_TYPES.UPDATE.PARTNER,
  payload,
});

export const select = (payload) => ({
  type: ACTION_TYPES.SELECT,
  payload,
});

export const selectAll = () => ({
  type: ACTION_TYPES.SELECT_ALL,
});

export const unselect = (payload) => ({
  type: ACTION_TYPES.UNSELECT,
  payload,
});

export const unselectAll = () => ({
  type: ACTION_TYPES.UNSELECT_ALL,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});

export const resetFilters = () => ({
  type: ACTION_TYPES.RESET.FILTERS,
});

export const resetSelected = () => ({
  type: ACTION_TYPES.RESET.SELECTED,
});

export const resetLocalStorage = () => ({
  type: ACTION_TYPES.RESET.RESET_LOCAL_STORAGE,
});

export const resetMode = () => ({
  type: ACTION_TYPES.RESET.PARTNER,
});

export const payDetran = () => ({
  type: ACTION_TYPES.PAY.DETRAN,
});

export const payBillet = () => ({
  type: ACTION_TYPES.PAY.BILLET,
});

export const payBilletPartner = () => ({
  type: ACTION_TYPES.PAY.PARTNER,
});

export const servicePostTokenPartner = () => ({
  type: ACTION_TYPES.SERVICE.POST_TOKEN_PARTNER,
});
