import { flow } from 'lodash';

import { NAME } from './dialogDetran.constant';

import { selectDialogsState } from '../dialogs.selector';

const selectState = (state) => state[NAME];
export const selectDialogAppState = flow(selectDialogsState, selectState);

const selectVisibleKey = (state) => state.visible;
const selectPayloadKey = (state) => state.payload;

export const selectVisible = flow(selectDialogAppState, selectVisibleKey);
export const selectPayload = flow(selectDialogAppState, selectPayloadKey);
