import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'lodash';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

import Logo from '@giro/shared-components/Logo.component';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';

import useDialogHook from '@giro-app/hooks/useDialogRedux.hook';

import ROUTES from '@giro-app/constants/routes.constant';
import theme from '@giro/client-app/src/constants/theme.constant';

import auth from '@giro/shared-store/auth';
import router from '@giro/shared-store/router';
import debts from '@giro-app/modules/debts/store';
import misc from '@giro-app/store/configs/misc';

import drawerMenuMobile from '@giro-app/store/configs/drawers/drawerMenuMobile';
import dialogContactUs from '@giro-app/store/configs/dialogs/dialogContactUs';

const LINKS = [];

const styleHover = (white) => ({
  color: white ? 'white' : 'secondary.main',
  borderColor: white ? 'white' : 'secondary.main',
});

const style = (white) => ({
  listStyle: 'none',
  cursor: 'pointer',
  '&:hover': styleHover(white),
  paddingBottom: 0.5,
  borderBottom: '2px solid transparent',
  color: white ? 'white' : 'info.dark2',
  ...theme.typography.subtitle1,
});

const NavHeaderComponent = ({ white }) => {
  const { handleOpen } = useDialogHook(dialogContactUs);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();

  const dispatchRedux = {
    UNSET_TOKEN: flow(auth.action.unsetToken, dispatch),
  };

  const dispatchDrawerMenuRedux = {
    open: flow(drawerMenuMobile.action.open, dispatch),
    reset: flow(drawerMenuMobile.action.reset, dispatch),
  };

  const dispatchRouterRedux = {
    push: flow(router.action.push, dispatch),
    routeChange: flow(router.action.routeChange, dispatch),
  };

  const selectorRedux = {
    SIGNEDIN: useSelector(auth.selector.selectSignedIn),
    debts: useSelector(debts.selector.selectState),
    removeLogin: useSelector(misc.selector.selectRemoveLogin),
  };

  const selectorDrawerMenu = {
    visible: useSelector(drawerMenuMobile.selector.selectVisible),
  };

  const selectorRouterRedux = {
    pathname: useSelector(router.selector.selectPathname),
  };

  const removeLogin = selectorRedux.removeLogin;

  const isActiveModule = (module) => {
    const [, moduleActive] = selectorRouterRedux?.pathname?.split('/') || [];

    if (module === '/') {
      return moduleActive === '';
    }

    return moduleActive === module;
  };

  useEffect(() => {
    if (selectorDrawerMenu.visible) {
      dispatchDrawerMenuRedux.reset();
    }
  }, [selectorRouterRedux.pathname]);

  const desktop = (
    <Box
      component="ul"
      gap={3}
      padding={0}
      margin={0}
      display="flex"
      alignItems="center"
    >
      {selectorRedux.SIGNEDIN && (
        <Fragment>
          <Box
            component="li"
            px={0.5}
            display="flex"
            alignItems="center"
            gap={0.5}
            onClick={() => dispatchRouterRedux.push(ROUTES.ORDERS.ROOT)}
            sx={Object.assign(
              {},
              style(white),
              isActiveModule('orders') && styleHover(white)
            )}
          >
            Meus Pedidos
          </Box>
          <Box
            component="li"
            px={0.5}
            display="flex"
            alignItems="center"
            gap={0.5}
            onClick={() => dispatchRouterRedux.push(ROUTES.PROFILE.ROOT)}
            sx={Object.assign(
              {},
              style(white),
              isActiveModule('profile') && styleHover(white)
            )}
          >
            <AccountCircleIcon
              css={{
                fill: '#240045',
                width: 40,
                height: 40,
                '&:hover': {
                  fill: '#D776FF',
                },
              }}
            />
          </Box>
        </Fragment>
      )}

      {!selectorRedux.SIGNEDIN && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            ((window as any).location.href = ROUTES.EXTERNAL.AUTH.LOGIN)
          }
        >
          <Typography
            variant="trasso_site_corpo"
            fontWeight={500}
            color="white"
            whiteSpace="nowrap"
          >
            Entrar
          </Typography>
        </Button>
      )}
    </Box>
  );

  const items = [
    {
      label: 'Débitos Detrans',
      href: null,
      onClick: () =>
        window.open('https://blog.giropagamentos.com.br/', '_blank'),
    },
    {
      label: 'Contas e Boletos',
      href: null,
      onClick: () =>
        window.open('https://blog.giropagamentos.com.br/', '_blank'),
    },
    {
      label: 'Empresas',
      href: null,
      onClick: () =>
        window.open('https://blog.giropagamentos.com.br/', '_blank'),
    },
    {
      label: 'Sobre nós',
      href: null,
      onClick: () =>
        window.open('https://blog.giropagamentos.com.br/', '_blank'),
    },
    {
      label: 'Blog',
      href: null,
      onClick: () =>
        window.open('https://blog.giropagamentos.com.br/', '_blank'),
    },
  ];

  const mobile = (
    <Fragment>
      <Box
        sx={{ cursor: 'pointer', color: white ? 'white' : 'secondary.main' }}
        onClick={() => dispatchDrawerMenuRedux.open({})}
      >
        <MenuIcon css={{ color: 'inherit', fontSize: 30 }} />
      </Box>

      <Drawer
        open={selectorDrawerMenu.visible}
        onClose={() => dispatchDrawerMenuRedux.reset()}
        anchor="right"
      >
        <Box
          width="100vw"
          minHeight="100vh"
          bgcolor="trasso_site.roxo"
          p={4}
          gap={4}
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box onClick={() => {}}>
              <Logo variant="pdv" />
            </Box>

            <IconButton
              sx={{ color: 'trasso_site.rosa', px: 0 }}
              onClick={() => dispatchDrawerMenuRedux.reset()}
            >
              <CloseIcon style={{ fontSize: 30 }} />
            </IconButton>
          </Box>

          <List component="nav">
            {selectorRedux.SIGNEDIN && (
              <>
                <ListItemButton
                  onClick={() => dispatchRouterRedux.push(ROUTES.ORDERS.ROOT)}
                  sx={{ px: 0 }}
                >
                  <ListItemText
                    primary={'Meus pedidos'}
                    sx={{ color: 'white' }}
                  />
                </ListItemButton>
                <ListItemButton
                  onClick={() => dispatchRouterRedux.push(ROUTES.PROFILE.ROOT)}
                  sx={{ px: 0 }}
                >
                  <ListItemText
                    primary={'Minha conta'}
                    sx={{ color: 'white' }}
                  />
                </ListItemButton>
              </>
            )}

            {selectorRedux.SIGNEDIN && (
              <>
                <ListItemButton
                  onClick={() => dispatchRedux.UNSET_TOKEN()}
                  sx={{ px: 0 }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={{ color: 'white' }}
                  >
                    <Typography whiteSpace="nowrap">Sair</Typography>
                  </Button>
                </ListItemButton>
              </>
            )}

            {!selectorRedux.SIGNEDIN && (
              <>
                <ListItemButton
                  onClick={() =>
                    ((window as any).location.href = ROUTES.EXTERNAL.AUTH.LOGIN)
                  }
                  sx={{ px: 0 }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{ color: 'white' }}
                  >
                    <Typography whiteSpace="nowrap">Entrar</Typography>
                  </Button>
                </ListItemButton>
              </>
            )}
          </List>
        </Box>
      </Drawer>
    </Fragment>
  );

  return isMobile ? mobile : desktop;
};

export default NavHeaderComponent;
