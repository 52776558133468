import { flow } from 'lodash';
import { NAME } from './list.constant';

import { selectState as selectStateDebts } from '../debts.selector';

const selectStateKey = (state) => state[NAME];
const selectLoadingKey = (state) => state.loading;
const selectErrorKey = (state) => state.error;
const selectDataKey = (state) => state.data;
const selectDataResultsKey = (state: any) => state?.results;
const selectDataMetadataKey = (state: any) => state?.metadata;
const selectSelectedKey = (state) => state.selected;
const selectFiltersKey = (state) => state.filters;
const selectIsPartnerKey = (state) => state.isPartner;
const selectInstallmentKey = (state) => state.installment;

export const selectState = flow(selectStateDebts, selectStateKey);
export const selectError = flow(selectState, selectErrorKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectData = flow(selectState, selectDataKey);
export const selectInstallment = flow(selectState, selectInstallmentKey);
export const selectDataResults = flow(
  selectState,
  selectDataKey,
  selectDataResultsKey
);
export const selectDataMetadata = flow(
  selectState,
  selectDataKey,
  selectDataMetadataKey
);
export const selectSelected = flow(selectState, selectSelectedKey);
export const selectFilters = flow(selectState, selectFiltersKey);
export const selectIsPartner = flow(selectState, selectIsPartnerKey);
