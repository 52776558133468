import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import FormSearchDebtBillet from './Form/FormSearchDebtBillet.component';
import FormSearchDebit from './Form/FormSearchDebitDetran.component';

import useProtectedByAuth from '@giro-app/hooks/useProtectedByAuth.hook';

import routesConstant from '@giro-app/constants/routes.constant';

import apps from '@giro-app/store/apps';
import router from '@giro/shared-store/router';

const Layout = ({ children }) => {
  const params: any = useParams();

  const selectRedux = {
    apps: useSelector(apps.selector.selectData),
  };

  const currentPartner = selectRedux.apps?.find(
    (e) => e.external_code === params.partner
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="calc(100vh - 412px)"
    >
      <Box
        width="100%"
        display="flex"
        gap={10}
        maxWidth={800}
        bgcolor="white"
        p={4}
        borderRadius={'4px'}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box alignSelf="center" gap={4} display="flex" flexDirection="column">
          <Box
            alignSelf="center"
            overflow="hidden"
            minWidth={{ md: '200px', xs: undefined }}
          >
            <img
              style={{ display: 'block', width: '100%' }}
              src={currentPartner?.logo_path}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={4}>
          <div
            dangerouslySetInnerHTML={{
              __html: currentPartner?.html_description,
            }}
          />
          <Box display="flex" flexDirection="column" gap={4} flex={1}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const DebtPartnerComponent = () => {
  useProtectedByAuth();

  const dispatch = useDispatch();
  const params: any = useParams();

  const selectRedux = {
    apps: useSelector(apps.selector.selectData),
    loading: useSelector(apps.selector.selectLoading),
  };

  const currentPartner = selectRedux.apps?.find(
    (e) => e.external_code === params.partner
  );

  const isLoading = [true, undefined, null].includes(selectRedux.loading);
  const isBillet = !isLoading && currentPartner?.filter_type === 3;
  const isReference = !isLoading && currentPartner?.filter_type === 4;
  const isDetran =
    !isLoading && [0, 1, 2].includes(currentPartner?.filter_type);

  React.useEffect(() => {
    if (isReference)
      dispatch(
        router.action.push(
          `${routesConstant.DEBTS.REFERENCE}?reference_id=${params?.barcode}&integration_id=${currentPartner?.id}`
        )
      );
  }, [isReference]);

  return (
    <>
      {isDetran && (
        <Layout>
          <Box width="100%" alignSelf="center">
            <FormSearchDebit currentDetran={currentPartner?.id} />
          </Box>
        </Layout>
      )}

      {isBillet && (
        <Layout>
          <Box width="100%" alignSelf="center">
            <FormSearchDebtBillet integrationId={currentPartner?.id} />
          </Box>
        </Layout>
      )}

      {isLoading && (
        <Box
          height="calc(100vh - 412px)"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default DebtPartnerComponent;
