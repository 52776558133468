import { takeLatest, select, call, put } from 'redux-saga/effects';

import router from '@giro/shared-store/router';
import auth from '@giro/shared-store/auth';

import getApiDebtsService from '@giro/shared-services/debts/getApiDebts.service';
import postApiAppsIdTokenServiceService from '@giro/shared-services/debts/postApiAppsIdToken.service';

import { DEBTS_MODE_KEY } from '@giro-app/configs/misc.config';

import ROUTES from '@giro-app/constants/routes.constant';

import debits from '.';

function* handleFetchAPI() {
  const filters = yield select(debits.selector.selectFilters);
  const [success, body] = yield call(getApiDebtsService, filters);

  if (!success) {
    return yield put(debits.action.fetchError(body));
  }

  return yield put(debits.action.fetchEnd(body));
}

function handlePersitStorage(mode) {
  window.localStorage.setItem(DEBTS_MODE_KEY, mode);
}

function* handlePayBilletPartner() {
  //
}

function* handlePayBillet() {
  yield put(router.action.push(ROUTES.DEBTS.BILLET));
}

function* handlePayDetran() {
  yield put(router.action.push(ROUTES.DEBTS.DETRAN));
}

function* handleResetLocalStorage() {
  yield call(() => {
    window.localStorage.removeItem(DEBTS_MODE_KEY);
  });
}

function* handleServicePostTokenPartner() {
  const { integration_id } = yield select(debits.selector.selectFilters);

  try {
    const [success, result] = yield call(
      postApiAppsIdTokenServiceService,
      integration_id
    );

    if (!success) {
      throw result;
    }

    yield put(auth.action.setToken(result.token));
  } catch (e) {
    return false;
  }
}

function* watch() {
  yield takeLatest(debits.constant.ACTION_TYPES.FETCH.START, handleFetchAPI);
  yield takeLatest(debits.constant.ACTION_TYPES.UPDATE.FILTERS, handleFetchAPI);
  yield takeLatest(
    debits.constant.ACTION_TYPES.PAY.PARTNER,
    handlePayBilletPartner
  );
  yield takeLatest(debits.constant.ACTION_TYPES.PAY.BILLET, handlePayBillet);
  yield takeLatest(debits.constant.ACTION_TYPES.PAY.DETRAN, handlePayDetran);
  yield takeLatest(
    debits.constant.ACTION_TYPES.RESET.RESET_LOCAL_STORAGE,
    handleResetLocalStorage
  );
  yield takeLatest(
    debits.constant.ACTION_TYPES.SERVICE.POST_TOKEN_PARTNER,
    handleServicePostTokenPartner
  );
}

export default {
  watch,
};
