import { Switch, Route } from 'react-router-dom';

import AppLayout from '@giro-app/layouts/App.layout';

import Dialog from '@giro-app/factories/Dialog.factory';

import ROUTES from '@giro-app/constants/routes.constant';

import useProtectedByAuth from '@giro-app/hooks/useProtectedByAuth.hook';

import ProfileScreen from './screens/Profile.screen';

export default function ProfileModule() {
  useProtectedByAuth();

  return (
    <AppLayout>
      <Switch>
        <Route exact path={ROUTES.PROFILE.ROOT} component={ProfileScreen} />
      </Switch>

      <Dialog variant="profileChangePassword" />
    </AppLayout>
  );
}
