import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import useList from '@giro-app/hooks/useListRedux.hook';

import debits from '../store/list';

import { listsPropTypes } from '@giro-app/hooks/useListRedux.hook';

type propTypes = listsPropTypes & {
  handleSelectAll: any;
  handleUnselectAll: any;
  handleUpdateSelected: any;
  selected: any;
  installments: any;
  handleUpdateInstallment: any;
};

export default function useListDebitsHook(): propTypes {
  const listProps = useList(debits);

  const dispatch = useDispatch();

  const dispatchDebitsRedux = {
    SELECT_ALL: flow(debits.action.selectAll, dispatch),
    UNSELECT_ALL: flow(debits.action.unselectAll, dispatch),
    updateSelected: flow(debits.action.updateSelected, dispatch),
    updateInstallment: flow(debits.action.updateInstallment, dispatch),
  };

  const selectorDebitsRedux = {
    selected: useSelector(debits.selector.selectSelected),
    filters: useSelector(debits.selector.selectFilters),
    installments: useSelector(debits.selector.selectInstallment),
  };

  const props: propTypes = {
    ...listProps,
    selected: selectorDebitsRedux.selected,
    filters: selectorDebitsRedux.filters,
    installments: selectorDebitsRedux.installments,
    handleUpdateSelected: dispatchDebitsRedux.updateSelected,
    handleUpdateInstallment: dispatchDebitsRedux.updateInstallment,
    handleSelectAll: dispatchDebitsRedux.SELECT_ALL,
    handleUnselectAll: dispatchDebitsRedux.UNSELECT_ALL,
  };

  return props;
}
