import { takeLatest, select, call, put, delay } from 'redux-saga/effects';

import toaster from '@giro/shared-store/toaster';

import viaCepService from '@giro/shared-services/viaCep.service';

import auth from '@giro/shared-store/auth';
import { handleRefreshToken } from '@giro/shared-store/auth/auth.saga';

import postApiCheckoutsService, {
  BodyType,
} from '@giro/shared-services/checkout/postApiCheckouts.service';
import getApiCheckoutsReferenceIdService from '@giro/shared-services/checkout/getApiCheckoutsReferenceId.service';
import postApiCheckoutsReferenceIdVerificationsService from '@giro/shared-services/checkout/postApiCheckoutsReferenceIdVerifications.service';
import postApiAccountsResendConfirmationPhoneService from '@giro/shared-services/users/postApiAccountsResendConfirmationPhone.service';
import postApiAccountsConfirmPhoneService from '@giro/shared-services/users/postApiAccountsConfirmPhone.service';
import postApiAuthsRefreshService from '@giro/shared-services/users/postApiAuthsRefresh.service';
import postApiReservesService from '@giro/shared-services/checkout/postApiReserves.service';
import postApiReservesIdService from '@giro/shared-services/checkout/postApiReservesId.service';

import { PAYMENT_TYPE } from '@giro-app/modules/checkout/constants/paymentType.constant';
import { CHECKOUT_STEPS } from '@giro-app/modules/checkout/constants/stepsFormCheckout.constant';

import dialogErrorPayment from '../dialogs/dialogErrorPayment';
import dialogPreAuth from '../dialogs/dialogPreAuth';

import checkout from '.';
import apps from '@giro-app/store/apps';

import { VERIFY_STEPS } from '@giro-app/modules/checkout/constants/verifySteps.constant';

import verifyOnboardingUtil from '@giro-app/utils/verifyOnboarding.util';

function* handleFetchAPI() {
  const signedIn = yield select(auth.selector.selectSignedIn);
  const user = yield select(auth.selector.selectUser);
  const appsState = yield select(apps.selector.selectState);
  const authState = yield select(auth.selector.selectState);

  const { integration_id, reference_id, debits } = yield select(
    checkout.selector.selectDebit
  );
  const formDataPayment = yield select(checkout.selector.selectFormDataPayment);
  const formDataAntiFraud = yield select(
    checkout.selector.selectFormDataAntiFraud
  );
  const paymentTypeCheckout = yield select(checkout.selector.selectPaymentType);
  const sessionId = yield select(checkout.selector.selectSessionId);
  const app = yield select(checkout.selector.selectApp);

  const sessionId_prefix = app?.validation_session_prefix || 'giropagamentos';

  const sendToApi: BodyType = {
    integration_id: integration_id,
    reference_id: reference_id,
    amount: Number(
      debits.reduce((acc, curr) => acc + curr.amount, 0).toFixed(2)
    ),
    details: debits.map((selected) => ({
      reference: selected.reference,
      description: selected.description,
      amount: selected.amount,
    })),
    payment: {
      description: formDataPayment.description,
      type: [
        PAYMENT_TYPE.CARD,
        PAYMENT_TYPE.CARD_ONE,
        PAYMENT_TYPE.CARD_TWO,
      ].includes(paymentTypeCheckout)
        ? 'CARTAO'
        : 'PIX',
      address: {
        state: formDataPayment.address.state,
        street: formDataPayment.address.street,
        country: formDataPayment?.address?.country || 'BR',
        countryCode: formDataPayment?.address?.countryCode || '55',
        city: formDataPayment.address.city,
        postalCode: formDataPayment.address.postalCode,
        neighborhood: formDataPayment.address.neighborhood,
        number: formDataPayment.address.number || 'SN',
        complement: formDataPayment.address.complement,
      },
      cards: [
        {
          charge_id: formDataPayment.firstCard.charge_id,
          number: formDataPayment.firstCard.number,
          amount: formDataPayment.firstCard.amount_total,
          amount_original: formDataPayment.firstCard.amount,
          installments: formDataPayment.firstCard.installments,
          exp_month: formDataPayment.firstCard.exp.substring(0, 2),
          exp_year: formDataPayment.firstCard.exp.substring(2),
          security_code: formDataPayment.firstCard.security_code,
          holder: {
            name: formDataPayment.firstCard.name,
            document_number: formDataPayment.firstCard.document,
          },
        },
      ],
    },
    metadata: {
      fingerprint: `${sessionId_prefix}${sessionId}`,
    },
  };

  if (PAYMENT_TYPE.CARD_TWO === paymentTypeCheckout) {
    sendToApi.payment.cards.push({
      charge_id: formDataPayment.secondCard.charge_id,
      number: formDataPayment.secondCard.number,
      amount: formDataPayment.secondCard.amount_total,
      amount_original: formDataPayment.secondCard.amount,
      installments: formDataPayment.secondCard.installments,
      exp_month: formDataPayment.secondCard.exp.substring(0, 2),
      exp_year: formDataPayment.secondCard.exp.substring(2),
      security_code: formDataPayment.secondCard.security_code,
      holder: {
        name: formDataPayment.secondCard.name,
        document_number: formDataPayment.secondCard.document,
      },
    });
  }

  sendToApi.amount = sendToApi.payment.cards.reduce(
    (acc, curr) => acc + curr.amount,
    0
  );

  const isUserComplete = verifyOnboardingUtil(
    integration_id,
    appsState,
    authState
  )?.checked_onboarding;

  const service =
    signedIn && isUserComplete
      ? postApiCheckoutsService
      : postApiReservesService;

  const [success, result] = yield call(service, sendToApi);

  if (success && signedIn && isUserComplete) {
    yield put(checkout.action.updateCurrentstep(CHECKOUT_STEPS.FINISH));

    yield put(checkout.action.fetchEnd(result));

    yield put(checkout.action.fetchUpdate());
  }

  if (success && (!signedIn || !isUserComplete)) {
    yield put(dialogPreAuth.action.open({}));

    yield put(checkout.action.fetchEnd(result));
  }

  if (!success) {
    yield put(checkout.action.fetchError(result));
    yield put(dialogErrorPayment.action.open(result));
  }
}

function* handleGetZipCode(action) {
  const { zipcode }: any = action;

  const onlyZipCode = zipcode.replace(/\D/g, '');

  const data = yield select(checkout.selector.selectData);
  const formData = yield select(checkout.selector.selectFormData);

  const [success, result] = yield call(viaCepService, onlyZipCode);

  if (success) {
    yield put(
      checkout.action.updateFormdata({
        ...formData,
        payment: {
          ...formData.payment,
          address: {
            ...formData.payment.address,
            postalCode: result?.cep,
            state: result?.uf,
            city: result?.localidade,
            neighborhood: result?.bairro,
            street: result?.logradouro,
          },
        },
      })
    );
  }

  yield put(checkout.action.fetchEnd(data));
}

function* handleUpdateCheckout() {
  const data = yield select(checkout.selector.selectData);

  const { status } = data || {};

  const isProcessing = status === 'PROCESSANDO';

  if (!isProcessing) {
    return true;
  }

  yield delay(1000);

  const { reference_id } = yield select(checkout.selector.selectData);

  const [success, result] = yield call(
    getApiCheckoutsReferenceIdService,
    reference_id
  );

  if (success) {
    const currentStep = yield select(checkout.selector.selectCurrentStep);
    const [hasError] = yield select(checkout.selector.selectError);
    const loading = yield select(checkout.selector.selectLoading);

    const isStepFinish = currentStep === 'finish';

    if (result.status === 'PAGO') {
      yield delay(5000);
    }

    yield put(checkout.action.fetchEnd(result));

    if (isStepFinish && !hasError && loading === false) {
      if (result.status === 'EM VERIFICAÇÃO') {
        yield put(checkout.action.updateCurrentstep(CHECKOUT_STEPS.VERIFY));
        yield put(
          checkout.action.updateVerifystep(VERIFY_STEPS.CONFIRM_TRANSACTION)
        );
      }

      yield put(checkout.action.fetchUpdate());
    }
  }
}

function* handleUpdatePhone(action) {
  const { phone } = action;

  const [success] = yield call(postApiAccountsResendConfirmationPhoneService, {
    phone_number: phone,
  });

  if (success) {
    yield put(checkout.action.updateVerifystep(VERIFY_STEPS.CONFIRM_ACCOUNT));

    yield put(checkout.action.fetchEnd({}));
  }
}

function* handleConfirmAccount(action) {
  const { token } = action;

  const { phone_number } = yield select(checkout.selector.selectFormDataVerify);

  const [success, result] = yield call(postApiAccountsConfirmPhoneService, {
    phone_number,
    token,
  });

  if (success) {
    yield call(handleRefreshToken, postApiAuthsRefreshService);

    yield put(checkout.action.fetchStart());
  }

  if (!success) {
    yield put(
      toaster.action.show({ message: result?.message, variant: 'error' })
    );

    yield put(checkout.action.fetchError(result));
  }
}

function* handleConfirmTransaction(action) {
  const { token } = action;

  const { reference_id } = yield select(checkout.selector.selectDebit);

  const [success] = yield call(
    postApiCheckoutsReferenceIdVerificationsService,
    reference_id,
    token
  );

  if (success) {
    yield call(handleUpdateTransactionToProcess);

    yield put(checkout.action.updateCurrentstep(CHECKOUT_STEPS.FINISH));

    yield put(checkout.action.fetchUpdate());
  }
}

function* handleUpdateTransactionToProcess() {
  const data = yield select(checkout.selector.selectData);

  const newData = { ...data };

  newData.status = 'PROCESSANDO';

  yield put(checkout.action.fetchEnd(newData));
}

function* watch() {
  yield takeLatest(checkout.constant.ACTION_TYPES.FETCH.START, handleFetchAPI);
  yield takeLatest(
    checkout.constant.ACTION_TYPES.CONFIRM.ACCOUNT,
    handleConfirmAccount
  );
  yield takeLatest(
    checkout.constant.ACTION_TYPES.CONFIRM.TRANSACTION,
    handleConfirmTransaction
  );
  yield takeLatest(
    checkout.constant.ACTION_TYPES.FETCH.UPDATE,
    handleUpdateCheckout
  );
  yield takeLatest(
    checkout.constant.ACTION_TYPES.FETCH.START_ZIPCODE,
    handleGetZipCode
  );
  yield takeLatest(
    checkout.constant.ACTION_TYPES.UPDATE.PHONE,
    handleUpdatePhone
  );
}

export default {
  watch,
};
