import { flow } from 'lodash';
import { NAME } from './history.constant';

import { selectProfileState } from '../profile.selector';

const selectState = (state) => state[NAME];
export const selectHistoryState = flow(selectProfileState, selectState);

const selectLoadingKey = (state) => state.loading;
const selectErrorKey = (state) => state.error;
const selectDataKey = (state) => state.data;
const selectDataResultsKey = (state: any) => state?.results;
const selectDataMetadataKey = (state: any) => state?.metadata;
const selectSelectedKey = (state) => state.selected;
const selectFiltersKey = (state) => state.filters;

export const selectError = flow(selectHistoryState, selectErrorKey);
export const selectLoading = flow(selectHistoryState, selectLoadingKey);
export const selectData = flow(selectHistoryState, selectDataKey);
export const selectDataResults = flow(
  selectHistoryState,
  selectDataKey,
  selectDataResultsKey
);
export const selectDataMetadata = flow(
  selectHistoryState,
  selectDataKey,
  selectDataMetadataKey
);
export const selectSelected = flow(selectHistoryState, selectSelectedKey);
export const selectFilters = flow(selectHistoryState, selectFiltersKey);
