const CARD = 'card';
const CARD_ONE = 'card_one';
const CARD_TWO = 'card_two';
const PIX = 'pix';
const BILLING = 'billing';

export const PAYMENT_TYPE = {
  CARD,
  CARD_ONE,
  CARD_TWO,
  BILLING,
  PIX,
};
