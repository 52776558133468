import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

import useQuery from '@giro/shared-hooks/useQuery.hook';
import useListDebitsHook from '../hooks/useListDebits.hook';

import ReferenceComponent from './Reference.component';

import debits from '../store/list';

const NoFound = () => (
  <Box
    display="flex"
    flexDirection="column"
    gap={1}
    justifyContent="center"
    height="100%"
  >
    <Typography
      variant="h3"
      fontWeight={700}
      color="primary.main"
      align="center"
    >
      Não encontramos nenhum resultado para sua pesquisa
    </Typography>
    <Typography
      variant="body2"
      fontWeight={400}
      color="gray.400"
      align="center"
    >
      Pesquise novamente
    </Typography>
  </Box>
);

const DebtReferenceComponent = () => {
  const { handleUpdateFilters } = useListDebitsHook();
  const { results = [], loading } = useListDebitsHook();

  const query = useQuery();

  const filters = {
    reference: query.get('reference_id'),
    integration_id: query.get('integration_id'),
  };

  React.useEffect(() => {
    if (loading === true) {
      return undefined;
    }

    const hasFilters = Object.values(filters).every((f) => !!f);

    if (hasFilters) {
      handleUpdateFilters({
        ...debits.initialState.filters,
        ...filters,
      });
    }
  }, []);

  return (
    <Box
      display="grid"
      gap={4}
      minHeight={{
        lg: 'calc(100vh - 410px)',
      }}
      alignItems="flex-start"
      gridTemplateColumns={{
        xs: '1fr',
        lg: '1fr',
      }}
    >
      <Box position="relative" height="100%">
        {results?.length > 0 && <ReferenceComponent />}

        {results?.length === 0 && loading !== null && <NoFound />}

        {loading && (
          <Box
            bgcolor="white"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={9999999999999}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DebtReferenceComponent;
