import * as constant from './drawerOrder.constant';
import reducer from './drawerOrder.reducer';
import initialState from './drawerOrder.initialState';
import * as action from './drawerOrder.action';
import * as selector from './drawerOrder.selector';

export default {
  reducer,
  action,
  constant,
  initialState,
  name: constant.NAME,
  selector,
};
