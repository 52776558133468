import { v4 } from 'uuid';
import { createReducer } from '@reduxjs/toolkit';

import initialState from './checkout.initialState';

import { ACTION_TYPES } from './checkout.constant';

const handleReset = () => initialState;

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchEnd = (state, action) => {
  state.loading = false;
  state.data = action.payload;
  state.error = [false, null];
};

const handleFetchError = (state, action) => {
  state.loading = false;
  state.error = [true, action.payload];
};

const handleUpdateFormdata = (state, action) => {
  state.formData = action.formData;
};

const handleUpdateCurrentstep = (state, action) => {
  state.currentStep = action.currentStep;
};

const handleUpdatePaymenttype = (state, action) => {
  state.paymentType = action.paymentType;
};

const handleUpdateVerifyStep = (state, action) => {
  state.verifyStep = action.step;
};

const handleUpdateDebit = (state, action) => {
  state.debit = action.debit;
};

const handleUpdateApp = (state, action) => {
  state.app = action.app;
};

const handleUpdatePhone = (state, action) => {
  state.loading = true;
  state.formData.verify.phone_number = action.phone;
};

const handleSessionNew = (state) => {
  state.sessionId = `${+new Date()}`;
};

const handleUpdateState = (state, action) => action.payload;

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.STATE]: handleReset,
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.START_ZIPCODE]: handleFetchStart,
  [ACTION_TYPES.FETCH.END]: handleFetchEnd,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
  [ACTION_TYPES.UPDATE.FORMDATA]: handleUpdateFormdata,
  [ACTION_TYPES.UPDATE.CURRENTSTEP]: handleUpdateCurrentstep,
  [ACTION_TYPES.UPDATE.PAYMENTTYPE]: handleUpdatePaymenttype,
  [ACTION_TYPES.UPDATE.VERIFYSTEP]: handleUpdateVerifyStep,
  [ACTION_TYPES.UPDATE.DEBIT]: handleUpdateDebit,
  [ACTION_TYPES.UPDATE.APP]: handleUpdateApp,
  [ACTION_TYPES.UPDATE.PHONE]: handleUpdatePhone,
  [ACTION_TYPES.UPDATE.STATE]: handleUpdateState,
  [ACTION_TYPES.SESSION.NEW]: handleSessionNew,
  [ACTION_TYPES.CONFIRM.ACCOUNT]: handleFetchStart,
  [ACTION_TYPES.CONFIRM.TRANSACTION]: handleFetchStart,
});
