import { isEqual, isEmpty } from 'lodash';

import dialogSelectAllDebits from '../dialogs/dialogSelectAllDebits';

import debits from '.';

let nextState = {};
let prevState = {};

const resetSelected = (dispatch) => {
  if (isEmpty(nextState) || isEmpty(prevState)) {
    return null;
  }

  const selected = debits.selector.selectSelected(nextState);

  const prevFilters = debits.selector.selectFilters(prevState);
  const nextFilters = debits.selector.selectFilters(nextState);

  const shouldFiltersEqual = isEqual(prevFilters, nextFilters);

  if (!shouldFiltersEqual) {
    const hasSelected = selected.length > 0;
    const shouldFiltersEqualInitialState = isEqual(
      nextFilters,
      debits.initialState.filters
    );

    if (!shouldFiltersEqualInitialState && hasSelected) {
      dispatch(debits.action.resetSelected());
    }
  }
};

const selectAllDebitsWhenSelectLIC = (dispatch) => {
  if (isEmpty(nextState) || isEmpty(prevState)) {
    return null;
  }

  const prevSelected = debits.selector.selectSelected(prevState);
  const nextSelected = debits.selector.selectSelected(nextState);

  const nextVisibleDialog =
    dialogSelectAllDebits.selector.selectVisible(nextState);

  const results = debits.selector.selectDataResults(nextState);

  const shouldStatesNotEquals = !isEqual(nextSelected, prevSelected);

  const shouldNextSelectNotEqualsResults =
    nextSelected?.length !== results?.length;
  const shouldAddItem = nextSelected?.length > prevSelected?.length;
  const shouldRemoveItem = nextSelected?.length < prevSelected?.length;

  const hasDebitLIC = nextSelected?.some((s) => s.type === 'LIC');

  if (
    hasDebitLIC &&
    shouldStatesNotEquals &&
    shouldNextSelectNotEqualsResults
  ) {
    if (shouldAddItem && !nextVisibleDialog) {
      dispatch(dialogSelectAllDebits.action.open({}));
    }

    if (shouldRemoveItem) {
      dispatch(debits.action.unselectAll());
    }
  }
};

const subscribe = ({ getState, dispatch }) => {
  nextState = getState();

  resetSelected(dispatch);
  selectAllDebitsWhenSelectLIC(dispatch);

  prevState = nextState;
};

export default {
  subscribe,
};
