import { sortBy } from 'lodash';
import { takeLatest, select, call, put } from 'redux-saga/effects';

import getApiCheckoutsReferenceIdService from '@giro/shared-services/checkout/getApiCheckoutsReferenceId.service';

import { ACTION_TYPES } from './payment.constant';
import * as action from './payment.action';

function* handleGetData(actionType) {
  const { referenceId } = actionType;

  const [success, result] = yield call(
    getApiCheckoutsReferenceIdService,
    referenceId
  );

  if (success) {
    return yield put(action.fetchFinish(result));
  }

  return yield put(action.fetchError(result));
}

function* watch() {
  yield takeLatest(ACTION_TYPES.FETCH.START, handleGetData);
}

export default {
  watch,
};
