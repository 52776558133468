import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

const BoxInfo = ({ urlImage, title, url }) => {
  const history = useHistory();

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="trasso_site.roxo"
      borderRadius="30px"
      p={2}
      gap={2}
      flex={1}
      justifyContent="center"
      height={250}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'trasso_site.rosa',
          transition: 'background 0.3s ease',
        },
      }}
      onClick={() => history.push(url)}
    >
      <Box alignSelf="center">
        <img src={urlImage} />
      </Box>
      <Typography
        color="white"
        variant="trasso_site_h2"
        alignSelf="center"
        align="center"
      >
        {title}
      </Typography>
    </Box>
  );
};

const CheckoutLayout = ({ feats }) => {
  return (
    <Box display="flex" flexDirection="column" gap="40px">
      <Typography color="trasso_site.roxo2" variant="trasso_site_h3">
        Selecione uma categoria de serviço:
      </Typography>

      <Box display="flex" flexDirection="column" gap={'80px'}>
        <Box
          display="flex"
          flexDirection={{
            xs: 'column',
            md: 'row',
          }}
          gap={'20px'}
          position="relative"
        >
          {feats?.map((f, idx) => (
            <BoxInfo
              key={`feat-${idx}`}
              urlImage={f.urlImage}
              title={f.title}
              url={f.url}
            />
          ))}
        </Box>

        <Box
          justifyContent="center"
          display="flex"
          flexDirection={{
            xs: 'column',
            md: 'row',
          }}
          gap={'20px'}
          paddingTop={'20px'}
          position="relative"
          alignItems="center"
        >
          <Box
            component="img"
            src="/assets/group.png"
            sx={{
              display: 'block',
              width: {
                xs: '100%',
                md: '50%',
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CheckoutLayout;
