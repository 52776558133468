import { Switch, Route } from 'react-router-dom';

import AppLayout from '@giro-app/layouts/App.layout';

import Dialog from '@giro-app/factories/Dialog.factory';

import ROUTES from '@giro-app/constants/routes.constant';

import useProtectedByAuth from '@giro-app/hooks/useProtectedByAuth.hook';

import RequestsScreen from './screens/Requests.screen';

export default function OrdersModule() {
  useProtectedByAuth();

  return (
    <AppLayout>
      <Switch>
        <Route exact path={ROUTES.ORDERS.ROOT} component={RequestsScreen} />
      </Switch>

      <Dialog variant="profileSendReceipt" />
    </AppLayout>
  );
}
