import { ACTION_TYPES } from './apps.constant';

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchFinish = (payload) => ({
  type: ACTION_TYPES.FETCH.FINISH,
  payload,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET,
});
