import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import theme from '@giro/client-app/src/constants/theme.constant';

const BoxPaymentByPixComponent = () => {
  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={5}>
      <Box display="flex" gap={2} flexDirection="column">
        <Box
          p={2}
          border="1px solid black"
          borderRadius={1}
          sx={{ borderColor: 'gray.100' }}
        >
          <img src="https://via.placeholder.com/350x330" />
        </Box>
        <Typography
          align="center"
          variant="subtitle2"
          p={0.5}
          sx={{
            color: 'warning.dark2',
            backgroundColor: 'warning.light3',
            borderRadius: '4px',
          }}
        >
          Aguardando pagamento...
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="subtitle1">
          Acesse o aplicativo do banco em que você possui conta, escolher a
          opção para pagar por meio do QR Code e fazer a leitura do código com a
          câmera do seu celular.
        </Typography>
        <Typography variant="subtitle1" mt={2}>
          Ou copie o código e cole direto no aplicativo do seu banco
        </Typography>
        <Box mt={4}>
          <Button
            variant="contained"
            color="info"
            fullWidth
            size="small"
            sx={{
              ...theme.typography.body_sm,
              fontWeight: 500,
            }}
          >
            <ContentCopyIcon css={{ width: 18, height: 18, marginRight: 12 }} />
            Copiar código do PIX
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BoxPaymentByPixComponent;
