import { takeLatest, select, call, put } from 'redux-saga/effects';

import auth from '@giro/shared-store/auth';
import { handleRefreshToken } from '@giro/shared-store/auth/auth.saga';
import toaster from '@giro/shared-store/toaster';

import getApiAccountsIdService from '@giro/shared-services/users/getApiAccountsId.service';
import patchApiAccountsIdService from '@giro/shared-services/users/patchApiAccountsId.service';
import postApiAuthsRefreshService from '@giro/shared-services/users/postApiAuthsRefresh.service';

import { ACTION_TYPES } from './user.constant';
import * as action from './user.action';
import * as selectors from './user.selector';

function* handleGetData() {
  const user = yield select(auth.selector.selectUser);

  const [success, result] = yield call(getApiAccountsIdService, user?.user?.id);

  if (success) {
    return yield put(action.fetchFinish(result));
  }

  return yield put(action.fetchError(result));
}

function* handleUpdateUser(actionType) {
  const { values, toaster: toasterBoolean } = actionType;

  const data = yield select(selectors.selectData);
  const user = yield select(auth.selector.selectUser);

  const userId = user?.user?.id;

  const [success, result] = yield call(
    patchApiAccountsIdService,
    userId,
    values
  );

  const [successRefresh, resultRefresh] = yield call(
    handleRefreshToken,
    postApiAuthsRefreshService
  );

  if (success && successRefresh) {
    yield put(action.fetchFinish(data));

    if (toasterBoolean) {
      yield put(
        toaster.action.show({
          message: 'Usuário atualizado com sucesso',
          variant: 'success',
        })
      );
    }

    return yield put(action.fetchStart());
  }

  yield put(
    toaster.action.show({
      variant: 'error',
    })
  );

  return yield put(
    action.fetchError({
      result,
      resultRefresh,
    })
  );
}

function* watch() {
  yield takeLatest(ACTION_TYPES.FETCH.START, handleGetData);
  yield takeLatest(ACTION_TYPES.FETCH.USER_START, handleUpdateUser);
}

export default {
  watch,
};
