import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/lab/LoadingButton';
import { useFormikContext, Field } from 'formik';
import { Typography } from '@mui/material';
import { TextField } from 'formik-mui';

import theme from '@giro/client-app/src/constants/theme.constant';

import useAuth from '@giro/shared-hooks/useAuth.hook';

import useDialog from '@giro-app/hooks/useDialogRedux.hook';

import { CHECKOUT_STEPS } from '@giro-app/modules/checkout/constants/stepsFormCheckout.constant';
import { PAYMENT_TYPE } from '@giro-app/modules/checkout/constants/paymentType.constant';

import dialogCardDetail from '@giro-app/modules/checkout/store/dialogs/dialogCardDetail';

import useCheckoutHook from '../../hooks/useCheckout.hook';

import FormCheckoutPaymentCard from '../Form/FormCheckout/FormCheckoutPaymentCard.component';

import BoxInfosPaymentCardComponent from './BoxInfosPaymentCard.component';

const BoxPaymentByCardComponent = ({
  isCard,
  isCardTwo,
  isCardUnique,
  totalSelected,
  handleNext,
  handleBack,
  handleUpdatePaymentType,
  paymentType,
  loading,
}) => {
  const { handleOpen } = useDialog(dialogCardDetail);
  const { values } = useFormikContext();

  const firstCardAmountWithFee = Number(
    (values as any)?.firstCard.amount_total || 0
  );
  const firstCardInstallments = Number((values as any)?.firstCard.installments);

  return (
    <Box display="flex" flexDirection="column">
      {isCardTwo && (
        <BoxInfosPaymentCardComponent
          amount={firstCardAmountWithFee}
          installments={firstCardInstallments}
          title="Pagamento 01"
          handleClick={() => handleOpen()}
        />
      )}

      {isCard && (
        <Box mb={4}>
          <Typography variant="subtitle2">
            {`Digite o valor a ser cobrado no primeiro cartão e depois clique em "Adicionar outro cartão"`}
          </Typography>
        </Box>
      )}

      {(isCard || isCardUnique) && (
        <>
          <FormCheckoutPaymentCard
            name="firstCard"
            key={isCardUnique ? 'card-unique' : 'card-one'}
            max={totalSelected}
            disabledAmount={isCardUnique}
            unique={isCardUnique}
          />
        </>
      )}

      {isCardTwo && (
        <FormCheckoutPaymentCard
          name="secondCard"
          key="card-two"
          disabledAmount
        />
      )}
    </Box>
  );
};

export default BoxPaymentByCardComponent;
