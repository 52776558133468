import { Fragment } from 'react';
import { Box, Container, Link } from '@mui/material';
import { Global } from '@emotion/react';
import Alert from '@mui/material/Alert';
import { Helmet } from 'react-helmet';

import useAuth from '@giro/shared-hooks/useAuth.hook';

import ScrollTop from '@giro-app/components/ScrollTop.component';

import Header from '@giro-app/components/Header.component';
import Footer from '@giro-app/components/Footer.component';

const AppLayout = ({ children }) => {
  const currentUser = useAuth();
  const { user } = currentUser?.user || {};

  return (
    <Fragment>
      <Global
        styles={{
          body: {
            overflowX: 'hidden',
            backgroundColor: '#FFFFFF',
          },
        }}
      />

      <Box pt={1.5} component={Header} />
      <Box mt={8} component={Container}>
        {children}
      </Box>
      <Box mt={10} component={Footer} />
    </Fragment>
  );
};

export default AppLayout;
