export const NAME = 'misc';

const CONTEXT = 'CONFIG::MISC';

const RESET = `${CONTEXT}::RESET`;

const UPDATE_REMOVE_LOGIN = `${CONTEXT}::UPDATE::REMOVE_LOGIN`;
const UPDATE_INTEGRATION_ID = `${CONTEXT}::UPDATE::INTEGRATION_ID`;

const UPDATE = {
  REMOVE_LOGIN: UPDATE_REMOVE_LOGIN,
  INTEGRATION_ID: UPDATE_INTEGRATION_ID,
};

export const ACTION_TYPES = {
  UPDATE,
  RESET,
};
