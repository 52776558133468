import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import enumPayment from '@giro-app/constants/payments.constant';
import format from 'date-fns/format';

const handleNormalizeStatus = (status) => {
  const statusObj = {
    REJEITADO: 'Não aprovado',
    PAGO: 'Pago',
    'EM VERIFICAÇÃO': 'Em verificação',
  };

  return statusObj[status] || status;
};

const BoxDetailOrderComponent = ({
  amount,
  status,
  status_description,
  cards,
  created_at,
}) => {
  const colorBg = {
    PAGO: 'success.light3',
    CANCELADO: 'error.light3',
    REJEITADO: 'error.light3',
    PROCESSANDO: 'warning.light3',
    'EM PROCESSAMENTO': 'warning.light3',
  };

  const isPay = status === 'PAGO';

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      bgcolor={colorBg[status]}
      borderRadius={1}
      p={2}
      rowGap={2}
    >
      <Box display="flex" flexDirection="column">
        <Typography color="gray.400" variant="subtitle2">
          Pagamento
        </Typography>
        <Typography color="info.dark2" variant="subtitle1" fontWeight={600}>
          Cartão
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography color="gray.400" variant="subtitle2">
          Total pago
        </Typography>
        <Typography color="info.dark2" variant="subtitle1" fontWeight={600}>
          {Number(amount).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography color="gray.400" variant="subtitle2">
          Status
        </Typography>
        <Typography color="info.dark2" variant="subtitle1" fontWeight={600}>
          {handleNormalizeStatus(status)}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography color="gray.400" variant="subtitle2">
          Data
        </Typography>
        <Typography color="info.dark2" variant="subtitle1" fontWeight={600}>
          {format(new Date(created_at), 'dd/MM/yyyy HH:mm')}
        </Typography>
      </Box>

      {isPay && (
        <Box display="flex" flexDirection="column" gridColumn="span 2">
          {cards.map((c, index) => (
            <Typography fontWeight="bold" key={index} variant="body_sm">
              {c.number}, {c.installments}x de{' '}
              {Number(c.amount / c.installments).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default BoxDetailOrderComponent;
