import qs from 'qs';

import fetch from '@giro/shared-utils/fetch.util';

export default async function getApiCheckoutsService(filters) {
  const filtersStringfy = qs.stringify(filters, {
    skipNulls: true,
  });

  const url = ['checkouts', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    auth: true,
    ms: 'CHECKOUT',
  });
}
