import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { omit } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import CloseIcon from '@mui/icons-material/Close';

import theme from '@giro/client-app/src/constants/theme.constant';

import success from '../../assets/success.png';

import checkout from '../../store';

const DialogPreAuthComponent = ({ handleClose, visible, payload }) => {
  const selectorRedux = {
    checkout: useSelector(checkout.selector.selectState),
  };

  const onFinish = () => {
    Cookies.set(
      process.env.REACT_APP_COOKIE_KEY_RESERVE_ID,
      selectorRedux.checkout.checkout.data.id,
      {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      }
    );

    window.location.href = `${process.env.REACT_APP_ONBOARDING_URL}`;
  };

  return (
    <Dialog onClose={onFinish} open={visible} maxWidth={'xs'}>
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <Box position="absolute" right={8} top={8}>
          <IconButton onClick={() => onFinish()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box height={52} />
          <Box display="flex" flexDirection="column" gap={3}>
            <Box alignSelf="center">
              <img src={success} />
            </Box>
            <Typography
              variant="h3"
              color="black"
              align="center"
              sx={{ textTransform: 'uppercase' }}
            >
              Pré autorização <br />
              efetuada
            </Typography>
            <Typography variant="body2" color="gray.400" align="center">
              Realizamos uma pré <br />
              autorização em seu cartão.
            </Typography>
            <Typography variant="body2" color="gray.400" align="center">
              Conclua o seu cadastro para <br /> finalizar o pagamento.
            </Typography>
            <Typography
              variant="caption"
              color="gray.400"
              fontWeight={800}
              align="center"
            >
              Esta pré-autorização será estornada em sua conta.
            </Typography>
            <Button variant="contained" onClick={() => onFinish()}>
              Concluir cadastro
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogPreAuthComponent;
