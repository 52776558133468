import { all } from 'redux-saga/effects';

import dialogs from './dialogs';
import checkout from './checkout';

function* watch() {
  yield all([dialogs.saga.watch(), checkout.saga.watch()]);
}

export default {
  watch,
};
