import reducer from './dialogCardDetail.reducer';
import * as action from './dialogCardDetail.action';
import * as selector from './dialogCardDetail.selector';
import * as constant from './dialogCardDetail.constant';
import initialState from './dialogCardDetail.initialState';
import subscriber from './dialogCardDetail.subscriber';
import saga from './dialogCardDetail.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
