import { createReducer } from '@reduxjs/toolkit';

import initialState from './apps.initialState';

import { ACTION_TYPES } from './apps.constant';

const handleReset = () => initialState;

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchFinish = (state, action) => {
  state.loading = false;
  state.error = false;
  state.data = action.payload;
};

const handleFetchError = (state, action) => {
  state.loading = false;
  state.error = true;
  state.data = action.payload;
};

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.ALL]: handleReset,
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.FINISH]: handleFetchFinish,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
});
