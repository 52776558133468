export const NAME = 'dialogChangePassword';

const CONTEXT = 'MODULE::PROFILE::DIALOG_CHANGE_PASSWORD';

const RESET = `${CONTEXT}::RESET`;

const OPEN = `${CONTEXT}::OPEN`;
const CLOSE = `${CONTEXT}::CLOSE`;

const CHANGE_VARIANT = `${CONTEXT}::CHANGE::VARIANT`;

const CHANGE = {
  VARIANT: CHANGE_VARIANT,
};

export const ACTION_TYPES = {
  RESET,
  OPEN,
  CLOSE,
  CHANGE,
};
