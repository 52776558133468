import React, { useEffect } from 'react';
import flow from 'lodash/flow';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';

import router from '@giro/shared-store/router';

import useQuery from '@giro/shared-hooks/useQuery.hook';

import ROUTES from '@giro-app/constants/routes.constant';

import bg from '@giro-app/modules/payment/assets/background-md.png';

import useCheckout from '@giro-app/modules/checkout/hooks/useCheckout.hook';

import enumPayment from '../../../constants/payments.constant';

import { CHECKOUT_STEPS } from '../constants/stepsFormCheckout.constant';

import ListDebitsToPay from '../containers/ListDebitsToPay.container';

import CheckoutComponent from '../components/Checkout.component';

import checkout from '../store/checkout';

const PaymentCheckoutScreen = () => {
  useEffect(() => {
    if (process.env.REACT_APP_CLEARSALE_ENABLED !== 'true') {
      return undefined;
    }

    const s = document.createElement('script');
    s.type = 'text/javascript';
    const code = `
      (function (a, b, c, d, e, f, g) {
        a['CsdpObject'] = e; a[e] = a[e] || function () {
        (a[e].q = a[e].q || []).push(arguments)
        }, a[e].l = 1 * new Date(); f = b.createElement(c),
        g = b.getElementsByTagName(c)[0]; f.async = 1; f.src = d; g.parentNode.insertBefore(f, g)
        })(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp');
        csdp('app', '${process.env.REACT_APP_CLEARSALE_APPID}');
        csdp('sessionid', '${sessionId}');
        console.log('CLEARSALE - ENABLED');
    `;

    try {
      s.appendChild(document.createTextNode(code));
      document.body.appendChild(s);
    } catch (e) {
      s.text = code;
      document.body.appendChild(s);
    }

    return () => {
      document.body.removeChild(s);

      const iframes: any = document.querySelectorAll(
        `iframe#csdp_${sessionId}`
      );

      for (let i = 0; i < iframes.length; i++) {
        iframes[i].parentNode.removeChild(iframes[i]);
      }
    };
  }, []);

  const { selected } = useCheckout();

  const dispatch = useDispatch();

  const dispatchRedux = {
    RESET_STATE: flow(checkout.action.resetState, dispatch),
    sessionNew: flow(checkout.action.sessionNew, dispatch),
  };

  const dispatchRouterRedux = {
    push: flow(router.action.push, dispatch),
  };

  const selectorCheckoutRedux = {
    currentStep: useSelector(checkout.selector.selectCurrentStep),
    sessionId: useSelector(checkout.selector.selectSessionId),
    data: useSelector(checkout.selector.selectData),
    app: useSelector(checkout.selector.selectApp),
  };

  const sessionId_prefix =
    selectorCheckoutRedux?.app?.validation_session_prefix || 'giropagamentos';

  const sessionId = `${sessionId_prefix}${selectorCheckoutRedux.sessionId}`;

  useEffect(() => {
    return () => {
      dispatchRedux.RESET_STATE();
      dispatchRedux.sessionNew();
    };
  }, []);

  useEffect(() => {
    if (selected.length === 0) {
      dispatchRouterRedux.push(ROUTES.HOME);
    }
  }, []);

  const { status } = selectorCheckoutRedux?.data || {};

  const messages = {
    [enumPayment.REJEITADO]: 'Pagamento não aprovado',
    [enumPayment.PAGO]: 'Pagamento aprovado!',
    [enumPayment.CANCELADO]: 'Pagamento não aprovado',
    [enumPayment.PROCESSANDO]: 'Pagamento sendo processado',
  };

  return (
    <>
      <Box
        display="flex"
        gap={4}
        minHeight="calc(100vh - 410px)"
        alignItems="start"
        bgcolor="white"
      >
        <CheckoutComponent />
      </Box>
    </>
  );
};

export default PaymentCheckoutScreen;
