import React from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  TextField,
  Divider,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import theme from '@giro/client-app/src/constants/theme.constant';
import ROUTES from '@giro-app/constants/routes.constant';

import success from '../assets/success.png';
import error from '../assets/error.png';

import dialogSendReceipt from '../../checkout/store/dialogs/dialogSendReceipt';

import postApiCheckoutsReferenceIdReceiptsService from '@giro/shared-services/checkout/postApiCheckoutsReferenceIdReceipts.service';

import checkout from '../../checkout/store/checkout';
import toaster from '@giro/shared-store/toaster';

const CheckoutFinishComponent = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [sendEmail, setSendEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const selectorRedux = {
    data: useSelector(checkout.selector.selectData),
  };

  const dispatchRedux = {
    OPEN: flow(dialogSendReceipt.action.open, dispatch),
  };

  const dispatchToasterRedux = {
    show: flow(toaster.action.show, dispatch),
  };

  const handleGetRecipt = () =>
    window.open(`${ROUTES.RECEIPTS.ROOT}?ref=${reference_id}`);

  const handleSendEmail = async () => {
    const { reference_id } = selectorRedux?.data || {};

    setLoading(true);

    const [success, result] = await postApiCheckoutsReferenceIdReceiptsService(
      reference_id,
      sendEmail
    );

    setLoading(false);

    if (success) {
      dispatchToasterRedux.show({
        message: 'Email enviado com sucesso!',
        variant: 'success',
      });
    }
  };

  const { reference_id } = selectorRedux?.data || {};

  const { status } = selectorRedux.data || {};

  const processCyberSource = (
    <Box display="flex" flexDirection="column" gap={1.5}>
      <Typography
        variant="h3"
        fontWeight={700}
        color="info.dark2"
        align="center"
      >
        Seu pagamento está em processamento
      </Typography>
      <Typography
        align="center"
        variant="body2"
        fontWeight={400}
        color="gray.400"
      >
        Assim que concluído, você receberá um e-mail ou poderá ver o status no
        histórico do seu perfil
      </Typography>
      <Box alignSelf="center">
        <Button
          variant="contained"
          onClick={() => history.push(ROUTES.PROFILE.ROOT)}
        >
          Ver meus pedidos
        </Button>
      </Box>
    </Box>
  );

  const rejectVariant = (
    <Box display="flex" gap={1} flexDirection="column">
      <Box alignSelf="center">
        <img src={error} />
      </Box>

      <Typography
        variant="h3"
        fontWeight={700}
        color="info.dark2"
        align="center"
      >
        Pagamento não aprovado
      </Typography>
    </Box>
  );

  const payVariant = (
    <Box display="flex" flexDirection="column" gap={3} width="100%">
      <Box
        p={7}
        display="grid"
        gridTemplateColumns={{
          xs: '1fr',
          md: '1fr 1fr',
        }}
        bgcolor="trasso_site.base"
        gap={5}
        borderRadius="6px"
      >
        <Typography
          align="center"
          variant="trasso_site_h2"
          color="trasso_site.roxo2"
          sx={{
            gridColumn: {
              xs: 'span',
              md: 'span 2',
            },
          }}
        >
          Pagamento realizado com sucesso!
        </Typography>

        <Box
          p={4}
          bgcolor="white"
          borderRadius="30px"
          display="flex"
          justifyContent="center"
        >
          <img src="/assets/success_payment.png" style={{ maxWidth: '100%' }} />
        </Box>

        <Box display="flex" flexDirection="column" gap={4}>
          <Typography variant="trasso_site_h3" color="trasso_site.roxo">
            Quer compartilhar o comprovante de pagamento ou recebê-lo por
            e-mail?
          </Typography>
          <Box
            display="flex"
            gap={3}
            alignItems="end"
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box
              display="flex"
              gap={1}
              flexDirection="column"
              flex={1}
              width="100%"
            >
              <Typography
                variant="trasso_site_caption"
                color="trasso_site.rosa"
              >
                Digite seu email abaixo
              </Typography>
              <TextField
                fullWidth
                onChange={({ target: { value } }) => setSendEmail(value)}
              />
            </Box>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSendEmail}
              loading={loading}
              sx={{
                height: '59px',
                borderRadius: '15px',
                fontSize: '16px',
                width: { xs: '100%', md: 'auto' },
              }}
            >
              Receber comprovante
            </LoadingButton>
          </Box>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: '1fr',
          md: '1fr',
        }}
        alignItems="start"
        gap={3}
      >
        {/* <Box
          borderRadius="6px"
          bgcolor="trasso_site.base"
          p={4}
          display="flex"
          flexDirection="column"
          gap={4}
        >
          <Typography variant="trasso_site_h3" color="trasso_site.roxo">
            Informações de Pagamento
          </Typography>
          <Divider />
          <Box display="flex" flexDirection="column" gap={3}>
            <Box display="flex" gap={1} flexDirection="column">
              <Typography
                variant="trasso_site_caption"
                color="trasso_site.rosa"
              >
                parcelas
              </Typography>
              <TextField
                inputProps={{ readOnly: true }}
                value="10x de R$ 33,29"
              />
            </Box>
            <Box display="flex" gap={1} flexDirection="column">
              <Typography
                variant="trasso_site_caption"
                color="trasso_site.rosa"
              >
                forma de pagamento
              </Typography>
              <TextField inputProps={{ readOnly: true }} value="Crédito" />
            </Box>
            <Box display="flex" gap={1} flexDirection="column">
              <Typography
                variant="trasso_site_caption"
                color="trasso_site.rosa"
              >
                código de autorização
              </Typography>
              <TextField
                inputProps={{ readOnly: true }}
                value="0001724434485"
              />
            </Box>
          </Box>
        </Box> */}
        <Box
          border="1px solid"
          borderColor="trasso_site.rosa"
          display="grid"
          gridTemplateColumns="1fr 1fr"
          p={4}
          borderRadius="6px"
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="trasso_site_h2" color="trasso_site.roxo2">
              IPVA 2023
            </Typography>
            <Typography variant="trasso_site_h3" color="trasso_site.roxo2">
              Parcele em{' '}
              <Box component="strong" color="trasso_site.rosa">
                até 18x
              </Box>
            </Typography>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'trasso_site.rosa',
                height: 59,
                borderRadius: '15px',
                fontSize: '20px',
                width: 180,
              }}
            >
              Pague agora
            </Button>
            <Typography
              fontSize="14px"
              color="trasso_site.roxo2"
              fontWeight={400}
              lineHeight="21px"
              mt={5}
            >
              Ou acesse giropagamentos.com.br para saber mais!
            </Typography>
          </Box>
          <Box display="block" mb={-4}>
            <img
              src="/assets/celular_giro.png"
              style={{ display: 'block', width: '100%' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const payVariant2 = (
    <Box display="flex" gap={1} flexDirection="column">
      <Box alignSelf="center">
        <img src={success} />
      </Box>

      <Typography
        variant="h3"
        fontWeight={700}
        color="info.dark2"
        align="center"
      >
        Pagamento realizado
      </Typography>
      <Typography
        align="center"
        variant="body2"
        fontWeight={400}
        color="gray.400"
      >
        Seu pagamento foi aprovado com sucesso.
      </Typography>

      <Box display="flex" gap={2} mt={1} width="100%">
        <Button
          variant="outlined"
          size="small"
          fullWidth
          color="info"
          onClick={handleGetRecipt}
          sx={{
            ...theme.typography.body_sm,
            fontWeight: 500,
          }}
        >
          <CloudDownloadIcon css={{ width: 18, height: 18, marginRight: 10 }} />
          Baixar recibo
        </Button>
        <Button
          variant="contained"
          size="small"
          fullWidth
          color="info"
          onClick={() => dispatchRedux.OPEN({})}
          sx={{
            ...theme.typography.body_sm,
            fontWeight: 500,
          }}
        >
          <MailOutlineIcon css={{ width: 18, height: 18, marginRight: 10 }} />
          Enviar por e-mail
        </Button>
      </Box>
    </Box>
  );

  const processVariant = (
    <Box display="flex" gap={1} flexDirection="column">
      <Box
        width={121}
        height={121}
        alignSelf="center"
        display="flex"
        justifyContent="center"
      >
        <CircularProgress style={{ width: 80, height: 80 }} />
      </Box>
      <Typography
        variant="h3"
        fontWeight={700}
        color="info.dark2"
        align="center"
      >
        Aguarde
      </Typography>
      <Typography
        align="center"
        variant="body2"
        fontWeight={400}
        color="gray.400"
      >
        Seu pagamento está sendo processado
      </Typography>
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {status === 'PAGO' && payVariant}
      {status === 'PROCESSANDO' && processVariant}
      {status === 'EM PROCESSAMENTO' && processCyberSource}
      {(status === 'REJEITADO' || status === 'CANCELADO') && rejectVariant}
    </Box>
  );
};

export default CheckoutFinishComponent;
