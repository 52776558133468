import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import TagManager from 'react-gtm-module';

import getHistory from '@giro/shared-utils/getHistory.util';

import Routes from '@giro-app/routes';

import themeConstants from '@giro/client-app/src/constants/theme.constant';

import { useStore } from '@giro-app/store';

import reportWebVitals from './reportWebVitals';

import './index.css';

const tagManagerArgs = {
  gtmId: 'GTM-TWVNFB5',
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  const store = useStore();
  const theme = createTheme({ ...(themeConstants as any) });

  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <SnackbarProvider>
            <Router history={getHistory()}>
              <Routes />
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
