import React from 'react';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormikContext, Field } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Button, Dialog } from '@mui/material';
import { ptBR } from 'date-fns/locale';
import * as yup from 'yup';
import flow from 'lodash/flow';
import isEqual from 'lodash/isEqual';
import { TextField as TextFieldMUI } from 'formik-mui';
import { v4 } from 'uuid';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import { useMediaQuery, useTheme } from '@mui/material';

import theme from '@giro/client-app/src/constants/theme.constant';
import drawerOrderSearch from '../../store/drawerOrderSearch';

import history from '../../store/history';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const FormSearchTransactions = ({ handleResetFilters }) => {
  const themeMui = useTheme();
  const isMobile = useMediaQuery(themeMui.breakpoints.down('md'));
  const [showModalRange, setModalRange] = React.useState(false);
  const { submitForm, errors, setFieldValue, values }: any = useFormikContext();
  const [key, setKey] = React.useState(v4());

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Box display="grid" gridTemplateRows="1fr 1fr" gap={4} key={key}>
          <Field
            component={TextFieldMUI}
            name="startDate"
            label="Data inicial"
            onClick={() => setModalRange(true)}
            inputProps={{
              readOnly: true,
            }}
            value={
              values?.startDate &&
              format(new Date(values?.startDate), 'dd/MM/yyyy')
            }
          />
          <Field
            component={TextFieldMUI}
            name="endDate"
            label="Data final"
            onClick={() => setModalRange(true)}
            inputProps={{
              readOnly: true,
            }}
            value={
              values?.endDate && format(new Date(values?.endDate), 'dd/MM/yyyy')
            }
          />
        </Box>
        <Box display="grid" gridTemplateColumns="1fr" gap={4}>
          <Field
            component={TextFieldMUI}
            name="description"
            label="Descrição"
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box alignSelf="flex-end" gap={2} display="flex" flexDirection="row">
          <Button onClick={handleResetFilters}>Limpar filtros</Button>
          <Button
            variant="contained"
            onClick={submitForm}
            disabled={Object.keys(errors).length > 0}
          >
            Pesquisar
          </Button>
        </Box>
      </Box>

      <Dialog
        open={showModalRange}
        onClose={() => setModalRange(false)}
        css={css(`
          .rdrInRange, .rdrStartEdge, .rdrEndEdge {
            background: ${theme.palette.primary.main};
          }

          .rdrDayStartPreview, .rdrDayEndPreview, .rdrDayInPreview {
            border-color: ${theme.palette.primary.main}
          }

          .rdrDayToday .rdrDayNumber span:after {
            background: ${theme.palette.primary.main};
          }
        `)}
      >
        <DateRange
          weekdayDisplayFormat="EEEEEE"
          monthDisplayFormat="MMMM yyyy"
          locale={ptBR}
          showDateDisplay={false}
          months={isMobile ? 1 : 2}
          direction="horizontal"
          ranges={[
            {
              startDate: values.startDate || new Date(),
              endDate: values.endDate || new Date(),
              key: 'selection',
            },
          ]}
          onChange={({ selection }) => {
            setFieldValue('startDate', selection.startDate);
            setFieldValue('endDate', selection.endDate);

            setKey(v4());
          }}
        />
      </Dialog>
    </LocalizationProvider>
  );
};

const FormSearchTransactionsComponent = () => {
  const [key, setKey] = React.useState(v4());

  const validationSchema = yup.object({
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable(),
    date: yup.mixed(),
    description: yup.string().nullable().notRequired(),
  });

  const dispatch = useDispatch();

  const dispatchRedux = {
    updateFilters: flow(history.action.updateFilters, dispatch),
    resetFilters: flow(history.action.resetFilters, dispatch),
    CLOSE: flow(drawerOrderSearch.action.close, dispatch),
  };

  const selectorRedux = {
    filters: useSelector(history.selector.selectFilters),
  };

  const handleClose = async (values) => {
    dispatchRedux.updateFilters(values);
    dispatchRedux.CLOSE();
    return true;
  };

  const handleResetFilters = () => {
    dispatchRedux.resetFilters();

    if (!isEqual(selectorRedux.filters, history.initialState.filters)) {
      dispatchRedux.updateFilters({});
    }

    setKey(v4());
  };

  return (
    <Formik
      key={key}
      initialValues={{ ...selectorRedux.filters }}
      enableReinitialize
      onSubmit={handleClose}
      validationSchema={validationSchema}
      validateOnMount
    >
      <FormSearchTransactions handleResetFilters={handleResetFilters} />
    </Formik>
  );
};

export default FormSearchTransactionsComponent;
