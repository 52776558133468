const NAMESPACE = '/';
const NAMESPACE_PROFILE = '/profile';
const NAMESPACE_ORDERS = '/orders';
const NAMESPACE_DEBTS = '/debts';
const NAMESPACE_PAYMENT = '/payment';
const NAMESPACE_RECEIPTS = '/receipts';
const NAMESPACE_CHECKOUT = '/checkout';

const CHECKOUT_ROUTES = {
  ROOT: NAMESPACE_CHECKOUT,
};

const DEBTS_ROUTES = {
  ROOT: NAMESPACE_DEBTS,
  BILLET: `${NAMESPACE_DEBTS}/billet`,
  REFERENCE: `${NAMESPACE_DEBTS}/reference`,
  SELECTOR: `${NAMESPACE_DEBTS}/selector`,
  SELECTOR_BILLET: `${NAMESPACE_DEBTS}/selector-billet`,
  DETRAN: `${NAMESPACE_DEBTS}/detran`,
  CREA_SP: `${NAMESPACE_DEBTS}/creasp/:id?`,
  PARTNER: `${NAMESPACE_DEBTS}/:partner/:barcode?`,
};

const RECEIPTS_ROUTES = {
  ROOT: NAMESPACE_RECEIPTS,
};

const ORDERS_ROUTES = {
  ROOT: NAMESPACE_ORDERS,
};

const PROFILE_ROUTES = {
  ROOT: NAMESPACE_PROFILE,
};

const PAYMENT_ROUTES = {
  ROOT: NAMESPACE_PAYMENT,
};

const EXTERNAL_AUTH_ROUTES = {
  LOGIN: `${process.env.REACT_APP_AUTH_URL}/login`,
  CREATE_ACCOUNT: `${process.env.REACT_APP_AUTH_URL}/create-account`,
};

const EXTERNAL_ROUTES = {
  AUTH: EXTERNAL_AUTH_ROUTES,
};

const ROUTES = {
  HOME: NAMESPACE,
  PAYMENT: PAYMENT_ROUTES,
  PROFILE: PROFILE_ROUTES,
  ORDERS: ORDERS_ROUTES,
  RECEIPTS: RECEIPTS_ROUTES,
  EXTERNAL: EXTERNAL_ROUTES,
  DEBTS: DEBTS_ROUTES,
  CHECKOUT: CHECKOUT_ROUTES,
};

export const NAMESPACES = {
  ROOT: NAMESPACE,
  PROFILE: NAMESPACE_PROFILE,
  ORDERS: NAMESPACE_ORDERS,
  PAYMENT: NAMESPACE_PAYMENT,
  RECEIPTS: NAMESPACE_RECEIPTS,
  DEBTS: NAMESPACE_DEBTS,
  CHECKOUT: NAMESPACE_CHECKOUT,
};

export default ROUTES;
