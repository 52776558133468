import Box from '@mui/material/Box';
import { Button, TextField, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import useDialog from '@giro-app/hooks/useDialogRedux.hook';

import theme from '@giro/client-app/src/constants/theme.constant';

import dialogChangePassword from '../../store/dialogChangePassword';

import FormUpdatePersonalData from '../Form/FormUpdatePersonalData.component';

const BoxPersonalDataComponent = () => {
  return (
    <Box>
      <Box display="grid" gridTemplateColumns="4fr 1fr">
        <Typography variant="trasso_site_h3" color="trasso_site.roxo2">
          Minha Conta
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          alignSelf="top"
          gap={1}
        >
          <AccountCircleIcon
            sx={{ width: 91, height: 91, color: 'trasso_site.roxo' }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={3} bgcolor="white">
        <FormUpdatePersonalData />
      </Box>
    </Box>
  );
};

export default BoxPersonalDataComponent;
