import { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import useQuery from '@giro/shared-hooks/useQuery.hook';

import router from '@giro/shared-store/router';

import ROUTES from '@giro-app/constants/routes.constant';

import list from '../store/list';

const DetranDebitsScreen = () => {
  const query = useQuery();

  const dispatch = useDispatch();

  const dispatchListDebts = {
    push: flow(router.action.push, dispatch),
  };

  useEffect(() => {
    const modeParam = query.get('mode');

    if (modeParam === 'billet') {
      dispatchListDebts.push(ROUTES.DEBTS.BILLET);
    }

    if (modeParam === 'detran' || modeParam == null) {
      dispatchListDebts.push(ROUTES.DEBTS.DETRAN);
    }
  }, []);

  return <Fragment />;
};

export default DetranDebitsScreen;
