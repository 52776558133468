import reducer from './dialogErrorPayment.reducer';
import * as action from './dialogErrorPayment.action';
import * as selector from './dialogErrorPayment.selector';
import * as constant from './dialogErrorPayment.constant';
import initialState from './dialogErrorPayment.initialState';
import subscriber from './dialogErrorPayment.subscriber';
import saga from './dialogErrorPayment.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
