import React from 'react';
import { TextField, Box, Typography } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { Formik, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import qs from 'qs';
import NumberFormat from 'react-number-format';
import flow from 'lodash/flow';
import { useSelector, useDispatch } from 'react-redux';
import { validarBoleto } from '@mrmgomes/boleto-utils';
import { useParams } from 'react-router-dom';

import ROUTES from '@giro-app/constants/routes.constant';

import router from '@giro/shared-store/router';

import useQuery from '@giro/shared-hooks/useQuery.hook';

import useListDebitsHook from '../../hooks/useListDebits.hook';

import messages from '@giro/shared-constants/messagesSchema.constant';

import apps from '@giro-app/store/apps';

import debits from '../../store/list';

const validationSchema = Yup.object({
  barcode: Yup.string()
    .required(messages.REQUIRED)
    .test('valida-boleto', 'Código de boleto inválido', (value: any) => {
      const isValidBillet = validarBoleto(value || '');

      return isValidBillet.sucesso;
    }),
});

const FormSearchDebtBilletComponent = () => {
  const params: any = useParams();
  const query = useQuery();

  const { loading } = useListDebitsHook();

  const [isTribute, setIsTribute] = React.useState(false);

  const { values, submitForm, errors, validateForm, setValues }: any =
    useFormikContext();

  const maskConveniado =
    '#####.##### #####.###### #####.###### # ###############';
  const maskTribute = '###########-# ###########-# ###########-# ###########-#';

  React.useEffect(() => {
    const firstDigit = values?.barcode?.[0];

    if (firstDigit === '8') {
      setIsTribute(true);
    } else {
      setIsTribute(false);
    }
  }, [values?.barcode]);

  React.useEffect(() => {
    const newValues: any = { ...values };

    if (Object.prototype.hasOwnProperty.call(newValues, 'barcode')) {
      newValues.barcode = query.get('barcode') || params?.barcode || undefined;
    }

    const firstDigit = newValues.barcode?.[0];

    if (firstDigit === '8') {
      setIsTribute(true);
    }

    setValues(newValues);

    setTimeout(() => validateForm());
  }, []);

  return (
    <Box display="grid" gridTemplateColumns="1fr" gap={3}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="trasso_site_caption" color="trasso_site.rosa">
          Código de barras
        </Typography>
        <Field name="barcode">
          {({
            field,
            form: { setFieldValue, setFieldTouched },
            meta: { error, touched },
          }) => (
            <NumberFormat
              customInput={TextField}
              multiline
              fullWidth
              placeholder="Código de barras"
              format={isTribute ? maskTribute : maskConveniado}
              value={field.value}
              error={touched && !!error}
              helperText={touched && !!error && error}
              sx={{
                '.MuiOutlinedInput-root': {
                  height: 'auto',
                },
              }}
              onBlur={() => {
                setFieldTouched(field.name, true);
              }}
              onValueChange={(values: any) =>
                setFieldValue(field.name, values.value)
              }
            />
          )}
        </Field>
      </Box>

      <Button
        color="secondary"
        variant="contained"
        sx={{
          height: 48,
          fontSize: 'body1.fontSize',
          fontWeight: 600,
          color: 'white',
        }}
        onClick={submitForm}
        disabled={Object.keys(errors).length > 0 || loading}
        loading={loading}
      >
        Consultar
      </Button>
    </Box>
  );
};

const FormSearchDebtBilletFormik = ({
  integrationId,
}: {
  integrationId?: string;
  [key: string]: any;
}) => {
  const { handleUpdateFilters } = useListDebitsHook();

  const dispatch = useDispatch();

  const dispatchDebitsRedux = {
    UPDATE_FILTERS: flow(debits.action.updateFilters, dispatch),
  };

  const dispatchRouterRedux = {
    push: flow(router.action.push, dispatch),
  };

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
  };

  const handleSubmit = async (values) => {
    const data = selectorAppsRedux.data;

    const integration_id =
      integrationId || data.find((d) => d.filter_type === 3).id;

    handleUpdateFilters({
      ...debits.initialState.filters,
      barcode: values?.barcode,
      integration_id: integration_id,
    });

    const valuesQs = qs.stringify({ ...values, integration_id });

    const path = [ROUTES.DEBTS.BILLET, valuesQs].join('?');

    dispatchRouterRedux.push(path);

    return true;
  };

  return (
    <Formik
      validateOnMount
      initialValues={{ barcode: '' }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <FormSearchDebtBilletComponent />
    </Formik>
  );
};

export default FormSearchDebtBilletFormik;
