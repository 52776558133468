import payment from '@giro-app/modules/payment/store';
import profile from '@giro-app/modules/profile/store';
import debts from '@giro-app/modules/debts/store';
import checkout from '@giro-app/modules/checkout/store';

const subscribe = (store) => {
  payment.subscriber.subscribe(store);
  profile.subscriber.subscribe(store);
  debts.subscriber.subscribe(store);
  checkout.subscriber.subscribe(store);
};

export default {
  subscribe,
};
