import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import DialogChangePasswordComponent from '../components/Dialog/DialogChangePassword.component';

import dialogChangePassword from '../store/dialogChangePassword';

import { dialogsPropsType } from '@giro-app/hooks/useDialogRedux.hook';

import postApiAccountsChangepasswordService from '@giro/shared-services/users/postApiAccountsChangepassword.service';

const DialogChangePasswordContainer = ({
  handleClose,
  visible,
}: dialogsPropsType) => {
  const auth = useAuthHook();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const selectorRedux = {
    VARIANT: useSelector(dialogChangePassword.selector.selectVariant),
  };

  const dispatchRedux = {
    CHANGE_VARIANT: flow(dialogChangePassword.action.changeVariant, dispatch),
  };

  const handleUpdatePassword = async ({ password, confirmPassword }) => {
    setLoading(true);

    const [success, result] = await postApiAccountsChangepasswordService({
      password: password,
      confirmPassword: confirmPassword,
      token: auth.token,
    });

    setLoading(false);

    if (success) {
      dispatchRedux.CHANGE_VARIANT('success');
    }

    return true;
  };

  return (
    <DialogChangePasswordComponent
      handleClose={handleClose}
      visible={visible}
      variant={selectorRedux.VARIANT}
      handleUpdatePassword={handleUpdatePassword}
      loading={loading}
    />
  );
};

export default DialogChangePasswordContainer;
