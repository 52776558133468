import reducer from './checkout.reducer';
import * as selector from './checkout.selector';
import * as constant from './checkout.constant';
import subscriber from './checkout.subscriber';
import saga from './checkout.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
