import React from 'react';

import DebtBilletComponent from '../components/DebtBillet.component';

const DebitsBilletScreen = () => {
  return (
    <>
      <DebtBilletComponent />
    </>
  );
};

export default DebitsBilletScreen;
