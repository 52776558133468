import React from 'react';
import Cookies from 'js-cookie';
import {
  Box,
  CircularProgress,
  Typography,
  Paper,
  Button,
  Backdrop,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import ROUTES from '@giro-app/constants/routes.constant';

import postApiInstallmentsService from '@giro/shared-services/checkout/postApiInstallments.service';

import apps from '@giro-app/store/apps';
import checkout from '../../checkout/store/checkout';
import router from '@giro/shared-store/router';
import list from '../store/list';

import useListDebits from '../hooks/useListDebits.hook';
import useProtectedByAuth from '@giro-app/hooks/useProtectedByAuth.hook';

const getTaxFormatted = (value) =>
  Number(value).toLocaleString('pt-br', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

const getCurencyFormatted = (value) =>
  Number(value).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

const Layout = ({
  installments,
  results,
  idParams,
  onPay,
  hasError,
  metadata,
}) => {
  const lastInstallment = [...installments].pop();

  const result = results?.[0] || {};

  const isPay = result?.status === 'Pago';
  const isPending = result?.status === 'Aguardando Pagamento';

  return (
    <Box
      display="flex"
      minHeight="calc(100vh - 412px)"
      flexDirection="column"
      gap={4}
    >
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography fontWeight={600} fontSize="26px">
          Confira os dados abaixo
        </Typography>
        <Typography fontWeight={400} fontSize="16px">
          Antes de continuar o pagamento, tenha a certeza de que as informações
          abaixo estão de acordo com os serviços contratados.
        </Typography>
      </Box>

      <Paper elevation={8} variant="outlined">
        <Box display="flex" gap={1} alignItems="center" p={4}>
          {!hasError && (
            <Box display="flex" flexDirection="column" gap={1} width="100%">
              <Typography>
                <strong>Nome:</strong> {result?.owner}
              </Typography>
              <Typography>
                <strong>Número do Registro:</strong> {result?.register}
              </Typography>
              <Typography>
                <strong>Documento:</strong> {metadata?.meta?.data?.nroCarrinho}
              </Typography>
              <Typography>
                <strong>Valor do Pagamento:</strong>{' '}
                {getCurencyFormatted(result?.amount || 0)}
              </Typography>
            </Box>
          )}

          {hasError && (
            <Box flex={1}>
              <Typography> Não foi possível carregar os dados</Typography>
            </Box>
          )}

          <Box>
            <img src="/crea.jpg" style={{ display: 'block' }} width={200} />
          </Box>
        </Box>
      </Paper>

      {!hasError && isPay && (
        <Paper sx={{ bgcolor: 'secondary.main' }}>
          <Box p={4} py={2}>
            <Typography color="white">Esta cobrança já foi paga.</Typography>
          </Box>
        </Paper>
      )}

      {!hasError && isPending && (
        <Paper sx={{ bgcolor: 'secondary.main' }}>
          <Box p={4} py={2}>
            <Typography color="white">
              Pague em até {lastInstallment?.installments}x de{' '}
              <strong>
                {getCurencyFormatted(lastInstallment?.amount || 0)}
              </strong>
            </Typography>
          </Box>
        </Paper>
      )}

      {!hasError && isPending && (
        <Box>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={onPay}
            disabled={hasError}
          >
            Pagar
          </Button>
        </Box>
      )}
    </Box>
  );
};

const DebtCREAComponent = () => {
  useProtectedByAuth();

  const dispatch = useDispatch();

  const { id: idParams }: any = useParams();

  const [loadingInstallment, setLoadingInstallment] = React.useState(false);
  const [installments, setInstallments] = React.useState([]);

  const selectRedux = {
    apps: useSelector(apps.selector.selectData),
    loading: useSelector(apps.selector.selectLoading),
  };

  const dispatchCheckoutRedux = {
    updateDebit: flow(checkout.action.updateDebit, dispatch),
    updateApp: flow(checkout.action.updateApp, dispatch),
  };

  const dispatchRouterRedux = {
    push: flow(router.action.push, dispatch),
  };

  const dispatchRedux = {
    servicePost: flow(list.action.servicePostTokenPartner, dispatch),
  };

  const {
    handleUpdateFilters,
    results,
    loading,
    selected,
    filters,
    metadata,
    error,
    installments: installmentsDebts,
  } = useListDebits();

  const handleGetInstallments = async (value) => {
    setLoadingInstallment(true);

    try {
      const [success, result] = await postApiInstallmentsService({
        integration_id: '313b1ffe-56f9-4290-94d8-388f36684105',
        amount: value,
        type: 'CARTAO',
      });

      if (success) {
        const resultParsed = result?.map((r) => ({
          ...r,
          tax_percent: getTaxFormatted((r.amount_total - value) / value),
        }));

        setInstallments(resultParsed);
      }
    } catch {
      return false;
    }

    setLoadingInstallment(false);
  };

  const handlePay = () => {
    if (filters?.integration_id) {
      Cookies.set(
        process.env.REACT_APP_COOKIE_KEY_INTEGRATION_ID,
        filters?.integration_id,
        {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        }
      );
    }

    dispatchCheckoutRedux.updateDebit({
      integration_id: filters?.integration_id,
      reference_id: metadata?.reference_id,
      installment: installmentsDebts,
      debits: selected,
    });

    return dispatchRouterRedux.push(ROUTES.CHECKOUT.ROOT);
  };

  React.useEffect(() => {
    if (loading === false && results?.[0]?.amount) {
      handleGetInstallments(results?.[0]?.amount);
    }
  }, [loading, results]);

  React.useEffect(() => {
    handleUpdateFilters({
      document_number: idParams,
      integration_id: '313b1ffe-56f9-4290-94d8-388f36684105',
    });
  }, []);

  React.useEffect(() => {
    if (filters?.integration_id) {
      dispatchRedux.servicePost();
    }
  }, [filters]);

  const isLoading = [true, undefined, null].includes(selectRedux.loading);
  const isLoadingInstallment = [true, undefined, null].includes(
    loadingInstallment
  );
  const isLoadingDebts = [true, undefined, null].includes(loading);

  return (
    <>
      <Layout
        idParams={idParams}
        installments={installments}
        results={results}
        onPay={handlePay}
        hasError={error?.[0]}
        metadata={metadata}
      ></Layout>

      <Backdrop open={isLoading || isLoadingInstallment || isLoadingDebts}>
        <CircularProgress sx={{ color: 'white' }} />
      </Backdrop>
    </>
  );
};

export default DebtCREAComponent;
