import { Button } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import flow from 'lodash/flow';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EmailIcon from '@mui/icons-material/Email';

import ROUTES from '@giro-app/constants/routes.constant';

import useListHook from '@giro-app/hooks/useListRedux.hook';

import payment from '../store/payment';

import dialogSendReceipt from '../store/dialogSendReceipt';

const ActionsOrderComponent = () => {
  const dispatch = useDispatch();

  const { data } = useListHook(payment);

  const { reference_id } = data || {};

  const handleGetRecipt = () =>
    window.open(`${ROUTES.RECEIPTS.ROOT}?ref=${reference_id}`);

  const dispatchReduxModal = {
    OPEN: flow(dialogSendReceipt.action.open, dispatch),
  };

  return (
    <Box display="flex" gap={2} flexDirection="column">
      <Button
        fullWidth
        variant="outlined"
        sx={{
          borderColor: 'info.main',
          color: 'info.main',
        }}
        onClick={handleGetRecipt}
      >
        <Box component={CloudDownloadIcon} mr={1} /> Baixar recibo
      </Button>
      <Button
        fullWidth
        variant="contained"
        onClick={() => dispatchReduxModal.OPEN({})}
        sx={{
          backgroundColor: 'info.main',
        }}
      >
        <Box component={EmailIcon} mr={1} /> Enviar por e-mail
      </Button>
    </Box>
  );
};

export default ActionsOrderComponent;
