import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'lodash';

import router from '@giro/shared-store/router';

import Logo from '@giro/shared-components/Logo.component';

import ROUTES from '@giro-app/constants/routes.constant';

import NavHeaderComponent from './NavHeader.component';

const HeaderComponent = (props) => {
  const dispatch = useDispatch();

  const dispatchRouterRedux = {
    push: flow(router.action.push, dispatch),
  };

  const selectorRouterRedux = {
    pathname: useSelector(router.selector.selectPathname),
  };

  const routesWhiteMode = [ROUTES.PAYMENT.ROOT];

  const isWhiteMode = routesWhiteMode.includes(selectorRouterRedux?.pathname);

  return (
    <Container component="header" {...props}>
      <Box
        display="grid"
        justifyContent="space-between"
        gridTemplateColumns="auto auto"
        alignItems="center"
      >
        <Box
          onClick={() => dispatchRouterRedux.push(ROUTES.HOME)}
          sx={{ cursor: 'pointer' }}
          minHeight={{
            md: 64,
          }}
        >
          <Logo width="200" variant={isWhiteMode ? 'new-white' : 'new'} />
        </Box>

        <NavHeaderComponent white={isWhiteMode} />
      </Box>

      <Box component={Divider} mt={4} />
    </Container>
  );
};

export default HeaderComponent;
