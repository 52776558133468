import reducer from './payment.reducer';
import * as selector from './payment.selector';
import * as constant from './payment.constant';
import subscriber from './payment.subscriber';
import saga from './payment.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
