import { flow } from 'lodash';
import { NAME } from './user.constant';

import { selectProfileState } from '../profile.selector';

const selectState = (state) => state[NAME];
export const selectHistoryState = flow(selectProfileState, selectState);

const selectLoadingKey = (state) => state.loading;
const selectErrorKey = (state) => state.error;
const selectDataKey = (state) => state.data;

export const selectError = flow(selectHistoryState, selectErrorKey);
export const selectLoading = flow(selectHistoryState, selectLoadingKey);
export const selectData = flow(selectHistoryState, selectDataKey);
