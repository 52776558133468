import { combineReducers } from 'redux';

import drawers from './drawers';
import misc from './misc';
import dialogs from './dialogs';

const reducer = combineReducers({
  [dialogs.name]: dialogs.reducer,
  [misc.name]: misc.reducer,
  [drawers.name]: drawers.reducer,
});

export default reducer;
