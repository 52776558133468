import React from 'react';

import useFormCheckout from '../hooks/useCheckout.hook';

import FormCheckoutAntiFraudAddressComponent from '../components/Form/FormCheckout/FormCheckoutAntiFraudAddress.component';
import FormCheckoutPaymentCardComponent from '../components/Form/FormCheckout/FormCheckoutPayment.component';

type Props = {
  variant: 'antiFarudAddress' | 'payment';
  [key: string]: any;
};

const components = {
  antiFarudAddress: FormCheckoutAntiFraudAddressComponent,
  payment: FormCheckoutPaymentCardComponent,
};

const FormCheckoutFactory = ({ variant, ...restProps }: Props) => {
  const formCheckoutProps = useFormCheckout();

  const props = {
    ...restProps,
    ...formCheckoutProps,
  };

  return React.createElement(components[variant], props);
};

export default FormCheckoutFactory;
