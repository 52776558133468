import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import useStep from './useStepCheckout.hook';

import checkout from '../store/checkout';

import { cardSchema } from '../store/checkout/checkout.initialState';

import { PAYMENT_TYPE } from '@giro-app/modules/checkout/constants/paymentType.constant';

const useCheckoutHook = () => {
  const { handleNext, handleBack } = useStep();

  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_FORMDATA: flow(checkout.action.updateFormdata, dispatch),
    UPDATE_PAYMENTTYPE: flow(checkout.action.updatePaymenttype, dispatch),
    UPDATE_PHONE: flow(checkout.action.updatePhone, dispatch),
    FETCH_START: flow(checkout.action.fetchStart, dispatch),
    FETCH_START_ZIPCODE: flow(checkout.action.fetchStartZipcode, dispatch),
    updateCurrentstep: flow(checkout.action.updateCurrentstep, dispatch),
    confirmAccount: flow(checkout.action.confirmAccount, dispatch),
    confirmTransaction: flow(checkout.action.confirmTransaction, dispatch),
    updateVerifystep: flow(checkout.action.updateVerifystep, dispatch),
  };

  const selectorRedux = {
    formData: useSelector(checkout.selector.selectFormData),
    paymentType: useSelector(checkout.selector.selectPaymentType),
    verifyStep: useSelector(checkout.selector.selectVerifyStep),
    selected: useSelector(checkout.selector.selectDebit)?.debits || [],
    loading: useSelector(checkout.selector.selectLoading),
  };

  const handleResetCard = () => {
    dispatchRedux.UPDATE_PAYMENTTYPE(PAYMENT_TYPE.CARD_ONE);

    const newValues = {
      ...selectorRedux.formData,
      payment: {
        firstCard: cardSchema,
        secondCard: cardSchema,
      },
    };

    dispatchRedux.UPDATE_FORMDATA(newValues);
  };

  const handleUpdateCard = (values) => {
    const newValues = {
      ...selectorRedux.formData,
      payment: {
        ...selectorRedux.formData.payment,
      },
    };

    if (values?.firstCard) {
      newValues.payment.firstCard = values.firstCard;
    }

    if (values?.address) {
      newValues.payment.address = values.address;
    }

    if (values?.secondCard) {
      newValues.payment.secondCard = values.secondCard;
    }

    if (values?.description) {
      newValues.payment.description = values.description;
    }

    dispatchRedux.UPDATE_FORMDATA(newValues);
  };

  const handleUpdateAddress = (values) => {
    const newValues = {
      ...selectorRedux.formData,
      antiFraud: {
        address: values,
      },
    };

    dispatchRedux.UPDATE_FORMDATA(newValues);
  };

  const handleNextUpdateValues = (fn) => () => {
    fn();

    return handleNext();
  };

  const props = {
    handleUpdateAddress,
    handleNextUpdateValues,
    handleResetCard,
    handleBack,
    handleNext,
    handleUpdateCard,
    handleUpdateVerifystep: dispatchRedux.updateVerifystep,
    handleConfirmAccount: dispatchRedux.confirmAccount,
    handleConfirmTransaction: dispatchRedux.confirmTransaction,
    handleFetch: dispatchRedux.FETCH_START,
    handleFetchZipcode: dispatchRedux.FETCH_START_ZIPCODE,
    handleUpdatePaymentType: dispatchRedux.UPDATE_PAYMENTTYPE,
    handleUpdateFormData: dispatchRedux.UPDATE_FORMDATA,
    handleUpdatePhone: dispatchRedux.UPDATE_PHONE,
    handleUpdateCurrentStep: dispatchRedux.updateCurrentstep,
    paymentType: selectorRedux.paymentType,
    verifyStep: selectorRedux.verifyStep,
    formData: selectorRedux.formData,
    selected: selectorRedux.selected,
    loading: selectorRedux.loading,
    totalSelected: Number(
      selectorRedux.selected
        .reduce((acc, curr) => acc + curr.amount, 0)
        .toFixed(2)
    ),
  };

  return props;
};

export default useCheckoutHook;
