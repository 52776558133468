import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { TextField, CircularProgress } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import toaster from '@giro/shared-store/toaster';

import theme from '@giro/client-app/src/constants/theme.constant';

import email from '@giro-app/modules/checkout/assets/email.png';

import postApiCheckoutsReferenceIdReceiptsService from '@giro/shared-services/checkout/postApiCheckoutsReferenceIdReceipts.service';

import payment from '../../store/payment';

import useListHook from '@giro-app/hooks/useListRedux.hook';

const DialogSendReceiptComponent = ({ handleClose, visible }) => {
  const dispatch = useDispatch();
  const { data } = useListHook(payment);
  const [loading, setLoading] = useState(false);
  const emailRef = useRef('');

  const dispatchToasterRedux = {
    show: flow(toaster.action.show, dispatch),
  };

  const handleSendEmail = async () => {
    const { reference_id } = data || {};

    setLoading(true);

    const [success, result] = await postApiCheckoutsReferenceIdReceiptsService(
      reference_id,
      emailRef.current
    );

    setLoading(false);

    if (success) {
      dispatchToasterRedux.show({
        message: 'Email enviado com sucesso!',
        variant: 'success',
      });

      handleClose();
    }
  };

  return (
    <Dialog onClose={handleClose} open={visible} maxWidth={'xs'}>
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <Box position="absolute" right={8} top={8}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box height={52} />
          <Box display="flex" flexDirection="column" gap={3}>
            <Box alignSelf="center">
              <img src={email} />
            </Box>
            <Typography align="center" variant="h3" color="black">
              Digite o e-mail para enviar a cópia do comprovante
            </Typography>
            <TextField
              label="E-mail"
              onChange={({ target: { value } }) => (emailRef.current = value)}
            />
            <Box mt={3}>
              <Button
                size="large"
                color="info"
                variant="contained"
                fullWidth
                onClick={handleSendEmail}
                sx={{
                  ...theme.typography.body1,
                  fontWeight: 600,
                }}
              >
                {!loading && <span>Enviar</span>}

                {loading && <CircularProgress color="inherit" />}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSendReceiptComponent;
