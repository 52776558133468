import { sortBy } from 'lodash';
import { takeLatest, select, call, put } from 'redux-saga/effects';

import getApiAppsService from '@giro/shared-services/debts/getApiApps.service';

import { ACTION_TYPES } from './apps.constant';
import * as selector from './apps.selector';
import * as action from './apps.action';

function* handleGetData() {
  const filters = yield select(selector.selectFilters);
  const loading = yield select(selector.selectLoading);
  const error = yield select(selector.selectError);

  if (loading === false && !error) {
    return true;
  }

  const [success, result] = yield call(getApiAppsService, filters);

  const resultSorted = sortBy(result, 'order');

  if (success) {
    return yield put(action.fetchFinish(resultSorted));
  }

  return yield put(action.fetchError(resultSorted));
}

function* watch() {
  yield takeLatest(ACTION_TYPES.FETCH.START, handleGetData);
}

export default {
  watch,
};
