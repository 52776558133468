import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography, Divider } from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import useQuery from '@giro/shared-hooks/useQuery.hook';

import { PAYMENT_TYPE } from '@giro-app/modules/checkout/constants/paymentType.constant';

import {
  FormCheckoutAntiFraudAddress,
  validationSchema as FormCheckoutAntiFraudAddressSchema,
} from './FormCheckoutAntiFraudAddress.component';
import { FormCheckoutCoupon } from './FormCheckoutCoupon.component';

import useListDebitsHook from '@giro-app/modules/debts/hooks/useListDebits.hook';

import { FormCheckoutConfirmInfo } from './FormCheckoutConfirmInfo.component';

import { FormCheckoutPaymentCardSchema } from './FormCheckoutPaymentCard.component';

import BoxSelectorPaymentTypeComponent from '../../Box/BoxSelectorPaymentType.component';

import BoxPaymentByCardComponent from '../../Box/BoxPaymentByCard.component';
import BoxPaymentConfirmation from '../../Box/BoxPaymentConfirmation.component';
import BoxPaymentByPixComponent from '../../Box/BoxPaymentByPix.component';

import checkout from '@giro-app/modules/checkout/store/checkout';

const FormCheckoutPayment = ({
  handleNext,
  handleBack,
  handleUpdatePaymentType,
  paymentType,
  totalSelected,
  loading,
  handleFetchZipcode,
}) => {
  const query = useQuery();

  const isPre = query.get('pre');

  const { values, setFieldValue, validateForm, submitForm, resetForm }: any =
    useFormikContext();

  const selectorRedux = {
    debit: useSelector(checkout.selector.selectDebit),
  };

  const firstCardAmount = Number((values as any)?.firstCard.amount || 0);

  const isCardUnique = paymentType === PAYMENT_TYPE.CARD;
  const isCardTwo = paymentType === PAYMENT_TYPE.CARD_TWO;
  const isCard = paymentType === PAYMENT_TYPE.CARD_ONE;
  const isPix = paymentType === PAYMENT_TYPE.PIX;

  useEffect(() => {
    if (isPre) {
      setTimeout(() => {
        submitForm();
      }, 600);
    }
  }, []);

  useEffect(() => {
    setFieldValue(
      'secondCard.amount',
      (totalSelected - firstCardAmount).toFixed(2)
    );
  }, [firstCardAmount]);

  useEffect(() => {
    validateForm();
  }, [paymentType]);

  return (
    <Box>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: '1fr',
          md: '1fr 1fr',
        }}
        gap={6}
        p={4}
        bgcolor="trasso.gray.20"
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <BoxSelectorPaymentTypeComponent
            handleUpdatePaymentType={handleUpdatePaymentType}
            isCard={isCard}
            isCardTwo={isCardTwo}
            isCardUnique={isCardUnique}
            isPix={isPix}
          />

          <Divider />

          <Box>
            {(isCard || isCardTwo || isCardUnique) && (
              <BoxPaymentByCardComponent
                loading={loading}
                isCard={isCard}
                isCardTwo={isCardTwo}
                totalSelected={totalSelected}
                handleNext={handleNext}
                handleBack={handleBack}
                handleUpdatePaymentType={handleUpdatePaymentType}
                paymentType={paymentType}
                isCardUnique={isCardUnique}
              />
            )}

            {isPix && <BoxPaymentByPixComponent />}
          </Box>
        </Box>

        <FormCheckoutAntiFraudAddress
          handleNextUpdateValues={() => {}}
          handleFetchZipcode={handleFetchZipcode}
          handleBack={() => {}}
          name="address"
        />
      </Box>
      <Box bgcolor="white" minHeight="25px"></Box>
      <Box>
        <Box
          display="grid"
          gridTemplateColumns="1fr"
          gap={6}
          bgcolor="trasso.gray.20"
        >
          <Box
            width="100%"
            bgcolor="white"
            display="grid"
            gridTemplateRows="1fr"
            gap={3}
          >
            {(isCardUnique || isCard) && (
              <Box
                display="flex"
                gap={2}
                alignItems={{
                  xs: 'start',
                  md: 'center',
                }}
                justifyContent="space-between"
                flexDirection={{
                  xs: 'column',
                  md: 'row',
                }}
              >
                <Box display="grid" gridTemplateRows="1fr 1fr">
                  <Typography
                    variant="trasso_site_corpo"
                    color="trasso_site.roxo"
                  >
                    Parcelas
                  </Typography>

                  <Typography color="trasso_site.rosa" variant="trasso_site_h3">
                    {values?.firstCard?.installments || 0}x de{' '}
                    {Number(
                      Number(values?.firstCard?.amount_total) /
                        Number(values?.firstCard?.installments) || 0
                    ).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </Box>

                <Box display="grid" gridTemplateRows="1fr 1fr">
                  <Typography
                    color="trasso_site.roxo"
                    variant="trasso_site_corpo"
                  >
                    Total a Pagar:
                  </Typography>
                  <Typography
                    color="trasso_site.roxo"
                    variant="trasso_site_h3_light"
                  >
                    {Number(
                      values?.firstCard?.amount_total || 0
                    ).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </Box>
              </Box>
            )}

            {isCardTwo && (
              <Box display="flex" flexDirection="column" gap={2}>
                <Box
                  display="flex"
                  gap={2}
                  alignItems={{
                    xs: 'start',
                    md: 'center',
                  }}
                  justifyContent="space-between"
                  flexDirection={{
                    xs: 'column',
                    md: 'row',
                  }}
                >
                  <Box display="grid" gridTemplateRows="1fr 1fr">
                    <Typography
                      variant="trasso_site_corpo"
                      color="trasso_site.roxo"
                    >
                      Parcelas (Cartão 1)
                    </Typography>

                    <Typography
                      color="trasso_site.rosa"
                      variant="trasso_site_h3"
                    >
                      {values?.firstCard?.installments || 0}x de{' '}
                      {Number(
                        Number(values?.firstCard?.amount_total) /
                          Number(values?.firstCard?.installments) || 0
                      ).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  </Box>

                  <Box display="grid" gridTemplateRows="1fr 1fr">
                    <Typography
                      color="trasso_site.roxo"
                      variant="trasso_site_corpo"
                    >
                      Total a Pagar (Cartão 1)
                    </Typography>
                    <Typography
                      color="trasso_site.roxo"
                      variant="trasso_site_h3_light"
                    >
                      {Number(
                        values?.firstCard?.amount_total || 0
                      ).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  gap={2}
                  alignItems={{
                    xs: 'start',
                    md: 'center',
                  }}
                  justifyContent="space-between"
                  flexDirection={{
                    xs: 'column',
                    md: 'row',
                  }}
                >
                  <Box display="grid" gridTemplateRows="1fr 1fr">
                    <Typography
                      variant="trasso_site_corpo"
                      color="trasso_site.roxo"
                    >
                      Parcelas (Cartão 2)
                    </Typography>

                    <Typography
                      color="trasso_site.rosa"
                      variant="trasso_site_h3"
                    >
                      {values?.secondCard?.installments || 0}x de{' '}
                      {Number(
                        Number(values?.secondCard?.amount_total) /
                          Number(values?.secondCard?.installments) || 0
                      ).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  </Box>

                  <Box display="grid" gridTemplateRows="1fr 1fr">
                    <Typography
                      color="trasso_site.roxo"
                      variant="trasso_site_corpo"
                    >
                      Total a Pagar (Cartão 2)
                    </Typography>
                    <Typography
                      color="trasso_site.roxo"
                      variant="trasso_site_h3_light"
                    >
                      {Number(
                        values?.secondCard?.amount_total || 0
                      ).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            <Box position="relative">
              <BoxPaymentConfirmation
                loading={loading}
                isCardTwo={isCardTwo}
                totalSelected={totalSelected}
                handleBack={handleBack}
                handleUpdatePaymentType={handleUpdatePaymentType}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default function FormCheckoutPaymentComponent(props) {
  const query = useQuery();

  const isPre = query.get('pre');

  const { paymentType, formData, handleFetch, ...rest } = props;

  const { installments: installmentDebit } = useListDebitsHook();

  const handleGetSchema = () => {
    let schema = Yup.object({
      firstCard: Yup.object().concat(FormCheckoutPaymentCardSchema),
      address: Yup.object().concat(FormCheckoutAntiFraudAddressSchema),
    });

    if (paymentType === PAYMENT_TYPE.CARD_TWO) {
      const schemaSecondCard = Yup.object({
        secondCard: Yup.object().concat(FormCheckoutPaymentCardSchema),
      });

      schema = schema.concat(schemaSecondCard);
    }

    return schema;
  };

  return (
    <>
      <Formik
        initialValues={{
          address: formData.payment.address,
          firstCard: formData.payment.firstCard,
          secondCard: formData.payment.secondCard,
          description: formData.payment.description,
        }}
        onSubmit={async () => {
          handleFetch();

          return true;
        }}
        validationSchema={handleGetSchema()}
        validateOnMount
        enableReinitialize
      >
        <FormCheckoutPayment {...props} />
      </Formik>

      {props.loading && isPre && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          bgcolor="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={99}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
