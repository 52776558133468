import { Fragment, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import ListFactory from '@giro-app/factories/List.factory';

import DrawerHistoryOrder from '../components/Drawer/DrawerHistoryOrder.component';
import DrawerOrderSearchComponent from '../components/Drawer/DrawerOrderSearch.component';

import history from '../store/history';

const RequestsScreen = () => {
  const dispatch = useDispatch();

  const dispatchHistoryRedux = {
    resetState: flow(history.action.resetState, dispatch),
  };

  useEffect(() => {
    return () => {
      dispatchHistoryRedux.resetState();
    };
  }, []);

  return (
    <Fragment>
      <Box
        display="grid"
        gap={3}
        minHeight="calc(100vh - 409px)"
        alignItems="flex-start"
      >
        <Box display="flex" flexDirection="column" gap={5} position="relative">
          <span
            id="anchor-search-history-list"
            css={{ position: 'absolute', top: 0 }}
          />

          <Box>
            <ListFactory variant="profileHistory" />
          </Box>
        </Box>
      </Box>

      <DrawerOrderSearchComponent />
      <DrawerHistoryOrder />
    </Fragment>
  );
};

export default RequestsScreen;
