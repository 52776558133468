import { all } from 'redux-saga/effects';

import listDebts from './list';

function* watch() {
  yield all([listDebts.saga.watch()]);
}

export default {
  watch,
};
