import React from 'react';
import { Box, TextField as TextFieldMaterial, Typography } from '@mui/material';
import { Field } from 'formik';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InputAdornment from '@mui/material/InputAdornment';

import messages from '@giro/shared-constants/messagesSchema.constant';

export const validationSchema = Yup.object({
  placa: Yup.string()
    .required(messages.REQUIRED)
    .min(7, messages.MIN)
    .max(7, messages.MAX)
    .matches(
      /[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/,
      'Você precisa digitar uma placa válida'
    ),
  document_number: Yup.string().required(messages.REQUIRED),
});

const FormPlacaRenavamComponent = (props) => {
  return (
    <Box display="grid" gridTemplateColumns="1fr" {...props} gap={'20px'}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography color="trasso_site.rosa" variant="trasso_site_caption">
          Placa
        </Typography>

        <Field name="placa">
          {({
            field,
            form: { setFieldValue, setFieldTouched },
            meta: { error, touched },
          }) => (
            <Box display="flex" flexDirection="row">
              <TextFieldMaterial
                fullWidth
                placeholder="Placa do veículo"
                variant="outlined"
                value={field.value}
                error={touched && !!error}
                helperText={touched && !!error && error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!error ? (
                        <CheckCircleIcon css={{ fill: '#9DCC12' }} />
                      ) : (
                        <CancelIcon css={{ fill: '#FF5832' }} />
                      )}
                    </InputAdornment>
                  ),
                }}
                onBlur={() => {
                  setFieldTouched(field.name, true);
                }}
                onChange={({ target: { value } }) =>
                  setFieldValue(field.name, String(value).toUpperCase())
                }
              />
            </Box>
          )}
        </Field>
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        <Typography color="trasso_site.rosa" variant="trasso_site_caption">
          Número do Documento
        </Typography>

        <Field name="document_number">
          {({
            field,
            form: { setFieldValue, setFieldTouched },
            meta: { error, touched },
          }) => (
            <NumberFormat
              customInput={TextFieldMaterial}
              fullWidth
              placeholder="CPF/CNPJ"
              format={
                field?.value?.length > 11
                  ? '##.###.###/####-##'
                  : '###.###.###-###'
              }
              value={field.value}
              error={touched && !!error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!error ? (
                      <CheckCircleIcon css={{ fill: '#9DCC12' }} />
                    ) : (
                      <CancelIcon css={{ fill: '#FF5832' }} />
                    )}
                  </InputAdornment>
                ),
              }}
              helperText={touched && !!error && error}
              onBlur={() => {
                setFieldTouched(field.name, true);
              }}
              onValueChange={(values: any) =>
                setFieldValue(field.name, values.value)
              }
            />
          )}
        </Field>
      </Box>
    </Box>
  );
};

export default FormPlacaRenavamComponent;
