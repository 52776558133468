import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Checkbox, Typography, Skeleton } from '@mui/material';
import { isEmpty, flow } from 'lodash';
import qs from 'qs';

import ROUTES from '@giro-app/constants/routes.constant';

import useAuth from '@giro/shared-hooks/useAuth.hook';
import useCurrentUrl from '@giro-app/hooks/useCurrentUrl.hook';
import useListDebits from '../hooks/useListDebits.hook';

import BoxSearch from './BoxSearchDebit.component';

import checkout from '../../checkout/store/checkout';
import router from '@giro/shared-store/router';
import apps from '@giro-app/store/apps';

const SelectorComponent = () => {
  const dispatch = useDispatch();

  const currentUrl = useCurrentUrl();

  const {
    handleSelectAll,
    handleUnselectAll,
    handleUpdateFilters,
    selected,
    results,
    metadata,
    filters,
    loading,
  } = useListDebits();

  const { signedIn, user: userInfo } = useAuth();

  const dispatchCheckoutRedux = {
    updateDebit: flow(checkout.action.updateDebit, dispatch),
    updateApp: flow(checkout.action.updateApp, dispatch),
  };

  const dispatchRouterRedux = {
    push: flow(router.action.push, dispatch),
  };

  const selectorRouterRedux = {
    search: useSelector(router.selector.selectSearch),
  };

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
  };

  const indeterminate =
    selected.length !== results?.length && selected.length !== 0;

  const checked = selected.length === results?.length;

  React.useEffect(() => {
    const params = selectorRouterRedux?.search?.substring(1);
    const { mode, ...paramsParsed } = qs.parse(params);

    if (isEmpty(paramsParsed)) {
      return undefined;
    }

    handleUpdateFilters(paramsParsed);
  }, [selectorRouterRedux?.search]);

  const handleClickPay = () => {
    dispatchCheckoutRedux.updateDebit({
      integration_id: filters?.integration_id,
      reference_id: metadata?.reference_id,
      debits: selected,
    });

    const app = selectorAppsRedux.data?.find(
      (a) => a.id === filters?.integration_id
    );

    if (app) {
      dispatchCheckoutRedux.updateApp(app);
    }

    return dispatchRouterRedux.push(ROUTES.CHECKOUT.ROOT);
  };

  return (
    <>
      <Box display="grid" gap={4}>
        <Box
          minHeight={{
            md: 594,
          }}
          order={{
            xs: 1,
            md: 0,
          }}
          display="flex"
          flexDirection="column"
          gap="40px"
        >
          <Typography variant="trasso_site_h3" color="trasso_site.roxo2">
            Consultar Débitos de Veículos:
          </Typography>

          <BoxSearch />
        </Box>
      </Box>
    </>
  );
};

export default SelectorComponent;
