import { Fragment, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Global, css } from '@emotion/react';

import bg from '@giro-app/modules/payment/assets/bg.png';
import placa from '@giro-app/modules/payment/assets/placa.png';

import FormSearchDebitHomeFormik from '../components/Form/FormSearchDebitHome.component';

const PaymentScreen = () => {
  return (
    <Fragment>
      <Global
        styles={css`
          #root {
            background: url(${bg}) no-repeat center center fixed;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
          }
        `}
      />

      <Box
        display="grid"
        alignItems="center"
        gridTemplateColumns={{
          md: '1fr 419px',
          xs: '1fr',
        }}
        minHeight={{
          md: 687,
        }}
        sx={{
          gap: {
            xs: 3,
            md: 0,
          },
        }}
      >
        <Box>
          <Typography variant="h1" color="white">
            Pague com facilidade o seu IPVA
          </Typography>
          <Typography variant="body1" color="white">
            Selecione um dos Detrans e verifique seus débitos
          </Typography>
        </Box>
        <Box
          width="100%"
          bgcolor="white"
          borderRadius={2}
          p={3}
          display="flex"
          flexDirection="column"
          gap={3}
        >
          <Box
            display="grid"
            gridTemplateColumns="1fr"
            justifyItems="center"
            gap={2}
          >
            <img src={placa} />

            <Typography color="primary.main" variant="h3" align="center">
              Faça a consulta do seu veículo e parcele em até 18x
            </Typography>
          </Box>

          <FormSearchDebitHomeFormik />
        </Box>
      </Box>
    </Fragment>
  );
};

export default PaymentScreen;
