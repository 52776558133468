import React from 'react';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { FORMCHECKOUT_STEPS } from '@giro-app/modules/checkout/constants/stepsFormCheckout.constant';

import checkout from '@giro-app/modules/checkout/store/checkout';

const useStepHook = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    UPDATE_CURRENTSTEP: flow(checkout.action.updateCurrentstep, dispatch),
  };

  const selectorRedux = {
    currentStep: useSelector(checkout.selector.selectCurrentStep),
  };

  const currentIndex = FORMCHECKOUT_STEPS.findIndex(
    (f) => f === selectorRedux?.currentStep
  );

  const nextStep = FORMCHECKOUT_STEPS[currentIndex + 1];
  const backStep = FORMCHECKOUT_STEPS[currentIndex - 1];
  const currentStep = FORMCHECKOUT_STEPS[currentIndex];

  const handleNext = () =>
    nextStep && dispatchRedux.UPDATE_CURRENTSTEP(nextStep);

  const handleBack = () =>
    backStep && dispatchRedux.UPDATE_CURRENTSTEP(backStep);

  return {
    handleNext,
    handleBack,
    nextStep,
    backStep,
    currentStep,
  };
};

export default useStepHook;
