import { ACTION_TYPES } from './payment.constant';

export const fetchStart = (referenceId) => ({
  type: ACTION_TYPES.FETCH.START,
  referenceId,
});

export const fetchFinish = (payload) => ({
  type: ACTION_TYPES.FETCH.END,
  payload,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET,
});
