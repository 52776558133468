import flow from 'lodash/flow';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Formik, useFormikContext, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from 'formik-mui';
import Button from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import qs from 'qs';

import router from '@giro/shared-store/router';

import ROUTES from '@giro-app/constants/routes.constant';

import messages from '@giro/shared-constants/messagesSchema.constant';

import apps from '../../../../store/apps';

import FormPlacaRenavam, {
  validationSchema as validationSchemaPlacaRenavam,
} from '../../../debts/components/Form/FormPlacaRenavam.component';

import FormRenavam, {
  validationSchema as validationSchemaRenavam,
} from '../../../debts/components/Form/FormRenavam.component';

import FormDocument, {
  validationSchema as validationSchemaDocument,
} from '../../../debts/components/Form/FormPlacaDocument.component';

import FormPlate, {
  validationSchema as validationSchemaPlate,
} from '../../../debts/components/Form/FormPlaca.component';

const FormSearchDebitContainer = ({ type, setDetran }) => {
  const dispatch = useDispatch();
  const { errors, values }: any = useFormikContext();

  const dispatchRouterRedux = {
    push: flow(router.action.push, dispatch),
  };

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
  };

  useEffect(() => {
    setDetran(values.integration_id);
  }, [values.integration_id]);

  const handleSubmit = () => {
    const valuesQs = qs.stringify({ ...values });

    const path = [ROUTES.DEBTS.ROOT, valuesQs].join('?');

    dispatchRouterRedux.push(path);
  };

  return (
    <FormSearchDebitComponent
      errors={errors}
      type={type}
      apps={selectorAppsRedux?.data?.filter((a) => a.filter_type !== 3)}
      handleSubmit={handleSubmit}
    />
  );
};

const FormSearchDebitComponent = ({ errors, type, apps, handleSubmit }) => {
  return (
    <Box display="flex" gap={3} width="100%" flexDirection="column">
      <Box display="grid" gap={3} gridTemplateColumns="1fr">
        {apps?.length > 0 && (
          <Field
            component={TextField}
            name="integration_id"
            fullWidth
            label="Escolha o Detran"
            variant="outlined"
            select
            SelectProps={{
              native: true,
            }}
          >
            <option value=""></option>
            {apps
              ?.filter((a) => a.status === 'ACTIVE')
              .map((a) => (
                <option value={a.id} key={a.id}>
                  {a.description}
                </option>
              ))}
          </Field>
        )}

        {type === 'placaRenavam' && <FormPlacaRenavam />}
        {type === 'renavam' && <FormRenavam />}
        {type === 'document' && <FormDocument />}
        {type === 'plate' && <FormPlate />}
      </Box>

      <Box my={3}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          disabled={Object.keys(errors).length > 0}
          css={{ height: 48, fontSize: 20 }}
          color="secondary"
        >
          Consultar
        </Button>
      </Box>
    </Box>
  );
};

export default function FormSearchDebitHomeFormik() {
  const [currentDetran, setCurrentDetran] = useState<any>(undefined);

  const dispatch = useDispatch();

  const dispatchRedux = {
    fetchStart: flow(apps.action.fetchStart, dispatch),
  };

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
  };

  const currentDetranObj = selectorAppsRedux?.data?.find(
    (s) => s.id === currentDetran
  );

  let type = 'placaRenavam';

  if (currentDetranObj?.filter_type === 1) {
    type = 'renavam';
  }

  if (currentDetranObj?.filter_type === 2) {
    type = 'document';
  }

  if (currentDetranObj?.filter_type === 5) {
    type = 'plate';
  }

  const schemaWithDetran = Yup.object({
    integration_id: Yup.string()
      .required(messages.REQUIRED)
      .default(currentDetran),
  });

  const validationTypes = {
    placaRenavam: validationSchemaPlacaRenavam.concat(schemaWithDetran),
    renavam: validationSchemaRenavam.concat(schemaWithDetran),
    document: validationSchemaDocument.concat(schemaWithDetran),
    plate: validationSchemaPlate.concat(schemaWithDetran),
  };

  return (
    <Formik
      key={currentDetran}
      initialValues={{ ...validationTypes[type].cast(undefined) }}
      onSubmit={() => {}}
      validationSchema={validationTypes[type]}
      validateOnMount
    >
      <FormSearchDebitContainer type={type} setDetran={setCurrentDetran} />
    </Formik>
  );
}
