import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, addMinutes, isBefore, parseISO, startOfDay } from 'date-fns';
import { flow } from 'lodash';
import { Box, Typography, Divider, Button } from '@mui/material';
import Cookies from 'js-cookie';

import postApiInstallmentsService from '@giro/shared-services/checkout/postApiInstallments.service';

import useAuth from '@giro/shared-hooks/useAuth.hook';
import useCurrentUrl from '@giro-app/hooks/useCurrentUrl.hook';
import useQuery from '@giro/shared-hooks/useQuery.hook';
import useVerifyOnboardingHook from '@giro-app/hooks/useVerifyOnboarding.hook';

import themeConstants from '@giro/client-app/src/constants/theme.constant';

import ROUTES from '@giro-app/constants/routes.constant';

import router from '@giro/shared-store/router';

import { saveRedirectInLocalStorage } from '@giro-app/utils/redirectLocalStorage.util';

import useListDebitsHook from '../hooks/useListDebits.hook';

import checkout from '../../checkout/store/checkout';

import apps from '@giro-app/store/apps';

import list from '../store/list';

const getTaxFormatted = (value) =>
  Number(value).toLocaleString('pt-br', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

function formatDate(date, formatter) {
  return format(addMinutes(date, date.getTimezoneOffset()), formatter);
}

const ItemBillet = ({ label, value, ...restProps }) => (
  <Box display="flex" flexDirection="column" {...restProps}>
    <Typography variant="subtitle2" color="gray.400">
      {label}
    </Typography>
    <Typography variant="subtitle1" color="primary.main">
      {value}
    </Typography>
  </Box>
);

const BilletComponent = () => {
  const { checked_onboarding, items: itemsOnboarding = [] } =
    useVerifyOnboardingHook();

  const query = useQuery();

  const [isTribute, setIsTribute] = React.useState(false);
  const [loadingInstallment, setLoadingInstallment] = React.useState(false);
  const [installments, setInstallments] = React.useState([]);

  const currentUrl = useCurrentUrl();
  const { signedIn } = useAuth();
  const dispatch = useDispatch();
  const { results = [], filters, metadata } = useListDebitsHook();

  const billet = results[0];

  React.useEffect(() => {
    if (billet?.barcode) {
      const first = billet.barcode[0];
      setIsTribute(first === '8');
    }
  }, [billet?.barcode]);

  const dispatchCheckoutRedux = {
    updateDebit: flow(checkout.action.updateDebit, dispatch),
    updateApp: flow(checkout.action.updateApp, dispatch),
  };

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
    isPartner: useSelector(list.selector.selectIsPartner),
  };

  const dispatchRouterRedux = {
    push: flow(router.action.push, dispatch),
  };

  const dispatchRedux = {
    servicePost: flow(list.action.servicePostTokenPartner, dispatch),
  };

  const handleGetInstallments = async (value) => {
    setLoadingInstallment(true);

    try {
      const [success, result] = await postApiInstallmentsService({
        integration_id: query.get('integration_id') as any,
        amount: value,
        type: 'CARTAO',
      });

      if (success) {
        const resultParsed = result?.map((r) => ({
          ...r,
          tax_percent: getTaxFormatted((r.amount_total - value) / value),
        }));

        setInstallments(resultParsed);
      }
    } catch {
      return false;
    }

    setLoadingInstallment(false);
  };

  const isDateInPast = (date) => {
    const now = startOfDay(new Date());
    const formattedDate = new Date(date.split('T')[0].replace(/-/g, '/'));

    const verifyDates = isBefore(formattedDate, now);

    return verifyDates;
  };

  React.useEffect(() => {
    handleGetInstallments(billet?.amount);
  }, []);

  React.useEffect(() => {
    if (selectorAppsRedux.isPartner) {
      dispatchRedux.servicePost();
    }
  }, []);

  const handleClickPay = () => {
    const isPartnerBool = selectorAppsRedux.isPartner === 'true';

    if (filters?.integration_id) {
      Cookies.set(
        process.env.REACT_APP_COOKIE_KEY_INTEGRATION_ID,
        filters?.integration_id,
        {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        }
      );
    }

    if (isPartnerBool && checked_onboarding === false) {
      (window as any).location.href = process.env.REACT_APP_ONBOARDING_URL;

      return null;
    }

    dispatchCheckoutRedux.updateDebit({
      integration_id: filters?.integration_id,
      reference_id: metadata?.reference_id,
      installment: installments[0],
      debits: results,
    });

    const app = selectorAppsRedux.data?.find(
      (a) => a.id === filters?.integration_id
    );

    if (app) {
      dispatchCheckoutRedux.updateApp(app);
    }

    return dispatchRouterRedux.push(ROUTES.CHECKOUT.ROOT);
  };

  const maskDocument = (document) => {
    const stringDocument = String(document);

    if (stringDocument.length === 11) {
      return stringDocument.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
        '$1.$2.$3-$4'
      );
    }
    if (stringDocument.length === 14) {
      return stringDocument.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
        '$1.$2.$3/$4-$5'
      );
    }
  };

  const beneficiaryTribute = billet?.payer?.name;
  const beneficiryNotTribute = [
    billet?.beneficiary?.document?.number &&
      maskDocument(billet?.beneficiary?.document?.number),
    billet?.beneficiary?.name,
  ]
    .filter(Boolean)
    .join(' - ');

  const beneficiary = isTribute ? beneficiaryTribute : beneficiryNotTribute;

  const isBilletDued = isDateInPast(billet?.due_date);

  return (
    <Box display="grid" gridTemplateColumns="1fr" gap={2}>
      <Box display="flex" alignItems="center" justifyContent={'space-between'}>
        <Typography fontWeight={800} color="red">
          {isBilletDued && 'BOLETO VENCIDO'}
        </Typography>

        <Box>
          <Button
            variant="contained"
            onClick={handleClickPay}
            disabled={isBilletDued}
            sx={{
              backgroundColor: 'success.main',
              height: 48,
              width: 155,
              fontSize: themeConstants.typography.body1.fontSize,
            }}
          >
            Pagar conta
          </Button>
        </Box>
      </Box>
      <Box
        width="100%"
        border="1px solid black"
        borderColor="gray.200"
        p={4}
        borderRadius={2}
        gap={2}
        display="grid"
        gridTemplateColumns="1fr"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" fontWeight={700} color="primary.main">
            Total a pagar
          </Typography>
          <Box px={1} py={0.5} bgcolor="info.light3" borderRadius="2px">
            <Typography variant="body1" fontWeight={700} color="primary.main">
              {Number(billet.amount).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Box>
        </Box>
        <Box display="grid" gridTemplateColumns="1fr" gap={2}>
          <ItemBillet label="Descrição" value={billet?.description} />
          <ItemBillet
            label="Data de vencimento"
            value={formatDate(new Date(billet?.due_date), 'dd/MM/yyyy')}
          />
        </Box>
        <Divider />
        <Box display="grid" gridTemplateColumns="1fr" gap={2}>
          {billet?.bank?.id && (
            <ItemBillet
              label="Banco destinatário"
              value={`${billet?.bank?.id} - ${billet?.bank?.name}`}
            />
          )}
          <ItemBillet label="Beneficiário" value={beneficiary} />
        </Box>
        <Divider />
        <Box display="grid" gridTemplateColumns="1fr" gap={2}>
          <ItemBillet
            label="Valor do documento"
            value={Number(billet.amount).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          />
        </Box>
        <Divider />
        <Typography variant="caption" color="secondary.dark">
          * Verifique sempre o banco destinatário e o beneficiário antes de
          pagar sua conta
        </Typography>
      </Box>
    </Box>
  );
};

export default BilletComponent;
