import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import useDialogHook from '@giro-app/hooks/useDialogRedux.hook';

import CloseIcon from '@mui/icons-material/Close';

import dialogContactUs from '@giro-app/store/configs/dialogs/dialogContactUs';

const DialogContactUsComponent = () => {
  const { visible, handleClose } = useDialogHook(dialogContactUs);

  return (
    <Dialog onClose={handleClose} open={visible} maxWidth={'sm'}>
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <Box position="absolute" right={8} top={8}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box height={52} />

          <Box display="flex" flexDirection="column" gap={3}>
            <iframe
              src="https://giropagamentos.movidesk.com/form/5763/"
              width="400"
              height="800"
              frameBorder="0"
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogContactUsComponent;
