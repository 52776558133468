import { sortBy } from 'lodash';
import { takeLatest, select, call, put } from 'redux-saga/effects';

import getApiCheckoutsService from '@giro/shared-services/checkout/getApiCheckouts.service';

import { ACTION_TYPES } from './history.constant';
import * as selector from './history.selector';
import * as action from './history.action';

import toaster from '@giro/shared-store/toaster';

function* handleGetData() {
  const filters = yield select(selector.selectFilters);

  const [success, result] = yield call(getApiCheckoutsService, filters);

  if (success) {
    return yield put(action.fetchFinish(result));
  }

  if (!success) {
    yield put(
      toaster.action.show({
        message: result?.message,
        variant: 'error',
      })
    );
  }

  return yield put(action.fetchError(result));
}

function* watch() {
  yield takeLatest(ACTION_TYPES.FETCH.START, handleGetData);
  yield takeLatest(ACTION_TYPES.UPDATE.FILTERS, handleGetData);
}

export default {
  watch,
};
