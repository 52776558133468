import reducer from './debts.reducer';
import * as constant from './debts.constant';
import * as selector from './debts.selector';
import saga from './debts.saga';
import subscriber from './debts.subscriber';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  saga,
  subscriber,
};
