const PHONE = 'phone';
const SEND_SMS = 'send_sms';
const CONFIRM_ACCOUNT = 'confirm_account';
const CONFIRM_TRANSACTION = 'confirm_transaction';
const FINISH = 'finish';

export const VERIFY_STEPS = {
  PHONE,
  SEND_SMS,
  CONFIRM_ACCOUNT,
  CONFIRM_TRANSACTION,
  FINISH,
};
