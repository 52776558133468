import dialogChangePassword from '.';

let nextState = {};
let prevState = {};

const resetModal = (dispatch) => {
  const visible = dialogChangePassword.selector.selectVisible(nextState);

  if (visible === false) {
    return setTimeout(() => {
      dispatch(dialogChangePassword.action.reset());
    }, 500);
  }
};

const subscribe = ({ getState, dispatch }) => {
  nextState = getState();

  resetModal(dispatch);

  prevState = nextState;
};

export default {
  subscribe,
};
