import { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';

type Props = {
  metadata: any;
  loading: any;
  handleUpdateFilters: any;
};

const PaginationComponent = ({
  metadata,
  loading,
  handleUpdateFilters,
}: Props) => {
  useEffect(() => {
    if (loading === true) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [loading]);

  const handleChangePage = (ev, page) =>
    handleUpdateFilters({
      offset: (page - 1) * metadata.limit,
    });

  return (
    <Pagination
      count={Math.ceil(metadata?.count / metadata?.limit) || 0}
      page={(metadata?.offset / metadata?.limit || 0) + 1}
      shape="rounded"
      color="primary"
      disabled={loading}
      onChange={handleChangePage}
      sx={{
        alignSelf: 'center',
      }}
    />
  );
};

export default PaginationComponent;
