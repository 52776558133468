import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import FormCheckoutPaymentDialogComponent from '../Form/FormCheckout/FormCheckoutPaymentDialog.component';

const DialogCardDetailComponent = ({ visible, handleClose }) => {
  return (
    <Dialog onClose={handleClose} open={visible} maxWidth={'sm'}>
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <Box position="absolute" right={8} top={8}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box height={52} />

          <Box display="flex" flexDirection="column" gap={3}>
            <Typography color="info.dark2" variant="h3">
              Pagamento 01
            </Typography>

            <FormCheckoutPaymentDialogComponent handleClose={handleClose} />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogCardDetailComponent;
