import React from 'react';
import { Box } from '@mui/system';
import { Divider, Typography, Checkbox } from '@mui/material';

import { rgba } from 'emotion-rgba';

import theme from '@giro/client-app/src/constants/theme.constant';

const normalizeType = {
  LIC: 'Licenciamento',
};

const BoxDebitComponent = ({
  name,
  registry,
  document,
  amount,
  onChangeCheck,
  checked,
  type,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gap={'20px'}
      width="100%"
      bgcolor={checked ? rgba(theme.palette.trasso.gray[20], 1) : 'white'}
      border={'1px solid'}
      borderColor={checked ? 'transparent' : 'trasso_site.base2'}
      borderRadius={'6px'}
      py={4}
      px={2}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" position="relative" alignItems="start" gap={1}>
          <Checkbox
            color="secondary"
            onChange={onChangeCheck}
            checked={checked}
          />

          <Box display="flex" flexDirection="column">
            <Typography variant="trasso_site_h3" color="trasso_site.roxo2">
              {normalizeType[type] || type}
            </Typography>
            <Typography variant="trasso_site_corpo" color="info.dark2">
              {name}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gap={1}>
        <Typography variant="trasso_site_h3" color="secondary.main">
          {Number(amount).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default BoxDebitComponent;
