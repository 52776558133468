import { combineReducers } from 'redux';

import dialogs from './dialogs';
import checkout from './checkout';

const reducer = combineReducers({
  [dialogs.name]: dialogs.reducer,
  [checkout.name]: checkout.reducer,
});

export default reducer;
