export default function verifyOnboardingUtil(
  integration_id,
  appsState,
  authState
) {
  const currentIntegrationIdObj = appsState?.data?.find(
    (d) => d.id === integration_id
  );

  const requiredItems = [
    'require_bgc',
    'require_document',
    'require_selfie',
    'require_user',
  ].reduce(
    (acc: any, curr) =>
      [...acc, currentIntegrationIdObj?.[curr] ? curr : undefined].filter(
        Boolean
      ),
    []
  );

  const parserUser = {
    require_bgc: 'checked_bgc',
    require_document: 'checked_document',
    require_selfie: 'checked_selfie',
  };

  const userRequiredItems = requiredItems.reduce(
    (acc, ri) => ({
      ...acc,
      [ri]: authState?.user?.user?.[parserUser[ri]],
    }),
    {}
  );

  if (requiredItems.includes('require_user')) {
    userRequiredItems.require_user =
      authState.user?.user?.checked_phone &&
      authState.user?.user?.checked_email;
  }

  const userPassRequiredItems = Object.values(userRequiredItems).every(Boolean);

  return {
    checked_onboarding: userPassRequiredItems,
    items: requiredItems,
  };
}
