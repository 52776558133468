import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Formik, Field, useFormikContext, Form } from 'formik';
import { TextField } from 'formik-mui';
import { isEmpty, flow, orderBy } from 'lodash';
import qs from 'qs';

import postApiInstallmentsService from '@giro/shared-services/checkout/postApiInstallments.service';

import router from '@giro/shared-store/router';

import useAuth from '@giro/shared-hooks/useAuth.hook';
import useCurrentUrl from '@giro-app/hooks/useCurrentUrl.hook';

import List from '@giro-app/factories/List.factory';

import { saveRedirectInLocalStorage } from '@giro-app/utils/redirectLocalStorage.util';

import ROUTES from '@giro-app/constants/routes.constant';

import useListDebits from '../hooks/useListDebits.hook';
import useQuery from '@giro/shared-hooks/useQuery.hook';

import bg from '../assets/background-sm.png';

import BoxSearch from './BoxSearchDebit.component';

import checkout from '../../checkout/store/checkout';
import apps from '@giro-app/store/apps';

const getTaxFormatted = (value) =>
  Number(value).toLocaleString('pt-br', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

const BoxInstallment = ({
  installment,
  amount_installment,
  amount_total,
  amount_tax,
  selected,
  featured,
  onSelect,
  i,
}) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: '1fr',
        lg: 'auto 1fr auto',
      }}
      py={4}
      px={3}
      bgcolor={selected ? 'trasso_site.roxo' : 'white'}
      justifyContent="space-between"
      borderRadius="6px"
      gap={1}
    >
      {/* <Box
        sx={{
          '.MuiCheckbox-root:not(.Mui-checked)': {
            color: 'trasso_site.base2',
          },
        }}
      >
        <Checkbox
          checked={selected}
          color="secondary"
          onClick={() =>
            onSelect({
              installment,
              installments: installment,
              amount_installment,
              amount_total,
              amount: amount_installment,
              amount_tax,
            })
          }
        />
      </Box> */}

      <Box display="flex" flexDirection="column" gap={1}>
        <Typography
          variant="trasso_site_h3"
          color={selected ? 'white' : 'trasso_site.roxo'}
        >
          {installment}x de{' '}
          <Box component="span" color="trasso_site.rosa">
            {Number(amount_installment).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Box>
        </Typography>
        <Typography
          variant="trasso_site_corpo"
          color={selected ? 'white' : 'trasso_site.roxo'}
        >
          Acréscimo:{' '}
          {Number(amount_tax).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        <Typography
          color={selected ? 'white' : 'trasso_site.roxo'}
          variant="trasso_site_h3_light"
          sx={{
            textAlign: {
              xs: 'left',
              md: 'right',
            },
          }}
        >
          {Number(amount_total).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>

        {featured && (
          <Box bgcolor="trasso_site.rosa" height="30px" px={1} alignSelf="end">
            <Typography color="white" variant="trasso_site_caption">
              Recomendado
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const DebtDetranComponent = () => {
  const firstRender: any = React.useRef(true);

  const query = useQuery();

  const [loadingInstallment, setLoadingInstallment] = React.useState(false);
  const [installments, setInstallments] = React.useState([]);
  const [installmentBest, setInstallmentBest] = React.useState(18);
  const [installmentSelected, setInstallmentSelected] = React.useState(0);

  const dispatch = useDispatch();

  const currentUrl = useCurrentUrl();

  const {
    handleSelectAll,
    handleUnselectAll,
    handleUpdateFilters,
    selected,
    results,
    metadata,
    filters,
    installments: installmentsDebit,
    loading,
    handleUpdateInstallment,
  } = useListDebits();

  const { signedIn, user: userInfo } = useAuth();

  const dispatchCheckoutRedux = {
    updateDebit: flow(checkout.action.updateDebit, dispatch),
    updateApp: flow(checkout.action.updateApp, dispatch),
  };

  const dispatchRouterRedux = {
    push: flow(router.action.push, dispatch),
  };

  const selectorRouterRedux = {
    search: useSelector(router.selector.selectSearch),
  };

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
  };

  const indeterminate =
    selected.length !== results?.length && selected.length !== 0;

  const checked = selected.length === results?.length;

  React.useEffect(() => {
    if (loading === true) {
      return undefined;
    }

    const params = selectorRouterRedux?.search?.substring(1);
    const { mode, ...paramsParsed } = qs.parse(params);

    if (isEmpty(paramsParsed)) {
      return undefined;
    }

    handleUpdateFilters(paramsParsed);
  }, [selectorRouterRedux?.search]);

  const handleClickPay = () => {
    if (filters?.integration_id) {
      Cookies.set(
        process.env.REACT_APP_COOKIE_KEY_INTEGRATION_ID,
        filters?.integration_id,
        {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        }
      );
    }

    dispatchCheckoutRedux.updateDebit({
      integration_id: filters?.integration_id,
      reference_id: metadata?.reference_id,
      installment: installmentsDebit,
      debits: selected,
    });

    const app = selectorAppsRedux.data?.find(
      (a) => a.id === filters?.integration_id
    );

    if (app) {
      dispatchCheckoutRedux.updateApp({
        ...app,
      });
    }

    return dispatchRouterRedux.push(ROUTES.CHECKOUT.ROOT);
  };

  const handleGetInstallments = async (value) => {
    setLoadingInstallment(true);

    try {
      const [success, result] = await postApiInstallmentsService({
        integration_id: query.get('integration_id') as any,
        amount: value,
        type: 'CARTAO',
      });

      if (success) {
        const resultParsed = result?.map((r) => ({
          ...r,
          tax_percent: getTaxFormatted((r.amount_total - value) / value),
        }));

        setInstallments(resultParsed);
      }
    } catch {
      return false;
    }

    setLoadingInstallment(false);
  };

  useEffect(() => {
    const lenghtArr = Array.from(selected).length;

    if (lenghtArr === 0) {
      return undefined;
    }

    const amountTotal = Array.from(selected).reduce(
      (acc, curr: any) => acc + curr.amount,
      0
    );

    handleGetInstallments(amountTotal);
  }, [Array.from(selected).length]);

  const heightSidebar =
    (document as any).querySelector('#sidebar-debts')?.clientHeight || 0;

  const bestInstallmentObj: any = installments.find(
    (i: any) => i.installments === installmentBest
  );

  const installmentsOrdered: any = orderBy(
    installments,
    'installments',
    'desc'
  );

  useEffect(() => {
    if (installmentsOrdered.length > 0 && firstRender.current) {
      firstRender.current = false;

      setInstallmentSelected(installmentsOrdered[0].installments);
      handleUpdateInstallment(installmentsOrdered[0]);
    }
  }),
    [installmentsOrdered];

  return (
    <Box display="flex" flexDirection="column" gap="40px" position="relative">
      <Typography variant="trasso_site_h3" color="trasso_site.roxo2">
        Consultar Débitos de Veículos:
      </Typography>

      <Box
        display="grid"
        gap={4}
        gridTemplateColumns={{
          xs: '1fr',
          lg: '530px 1fr',
        }}
        id="sidebar-debts"
        alignItems="start"
        position="relative"
        minHeight={{
          md: 'calc(100vh - 513px)',
        }}
      >
        {loading === false && Array.from(results).length > 0 && (
          <Box
            order={{
              xs: 1,
              md: 1,
            }}
            bgcolor="trasso_site.base"
            position="sticky"
            top={24}
          >
            <Box
              p={4}
              display="flex"
              gap={4}
              flexDirection="column"
              borderRadius="20px 20px 0px 0px"
              bgcolor="trasso_site.roxo"
              top={24}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                gap={2}
                flexDirection={{ xs: 'column', md: 'row' }}
              >
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography variant="trasso_site_corpo" color="white">
                    Indicado
                  </Typography>
                  <Typography color="trasso_site.rosa" variant="trasso_site_h3">
                    {bestInstallmentObj?.installments}x de{' '}
                    {Number(bestInstallmentObj?.amount || 0).toLocaleString(
                      'pt-br',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </Typography>
                  <Typography color="white" fontSize="14px">
                    Total:{' '}
                    {Number(
                      bestInstallmentObj?.amount_total || 0
                    ).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </Box>

                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography color="white" variant="trasso_site_corpo">
                    Debitos Selecionados
                  </Typography>
                  <Typography color="white" variant="trasso_site_h3_light">
                    {Number(
                      selected.reduce((acc, curr) => curr.amount + acc, 0)
                    ).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </Box>
              </Box>

              <Button
                variant="contained"
                disabled={
                  loadingInstallment || loading || selected.length === 0
                }
                onClick={handleClickPay}
                sx={{
                  backgroundColor: 'trasso_site.rosa',
                  fontSize: '20px',
                  borderRadius: '15px',
                  height: 59,
                }}
              >
                Pagar Agora
              </Button>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              p={4}
              gap={3}
              key={JSON.stringify(results)}
            >
              <Box
                flexDirection="column"
                display="flex"
                gap={2}
                height={{
                  xs: 'auto',
                  lg: 600,
                }}
                px={4}
                mx={'-32px'}
                pr={2}
                sx={{
                  overflowY: 'scroll',
                }}
              >
                <Typography
                  color="trasso_site.rosa"
                  variant="trasso_site_caption"
                >
                  Opções de Pagamento
                </Typography>

                <Box display="flex" flexDirection="column" gap={3}>
                  {[...Array.from(installmentsOrdered)].map((i: any, index) => (
                    <BoxInstallment
                      i={i}
                      onSelect={(i) => {
                        setInstallmentSelected(i.installment);
                        handleUpdateInstallment(i);
                      }}
                      key={`box-installment-${index}`}
                      installment={i.installments}
                      amount_installment={i.amount}
                      amount_tax={
                        i.amount_total -
                        selected.reduce((acc, curr) => curr.amount + acc, 0)
                      }
                      amount_total={i.amount_total}
                      featured={index === 0}
                      selected={installmentSelected === i?.installments}
                    />
                  ))}
                </Box>
              </Box>
            </Box>

            {loadingInstallment && (
              <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                bgcolor="#ffffffe0"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        )}

        <Box>
          <List variant="paymentDebits" />
        </Box>
      </Box>

      {loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          bgcolor="white"
          zIndex={999}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default DebtDetranComponent;
