import fetch from '@giro/shared-utils/fetch.util';

export type BodyType = {
  integration_id: string;
  reference_id: string;
  references?: string[];
  amount: number;
  details: Array<{
    reference: string;
    description: string;
    amount: number;
  }>;
  payment: {
    type: string;
    description: string;
    cards: Array<{
      charge_id: string;
      number: string;
      amount: number;
      amount_original: number;
      installments: number;
      exp_month: string;
      exp_year: string;
      security_code: string;
      holder: {
        name: string;
        document_number: string;
      };
    }>;
    address: {
      street: string;
      country: string;
      countryCode: string;
      city: string;
      postalCode: string;
      neighborhood: string;
      number: string;
      complement: string;
      state: string;
    };
  };
  metadata: {
    fingerprint: string;
  };
};

export default async function postApiAccountsService(body: BodyType) {
  return fetch('checkouts', {
    method: 'POST',
    ms: 'CHECKOUT',
    auth: true,
    body: JSON.stringify(body),
  });
}
