import { all } from 'redux-saga/effects';

import payment from './payment';

function* watch() {
  yield all([payment.saga.watch()]);
}

export default {
  watch,
};
