import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';

import Dialog from '@giro-app/factories/Dialog.factory';

import AppLayout from '@giro-app/layouts/App.layout';

import ROUTES from '@giro-app/constants/routes.constant';

import DebtsScreen from './screens/Debits.screen';
import PartnerScreen from './screens/DebtsPartner.screen';
import DebtsBilletScreen from './screens/DebtsBillet.screen';
import DebtsDetranScreen from './screens/DebtsDetran.screen';
import DebitsReferenceScreen from './screens/DebtsReference.screen';
import SelectorScreen from './screens/DetranSelector.screen';
import BilletSelector from './screens/BilletSelector.screen';
import DebitsCREAScreen from './screens/DebtsCrea.screen';

import list from './store/list';
import misc from '@giro-app/store/configs/misc';

export default function DebtsModule() {
  const dispatch = useDispatch();

  return (
    <AppLayout>
      <Switch>
        <Route exact path={ROUTES.DEBTS.ROOT} component={DebtsScreen} />
        <Route exact path={ROUTES.DEBTS.BILLET} component={DebtsBilletScreen} />
        <Route
          exact
          path={ROUTES.DEBTS.REFERENCE}
          component={DebitsReferenceScreen}
        />
        <Route exact path={ROUTES.DEBTS.DETRAN} component={DebtsDetranScreen} />
        <Route exact path={ROUTES.DEBTS.CREA_SP} component={DebitsCREAScreen} />
        <Route exact path={ROUTES.DEBTS.SELECTOR} component={SelectorScreen} />
        <Route
          exact
          path={ROUTES.DEBTS.SELECTOR_BILLET}
          component={BilletSelector}
        />
        <Route exact path={ROUTES.DEBTS.PARTNER} component={PartnerScreen} />
      </Switch>

      <Dialog variant="debtsSelectAllDebts" />
    </AppLayout>
  );
}
