import { Fragment, useEffect } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import BoxDetran from '@giro-app/modules/payment/components/Box/BoxDetran.component';

import useDialog from '@giro-app/hooks/useDialogRedux.hook';

import { listsPropTypes } from '@giro-app/hooks/useListRedux.hook';

import dialogPaymentDetran from '@giro-app/modules/payment/store/dialogs/dialogDetran';

const ListAppComponent = ({
  data,
  loading,
  handleFetch,
  isFetched,
  ...props
}: listsPropTypes) => {
  const { handleOpen } = useDialog(dialogPaymentDetran);

  useEffect(() => {
    if (!isFetched) {
      handleFetch();
    }
  }, []);

  return (
    <Fragment>
      {loading && (
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr 1fr"
          gap={6}
          p={4}
          {...props}
        >
          {[...Array(4)].map((_, index) => (
            <Skeleton
              width="100%"
              height={208}
              variant="rectangular"
              key={`${index}-skeleton-listapp`}
            />
          ))}
        </Box>
      )}

      {!loading && (
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr 1fr"
          gap={6}
          p={4}
          {...props}
        >
          {data?.map((a) => (
            <BoxDetran
              disabled={a.status !== 'ACTIVE'}
              img={a.logo_path}
              label={a.description}
              soon={a.status !== 'ACTIVE'}
              key={a.id}
              onClick={() => handleOpen(a)}
            />
          ))}
        </Box>
      )}
    </Fragment>
  );
};

export default ListAppComponent;
