import React from 'react';

import { useSelector } from 'react-redux';

import useDialog from '@giro-app/hooks/useDialogRedux.hook';

import DIALOGS from '@giro-app/constants/dialogs.constant';

type debtsDialogs = 'debtsSelectAllDebts';

type profileDialogs =
  | 'profileChangePassword'
  | 'profileSendReceipt'
  | 'profileOrderSearch';

type paymentDialogs =
  | 'paymentSendReceipt'
  | 'paymentErrorPayment'
  | 'paymentPreAuth'
  | 'paymentCardDetail'
  | 'paymentConfirmEmail';

type Props = {
  variant: profileDialogs | paymentDialogs | debtsDialogs;
  [key: string]: any;
};

const DialogFactory = ({ variant, ...restProps }: Props) => {
  const dialogProps = useDialog(DIALOGS.STORES[variant]);

  const props = {
    ...dialogProps,
    ...restProps,
  };

  return React.createElement(DIALOGS.COMPONENTS[variant], props);
};

export default DialogFactory;
