import { createReducer } from '@reduxjs/toolkit';

import initialState from './dialogChangePassword.initialState';
import { ACTION_TYPES } from './dialogChangePassword.constant';

const handleOpen = (state, action) => {
  state.visible = true;
};

const handleClose = (state) => {
  state.visible = false;
};

const handleReset = () => initialState;

const handleChangeVariant = (state, action) => {
  state.variant = action.variant;
};

export default createReducer(initialState, {
  [ACTION_TYPES.OPEN]: handleOpen,
  [ACTION_TYPES.CLOSE]: handleClose,
  [ACTION_TYPES.RESET]: handleReset,
  [ACTION_TYPES.CHANGE.VARIANT]: handleChangeVariant,
});
