import { useSelector } from 'react-redux';
import router from '@giro/shared-store/router';

export default function useCurrentUrlHook() {
  const pathname = useSelector(router.selector.selectPathname);

  const currentUrl = `${window.location.protocol}//${window.location.host}${pathname}`;

  return currentUrl;
}
