import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import auth from '@giro/shared-store/auth';

import BoxDebit from './Box/BoxDebit.component';
import BoxLoginComponent from './Box/BoxLogin.component';
import PaginationComponent from './Pagination.component';

const ListDebitsComponent = ({
  selected,
  results,
  metadata,
  loading,
  handleUpdateFilters,
  handleSelect,
  handleUnselect,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {loading && (
        <Fragment>
          {[...Array(results?.length || 4)].map((_, index) => (
            <Skeleton
              variant="rectangular"
              height={255}
              key={`skeleton-${index}-listdebits`}
            />
          ))}
        </Fragment>
      )}

      {!loading && (
        <Fragment>
          {metadata?.count > 0 &&
            results?.map((a, index) => (
              <Fragment key={`boxdebit-${index}`}>
                <BoxDebit
                  name={a.description}
                  registry={a.reference.split(';').slice(-2)}
                  document={a.plate}
                  type={a.type}
                  checked={!!selected?.find((s) => s.reference === a.reference)}
                  amount={a.amount}
                  onChangeCheck={(ev, value) =>
                    value ? handleSelect(a) : handleUnselect(a)
                  }
                />
              </Fragment>
            ))}

          {results?.length === 0 && (
            <Typography
              color="gray.400"
              sx={{
                gridColumn: 'span 2',
              }}
            >
              Nenhum débito encontrado para essa pesquisa
            </Typography>
          )}
        </Fragment>
      )}

      {metadata?.count > metadata?.limit && (
        <PaginationComponent
          metadata={metadata}
          loading={loading}
          handleUpdateFilters={handleUpdateFilters}
        />
      )}
    </Box>
  );
};

export default ListDebitsComponent;
