import { isEqual, isEmpty } from 'lodash';

import store from '.';

let nextState = {};
let prevState = {};

const subscribe = ({ getState, dispatch }) => {
  nextState = getState();

  prevState = nextState;
};

export default {
  subscribe,
};
