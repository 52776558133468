import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import getYear from 'date-fns/getYear';

import Logo from '@giro/shared-components/Logo.component';

const FooterComponent = (props) => {
  return (
    <Box
      component="footer"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={190}
      bgcolor="primary.main"
      {...props}
    >
      <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
        <Logo variant="new-sym" width="80" />
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            color: 'primary.main',
          }}
          gap={1}
        >
          <Typography align="center" variant="subtitle1" color="white">
            Giro Pagamentos e Tecnologia Ltda. <br />
            CNPJ: 23.041.219/0001-34
          </Typography>
          <Typography align="center" variant="subtitle1" color="white">
            Copyright © {getYear(new Date())} Giro Pagamentos.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FooterComponent;
