import { combineReducers } from 'redux';

import auth from '@giro/shared-store/auth';
import router from '@giro/shared-store/router';
import toaster from '@giro/shared-store/toaster';

import configs from './configs';
import apps from './apps';
import modules from './modules';

const appReducer = combineReducers({
  [toaster.name]: toaster.reducer,
  [apps.name]: apps.reducer,
  [configs.name]: configs.reducer,
  [modules.name]: modules.reducer,
  [auth.name]: auth.reducer,
  [router.name]: router.reducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
