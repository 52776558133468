import reducer from './apps.reducer';
import * as selector from './apps.selector';
import * as constant from './apps.constant';
import * as action from './apps.action';
import saga from './apps.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
};
