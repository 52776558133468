import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const InfosPaymentCardComponent = ({
  title,
  amount,
  installments,
  handleClick,
}) => {
  const amountFormatted = Number(amount).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

  const amountByInstallments = (amount / installments).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={3}
      border="1px solid transparent"
      borderRadius={1}
      onClick={() => handleClick()}
      sx={{
        borderColor: 'gray.200',
        padding: '20px 12px',
        cursor: 'pointer',
      }}
    >
      <Typography color="info.dark2" variant="body" fontWeight={600}>
        {title}
      </Typography>
      <Box display="flex" alignItems="center" gap={3}>
        <Box display="flex" flexDirection="column">
          <Typography color="info.dark2" variant="body" fontWeight={600}>
            {amountFormatted}
          </Typography>
          <Typography
            color="gray.400"
            variant="body"
            fontSize="12px"
            align="right"
          >
            {installments}x {amountByInstallments}
          </Typography>
        </Box>

        <ChevronRightIcon />
      </Box>
    </Box>
  );
};

export default InfosPaymentCardComponent;
