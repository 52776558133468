import { all } from 'redux-saga/effects';

import dialogsContactUs from './dialogContactUs';

function* watch() {
  yield all([dialogsContactUs.saga.watch()]);
}

export default {
  watch,
};
