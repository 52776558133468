import React from 'react';
import NumberFormat from 'react-number-format';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import useAuth from '@giro/shared-hooks/useAuth.hook';

import useCheckout from '@giro-app/modules/checkout/hooks/useCheckout.hook';

import { VERIFY_STEPS } from '../constants/verifySteps.constant';

const CheckoutVerifyComponent = () => {
  const {
    handleUpdatePhone,
    loading,
    verifyStep,
    handleConfirmAccount,
    handleConfirmTransaction,
  } = useCheckout();
  const currentUser = useAuth();

  const { phone_number } = currentUser?.user?.user || {};

  const [currentPhone, setCurrentPhone] = React.useState(phone_number);
  const [resend, setResend] = React.useState(false);
  const [token, setToken] = React.useState('');

  React.useEffect(() => {
    if (token.length === 6) {
      if (verifyStep === VERIFY_STEPS.CONFIRM_ACCOUNT) {
        onConfirmAccount();
      }

      // if (verifyStep === VERIFY_STEPS.CONFIRM_TRANSACTION) {
      //   onConfirmTransaction();
      // }
    }
  }, [verifyStep, token]);

  const handleTimeoutResend = () => {
    setResend(false);

    setTimeout(() => {
      setResend(true);
    }, 1000 * 60);
  };

  const handleSendSMS = () => {
    handleUpdatePhone(currentPhone);

    handleTimeoutResend();
  };

  const onConfirmAccount = () => handleConfirmAccount(token);

  const onConfirmTransaction = () => handleConfirmTransaction(token);

  const maskPhone = (phone) =>
    String(phone).replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');

  return (
    <>
      {verifyStep === VERIFY_STEPS.PHONE && (
        <Box
          display="grid"
          gridTemplateColumns="1fr"
          gap={4}
          alignContent="center"
          height="100%"
          maxHeight={519}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography align="center" variant="h5" fontWeight="bold">
              Precisamos validar seu celular
            </Typography>
            <Typography align="center">
              Confirme ou atualize o seu número abaixo, ele será utilizado para
              enviarmos um código de confirmação.
            </Typography>
          </Box>

          <Box width="100%" maxWidth={300} justifySelf="center">
            <NumberFormat
              customInput={TextField}
              fullWidth
              label="Telefone"
              format="(##) #####-####"
              disabled={loading}
              defaultValue={phone_number}
              onValueChange={(values) => setCurrentPhone(values.value)}
            />
          </Box>

          <Box justifySelf="center">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disabled={String(currentPhone).length < 11 || loading}
              onClick={handleSendSMS}
            >
              {!loading && 'Continuar'}

              {loading && <CircularProgress color="inherit" size="inherit" />}
            </Button>
          </Box>
        </Box>
      )}

      {verifyStep === VERIFY_STEPS.CONFIRM_ACCOUNT && (
        <Box
          display="grid"
          gridTemplateColumns="1fr"
          gap={4}
          alignContent="center"
          height="100%"
          maxHeight={519}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography align="center" variant="h5" fontWeight="bold">
              Verificação de celular
            </Typography>
            <Typography align="center">
              Digite o código de 6 dígitos enviado para{' '}
              {maskPhone(currentPhone)}
            </Typography>
          </Box>

          <Box
            width="100%"
            maxWidth={300}
            justifySelf="center"
            display="flex"
            flexDirection="column"
          >
            <TextField
              fullWidth
              label="Código"
              onChange={({ target: { value } }) => setToken(value)}
              disabled={loading}
              inputProps={{
                maxlength: 6,
              }}
            />
            <Button
              css={{ alignSelf: 'flex-start' }}
              onClick={handleSendSMS}
              disabled={!resend || loading}
            >
              Reenviar código
            </Button>
          </Box>

          <Box justifySelf="center">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disabled={String(token).length < 6 || loading}
              onClick={onConfirmAccount}
            >
              {!loading && 'Continuar'}

              {loading && <CircularProgress color="inherit" size="inherit" />}
            </Button>
          </Box>
        </Box>
      )}

      {verifyStep === VERIFY_STEPS.CONFIRM_TRANSACTION && (
        <Box
          display="grid"
          gridTemplateColumns="1fr"
          gap={4}
          alignContent="center"
          height="100%"
          maxHeight={519}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography align="center" variant="h5" fontWeight="bold">
              Verificação de segurança
            </Typography>
            <Typography align="center">
              Digite o código de 6 dígitos enviado para{' '}
              {maskPhone(currentPhone)}
            </Typography>
          </Box>

          <Box
            width="100%"
            maxWidth={300}
            justifySelf="center"
            display="flex"
            flexDirection="column"
          >
            <TextField
              fullWidth
              label="Código"
              disabled={loading}
              onChange={({ target: { value } }) => setToken(value)}
            />
          </Box>

          <Box justifySelf="center">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={onConfirmTransaction}
              disabled={String(token).length < 6 || loading}
            >
              {!loading && 'Continuar'}

              {loading && <CircularProgress color="inherit" size="inherit" />}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CheckoutVerifyComponent;
