import fetch from '@giro/shared-utils/fetch.util';

import { BodyType } from './postApiCheckouts.service';

export default async function postApiReservesService(body: BodyType) {
  return fetch('reserves', {
    method: 'POST',
    ms: 'CHECKOUT',
    body: JSON.stringify(body),
  });
}
