import { all } from 'redux-saga/effects';

import history from './history';
import payment from './payment';
import user from './user';

function* watch() {
  yield all([history.saga.watch(), payment.saga.watch(), user.saga.watch()]);
}

export default {
  watch,
};
