import React from 'react';

import DebtBilletComponent from '../components/DebtBillet.component';
import DebtReferenceComponent from '../components/DebtReference.component';

const DebitsReferenceScreen = () => {
  return (
    <>
      <DebtReferenceComponent />
    </>
  );
};

export default DebitsReferenceScreen;
