import reducer from './dialogSendReceipt.reducer';
import * as action from './dialogSendReceipt.action';
import * as selector from './dialogSendReceipt.selector';
import * as constant from './dialogSendReceipt.constant';
import initialState from './dialogSendReceipt.initialState';
import subscriber from './dialogSendReceipt.subscriber';
import saga from './dialogSendReceipt.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
