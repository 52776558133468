import fetch from '@giro/shared-utils/fetch.util';

type BodyType = {
  name: string;
  phone_number: string;
};

export default async function patchApiAccountsIdService(id, body: BodyType) {
  return fetch(`accounts/${id}`, {
    method: 'PATCH',
    ms: 'USERS',
    auth: true,
    body: JSON.stringify(body),
  });
}
