export const NAME = 'list';

const CONTEXT = 'MODULE::DEBITS::LIST';

const SERVICE_POST_TOKEN_PARTNER = `${CONTEXT}::SERVICE::POST::TOKEN_PARTNER`;

const UPDATE_FILTERS = `${CONTEXT}::UPDATE::FILTERS`;
const UPDATE_SELECTED = `${CONTEXT}::UPDATE::SELECTED`;
const UPDATE_PARTNER = `${CONTEXT}::UPDATE::PARTNER`;
const UPDATE_INSTALLMENT = `${CONTEXT}::UPDATE::INSTALLMENT`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const SELECT = `${CONTEXT}::SELECT`;
const SELECT_ALL = `${CONTEXT}::SELECT::ALL`;

const UNSELECT = `${CONTEXT}::UNSELECT`;
const UNSELECT_ALL = `${CONTEXT}::UNSELECT::ALL`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;
const RESET_FILTERS = `${CONTEXT}::RESET::FILTERS`;
const RESET_SELECTED = `${CONTEXT}::RESET::SELECTED`;
const RESET_LOCAL_STORAGE = `${CONTEXT}::RESET::LOCAL_STORAGE`;
const RESET_PARTNER = `${CONTEXT}::RESET::PARTNER`;

const PAY_DETRAN = `${CONTEXT}::PAY::DETRAN`;
const PAY_BILLET = `${CONTEXT}::PAY::BILLET`;
const PAY_PARTNER = `${CONTEXT}::PAY::PARTNER`;

const SERVICE = {
  POST_TOKEN_PARTNER: SERVICE_POST_TOKEN_PARTNER,
};

const PAY = {
  DETRAN: PAY_DETRAN,
  BILLET: PAY_BILLET,
  PARTNER: PAY_PARTNER,
};

const RESET = {
  STATE: RESET_STATE,
  FILTERS: RESET_FILTERS,
  SELECTED: RESET_SELECTED,
  RESET_LOCAL_STORAGE: RESET_LOCAL_STORAGE,
  PARTNER: RESET_PARTNER,
};

const FETCH = {
  START: FETCH_START,
  END: FETCH_END,
  ERROR: FETCH_ERROR,
};

const UPDATE = {
  FILTERS: UPDATE_FILTERS,
  SELECTED: UPDATE_SELECTED,
  PARTNER: UPDATE_PARTNER,
  INSTALLMENT: UPDATE_INSTALLMENT,
};

export const ACTION_TYPES = {
  RESET,
  SELECT,
  SELECT_ALL,
  UNSELECT,
  UNSELECT_ALL,
  FETCH,
  UPDATE,
  PAY,
  SERVICE,
};
