import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const BoxDetranComponent = ({ soon, label, img, disabled, onClick }) => {
  return (
    <Box position="relative">
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="white"
        p={3}
        boxShadow="0px 12px 24px rgba(0, 33, 82, 0.1)"
        borderRadius={2}
        alignItems="center"
        gap={2}
      >
        <img src={img} css={{ height: 64 }} />
        <Typography variant="body1" color="primary.main" align="center">
          {label}
        </Typography>
        <Button
          onClick={onClick}
          variant="contained"
          fullWidth
          sx={{ zIndex: 1 }}
          disabled={disabled}
          color="secondary"
        >
          Selecionar
        </Button>
      </Box>
      {soon && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          flexDirection="column"
          mt={-1.5}
        >
          <Box
            alignSelf="center"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={78}
            height={26}
            borderRadius={0.5}
            sx={{
              backgroundColor: 'info.main',
            }}
          >
            <Typography sx={{ color: 'white' }} variant="body_sm">
              em breve
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BoxDetranComponent;
