import { all } from 'redux-saga/effects';

import drawerMenuMobile from './drawerMenuMobile';

function* watch() {
  yield all([drawerMenuMobile.saga.watch()]);
}

export default {
  watch,
};
