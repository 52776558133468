import DialogChangePasswordContainer from '@giro-app/modules/profile/containers/DialogChangePassword.container';

import DialogSendReceiptProfileComponent from '@giro-app/modules/profile/components/Dialog/DialogSendReceipt.component';

import DialogSendReceiptComponent from '@giro-app/modules/checkout/components/Dialog/DialogSendReceipt.component';
import DialogErrorPaymentComponent from '@giro-app/modules/checkout/components/Dialog/DialogErrorPayment.component';
import DialogCardDetailComponent from '@giro-app/modules/checkout/components/Dialog/DialogCardDetail.component';
import DialogPreAuthComponent from '@giro-app/modules/checkout/components/Dialog/DialogPreAuth.component';
import DialogConfirmEmailComponent from '@giro-app/modules/checkout/components/Dialog/DialogConfirmEmail.component';

import DialogSelectAllDebitsComponent from '@giro-app/modules/debts/components/Dialog/DialogSelectAllDebits.component';

import dialogSendProfileReceipt from '@giro-app/modules/profile/store/dialogSendReceipt';
import dialogChangePassword from '@giro-app/modules/profile/store/dialogChangePassword';

import dialogSendReceipt from '@giro-app/modules/checkout/store/dialogs/dialogSendReceipt';
import dialogErrorPayment from '@giro-app/modules/checkout/store/dialogs/dialogErrorPayment';
import dialogCardDetail from '@giro-app/modules/checkout/store/dialogs/dialogCardDetail';
import dialogPreAuth from '@giro-app/modules/checkout/store/dialogs/dialogPreAuth';
import dialogConfirmEmail from '@giro-app/modules/checkout/store/dialogs/dialogConfirmEmail';

import dialogSelectAllDebts from '@giro-app/modules/debts/store/dialogs/dialogSelectAllDebits';

// DEBTS
const DIALOGS_DEBTS = {
  selectAllDebits: 'debtsSelectAllDebts',
};

const DIALOGS_COMPONENTS_DEBTS = {
  [DIALOGS_DEBTS.selectAllDebits]: DialogSelectAllDebitsComponent,
};

const DIALOGS_STORES_DEBTS = {
  [DIALOGS_DEBTS.selectAllDebits]: dialogSelectAllDebts,
};

// PAYMENT
const DIALOGS_PAYMENT = {
  detran: 'paymentDetran',
  sendReceipt: 'paymentSendReceipt',
  errorPayment: 'paymentErrorPayment',
  cardDetail: 'paymentCardDetail',
  preAuth: 'paymentPreAuth',
  confirmEmail: 'paymentConfirmEmail',
};

const DIALOGS_COMPONENTS_PAYMENT = {
  [DIALOGS_PAYMENT.sendReceipt]: DialogSendReceiptComponent,
  [DIALOGS_PAYMENT.errorPayment]: DialogErrorPaymentComponent,
  [DIALOGS_PAYMENT.cardDetail]: DialogCardDetailComponent,
  [DIALOGS_PAYMENT.preAuth]: DialogPreAuthComponent,
  [DIALOGS_PAYMENT.confirmEmail]: DialogConfirmEmailComponent,
};

const DIALOGS_STORES_PAYMENT = {
  [DIALOGS_PAYMENT.sendReceipt]: dialogSendReceipt,
  [DIALOGS_PAYMENT.errorPayment]: dialogErrorPayment,
  [DIALOGS_PAYMENT.cardDetail]: dialogCardDetail,
  [DIALOGS_PAYMENT.preAuth]: dialogPreAuth,
  [DIALOGS_PAYMENT.confirmEmail]: dialogConfirmEmail,
};

// PROFILE
const DIALOGS_PROFILE = {
  changePassword: 'profileChangePassword',
  profileSendReceipt: 'profileSendReceipt',
};

const DIALOGS_COMPONENTS_PROFILE = {
  [DIALOGS_PROFILE.changePassword]: DialogChangePasswordContainer,
  [DIALOGS_PROFILE.profileSendReceipt]: DialogSendReceiptProfileComponent,
};

const DIALOGS_STORES_PROFILE = {
  [DIALOGS_PROFILE.changePassword]: dialogChangePassword,
  [DIALOGS_PROFILE.profileSendReceipt]: dialogSendProfileReceipt,
};

// -----

const DIALOGS_COMPONENTS = {
  ...DIALOGS_COMPONENTS_PAYMENT,
  ...DIALOGS_COMPONENTS_PROFILE,
  ...DIALOGS_COMPONENTS_DEBTS,
};

const DIALOGS_STORES = {
  ...DIALOGS_STORES_PAYMENT,
  ...DIALOGS_STORES_PROFILE,
  ...DIALOGS_STORES_DEBTS,
};

const DIALOGS_CONSTANTS = {
  PAYMENT: DIALOGS_PAYMENT,
  PROFILE: DIALOGS_PROFILE,
  DEBTS: DIALOGS_DEBTS,
};

const DIALOGS = {
  CONSTANTS: DIALOGS_CONSTANTS,
  STORES: DIALOGS_STORES,
  COMPONENTS: DIALOGS_COMPONENTS,
};

export default DIALOGS;
