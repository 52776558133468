import { isEqual } from 'lodash';
import { takeLatest, put, select } from 'redux-saga/effects';

import dialogData from '.';

function* watch() {}

export default {
  watch,
};
