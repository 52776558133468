import qs from 'qs';
import { PARAMS_KEY, PATHNAME_KEY } from '@giro-app/configs/misc.config';

export function deleteRedirectInLocalStorage() {
  localStorage.removeItem(PARAMS_KEY);
  localStorage.removeItem(PATHNAME_KEY);

  // window.location.reload();
}

export function getRedirectInLocalStorage() {
  const params = localStorage?.getItem(PARAMS_KEY);
  const pathname = localStorage?.getItem(PATHNAME_KEY);

  if (!params) {
    return [];
  }

  return [pathname, JSON.parse(params)];
}

export function saveRedirectInLocalStorage() {
  const params = window.location?.search.substring(1);
  const pathname = window.location.pathname;

  const paramsParsed = qs.parse(params);

  localStorage.setItem(PARAMS_KEY, JSON.stringify(paramsParsed));
  localStorage.setItem(PATHNAME_KEY, JSON.stringify(pathname));
}
