import { combineReducers } from 'redux';

import payment from '@giro-app/modules/payment/store';
import profile from '@giro-app/modules/profile/store';
import receipts from '@giro-app/modules/receipts/store';
import debts from '@giro-app/modules/debts/store';
import checkout from '@giro-app/modules/checkout/store';

const reducer = combineReducers({
  [payment.name]: payment.reducer,
  [profile.name]: profile.reducer,
  [debts.name]: debts.reducer,
  [checkout.name]: checkout.reducer,
  [receipts.name]: receipts.reducer,
});

export default reducer;
