import { ACTION_TYPES } from './user.constant';

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchUserStart = (values, { toaster = true } = {}) => ({
  type: ACTION_TYPES.FETCH.USER_START,
  values,
  toaster,
});

export const fetchFinish = (payload) => ({
  type: ACTION_TYPES.FETCH.END,
  payload,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET,
});
