import { flow } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { Formik, useFormikContext, Field } from 'formik';
import {
  Box,
  TextField as TextFieldMaterial,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch } from 'react-redux';
import { TextField } from 'formik-mui';
import Button from '@mui/lab/LoadingButton';
import * as yup from 'yup';
import { v4 } from 'uuid';
import NumberFormat from 'react-number-format';

import useDialog from '@giro-app/hooks/useDialogRedux.hook';
import useList from '@giro-app/hooks/useListRedux.hook';

import theme from '@giro/client-app/src/constants/theme.constant';

import messages from '@giro/shared-constants/messagesSchema.constant';

import dialogChangePassword from '../../store/dialogChangePassword';

import user from '../../store/user';
import auth from '@giro/shared-store/auth';

const validationSchema = yup.object({
  name: yup.string().required(messages.REQUIRED),
  phone_number: yup.string().required(messages.REQUIRED).nullable(),
});

const FormUpdatePersonalData = ({ loading, showEditPassword }) => {
  const dispatch = useDispatch();

  const { handleOpen } = useDialog(dialogChangePassword);

  const { submitForm } = useFormikContext();

  const dispatchRedux = {
    UNSET_TOKEN: flow(auth.action.unsetToken, dispatch),
  };

  return (
    <Box display="grid" gap={5} gridTemplateColumns="1fr">
      <Box
        display="grid"
        gridTemplateColumns={{ md: '1fr 1fr', xs: '1fr' }}
        gap={4}
      >
        <Box display="flex" flexDirection="column" gap="20px">
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso_site.rosa" variant="trasso_site_caption">
              NOME
            </Typography>
            <Field name="name" component={TextField} fullWidth />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso_site.rosa" variant="trasso_site_caption">
              EMAIL
            </Typography>
            <Field
              name="email"
              component={TextField}
              fullWidth
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {}}
                      edge="start"
                      sx={{
                        backgroundColor: 'trasso_site.roxo',
                        '&:hover': { backgroundColor: 'trasso_site.rosa' },
                        borderRadius: '10px',
                      }}
                    >
                      <EditIcon
                        style={{
                          color: 'white',
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso_site.rosa" variant="trasso_site_caption">
              TELEFONE
            </Typography>
            <Field name="phone_number">
              {({
                field,
                form: { setFieldValue, setFieldTouched },
                meta: { error, touched },
              }) => (
                <NumberFormat
                  customInput={TextFieldMaterial}
                  fullWidth
                  format="(##) #####-####"
                  value={field.value}
                  error={touched && !!error}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {}}
                          edge="start"
                          sx={{
                            backgroundColor: 'trasso_site.roxo',
                            '&:hover': { backgroundColor: 'trasso_site.rosa' },
                            borderRadius: '10px',
                          }}
                        >
                          <EditIcon
                            style={{
                              color: 'white',
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  helperText={touched && !!error && error}
                  onBlur={() => {
                    setFieldTouched(field.name, true);
                  }}
                  onValueChange={(values: any) =>
                    setFieldValue(field.name, values.value)
                  }
                />
              )}
            </Field>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="20px">
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso_site.rosa" variant="trasso_site_caption">
              CEP
            </Typography>
            <Field name="cep" component={TextField} fullWidth />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="trasso_site.rosa" variant="trasso_site_caption">
              ENDEREÇO
            </Typography>
            <Field name="address" component={TextField} fullWidth />
          </Box>

          <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography
                color="trasso_site.rosa"
                variant="trasso_site_caption"
              >
                NÚMERO
              </Typography>
              <Field name="number" component={TextField} fullWidth />
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography
                color="trasso_site.rosa"
                variant="trasso_site_caption"
              >
                COMPLEMENTO
              </Typography>
              <Field name="complement" component={TextField} fullWidth />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {showEditPassword && (
          <Button
            variant="text"
            loading={loading}
            disabled={loading}
            sx={{ color: 'info.main', fontWeight: 500 }}
            onClick={() => handleOpen()}
          >
            Trocar senha
          </Button>
        )}
        <Button
          variant="contained"
          onClick={submitForm}
          loading={loading}
          disabled={loading}
          sx={{
            backgroundColor: 'success.main',
            fontSize: theme.typography.body1.fontSize,
          }}
        >
          Salvar
        </Button>
        <Button
          onClick={() => dispatchRedux.UNSET_TOKEN()}
          sx={{
            fontSize: theme.typography.body1.fontSize,
          }}
        >
          Sair
        </Button>
      </Box>
    </Box>
  );
};

const FormUpdatePersonalDataComponent = () => {
  const [showEditPassword, setState] = useState(false);

  const action = useRef('');

  const dispatch = useDispatch();
  const { handleFetch, loading, error } = useList(user);

  const dispatchRedux = {
    FETCH_USER_START: flow(user.action.fetchUserStart, dispatch),
  };

  const [key, setKey] = useState(v4());

  const { data } = useList(user);

  const handleSubmit = async (values) => {
    const newValues = { ...values };

    delete newValues.email;

    dispatchRedux.FETCH_USER_START(newValues);

    action.current = 'update';

    return true;
  };

  useEffect(() => {
    setKey(v4());

    const { origin } = data || {};

    if (origin === 'Default') {
      setState(true);
    } else {
      setState(false);
    }
  }, [data]);

  useEffect(() => {
    if (loading === null) {
      handleFetch();
    }
  }, []);

  useEffect(() => {
    const [hasError] = error;

    if (hasError === false && action.current === 'update') {
      action.current = '';
    }
  }, [error]);

  return (
    <Formik
      key={key}
      enableReinitialize
      initialValues={{
        name: data?.name,
        phone_number: data?.phone_number,
        email: data?.email,
        cep: '',
        address: '',
        number: '',
        complement: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <FormUpdatePersonalData
        loading={loading}
        showEditPassword={showEditPassword}
      />
    </Formik>
  );
};

export default FormUpdatePersonalDataComponent;
