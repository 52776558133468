import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

export type listsPropTypes = {
  handleFetch: any;
  handleUpdateFilters: any;
  handleResetState: any;
  handleResetFilters: any;
  results: any;
  metadata: any;
  data: any;
  loading: boolean;
  error: any;
  filters: any;
  isFetched: boolean;
};

export default function useListHook(store, configs: any = {}): listsPropTypes {
  const { scroll } = configs;

  const dispatch = useDispatch();

  const checkExistsAction = (action) => store.action?.[action];
  const checkExistsSelector = (selector) => store.selector?.[selector];

  const dispatchRedux = {
    FETCH_START: flow(store.action.fetchStart, dispatch),
    RESET_STATE: flow(store.action.resetState, dispatch),
  };

  const dispatchOptionalRedux = {
    UPDATE_FILTERS:
      checkExistsAction('updateFilters') &&
      flow(store.action.updateFilters, dispatch),
    RESET_FILTERS:
      checkExistsAction('resetFilters') &&
      flow(store.action.resetFilters, dispatch),
  };

  const selectorRedux = {
    data: useSelector(store.selector.selectData),
    loading: useSelector(store.selector.selectLoading),
    error: useSelector(store.selector.selectError),
  };

  const selectorOptionalRedux = {
    filters:
      checkExistsSelector('selectFilters') &&
      useSelector(store.selector.selectFilters),
    results:
      checkExistsSelector('selectDataResults') &&
      useSelector(store.selector.selectDataResults),
    metadata:
      checkExistsSelector('selectDataMetadata') &&
      useSelector(store.selector.selectDataMetadata),
  };

  React.useEffect(() => {
    if (!scroll) {
      return undefined;
    }

    if (selectorRedux.loading === false) {
      if (scroll === true) {
        window.scrollTo({ behavior: 'smooth', top: 0 });
      }

      if (typeof scroll === 'string') {
        (document as any)
          .querySelector(scroll)
          .scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [scroll, selectorRedux.loading]);

  const props: listsPropTypes = {
    handleFetch: dispatchRedux.FETCH_START,
    handleResetState: dispatchRedux.RESET_STATE,
    handleResetFilters: dispatchOptionalRedux.RESET_FILTERS,
    handleUpdateFilters: dispatchOptionalRedux.UPDATE_FILTERS,
    results: selectorOptionalRedux.results,
    metadata: selectorOptionalRedux.metadata,
    data: selectorRedux.data,
    loading: selectorRedux.loading as boolean,
    error: selectorRedux.error,
    filters: selectorOptionalRedux.filters,
    isFetched: selectorRedux.loading !== null,
  };

  return props;
}
