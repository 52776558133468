import { flow } from 'lodash';
import { NAME } from './checkout.constant';

import { selectState as selectStateCheckout } from '../checkout.selector';

const selectStateKey = (state) => state[NAME];
const selectLoadingKey = (state) => state.loading;
const selectErrorKey = (state) => state.error;
const selectDataKey = (state) => state.data;
const selectSessionIdKey = (state) => state.sessionId;
const selectCurrentStepKey = (state) => state.currentStep;
const selectPaymentTypeKey = (state) => state.paymentType;
const selectVerifyStepKey = (state) => state.verifyStep;
const selectDebitKey = (state) => state.debit;
const selectAppKey = (state) => state.app;
const selectFormDataKey = (state) => state.formData;
const selectFormDataAntiFraudKey = (state: any) => state.antiFraud;
const selectFormDataPaymentKey = (state: any) => state.payment;
const selectFormDataVerifyKey = (state: any) => state.verify;

export const selectState = flow(selectStateCheckout, selectStateKey);
export const selectError = flow(selectState, selectErrorKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectData = flow(selectState, selectDataKey);
export const selectSessionId = flow(selectState, selectSessionIdKey);
export const selectCurrentStep = flow(selectState, selectCurrentStepKey);
export const selectApp = flow(selectState, selectAppKey);
export const selectDebit = flow(selectState, selectDebitKey);
export const selectVerifyStep = flow(selectState, selectVerifyStepKey);
export const selectPaymentType = flow(selectState, selectPaymentTypeKey);
export const selectFormData = flow(selectState, selectFormDataKey);
export const selectFormDataAntiFraud = flow(
  selectFormData,
  selectFormDataAntiFraudKey
);
export const selectFormDataPayment = flow(
  selectFormData,
  selectFormDataPaymentKey
);
export const selectFormDataVerify = flow(
  selectFormData,
  selectFormDataVerifyKey
);
