import { all } from 'redux-saga/effects';

import dialogCardDetail from './dialogCardDetail';
import dialogErrorPayment from './dialogErrorPayment';
import dialogSendReceipt from './dialogSendReceipt';
import dialogPreAuth from './dialogPreAuth';
import dialogConfirmEmail from './dialogConfirmEmail';

function* watch() {
  yield all([
    dialogCardDetail.saga.watch(),
    dialogErrorPayment.saga.watch(),
    dialogConfirmEmail.saga.watch(),
    dialogPreAuth.saga.watch(),
    dialogSendReceipt.saga.watch(),
  ]);
}

export default {
  watch,
};
