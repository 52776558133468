import fetch from '@giro/shared-utils/fetch.util';

export default async function postApiCheckoutsReferenceIdReceiptsService(
  reference_id,
  email
) {
  const url = `checkouts/${reference_id}/receipts`;

  return fetch(url, {
    method: 'POST',
    auth: true,
    ms: 'CHECKOUT',
    body: JSON.stringify({ email }),
  });
}
