import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import CloseIcon from '@mui/icons-material/Close';

import theme from '@giro/client-app/src/constants/theme.constant';

import error from '../../assets/error.png';

const DialogErrorPaymentComponent = ({ handleClose, visible, payload }) => {
  return (
    <Dialog onClose={handleClose} open={visible} maxWidth={'xs'}>
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <Box position="absolute" right={8} top={8}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box height={52} />
          <Box display="flex" flexDirection="column" gap={3}>
            <Box alignSelf="center">
              <img src={error} />
            </Box>
            <Typography variant="h3" color="black" align="center">
              Não foi possível realizar o pagamento
            </Typography>
            <Typography variant="body2" color="gray.400" align="center">
              {payload?.message}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogErrorPaymentComponent;
