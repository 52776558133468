import * as constant from './dialogChangePassword.constant';
import reducer from './dialogChangePassword.reducer';
import initialState from './dialogChangePassword.initialState';
import * as action from './dialogChangePassword.action';
import * as selector from './dialogChangePassword.selector';
import subscriber from './dialogChangePassword.subscriber';

export default {
  reducer,
  action,
  constant,
  initialState,
  name: constant.NAME,
  selector,
  subscriber,
};
