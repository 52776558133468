import * as constant from './drawerOrderSearch.constant';
import reducer from './drawerOrderSearch.reducer';
import initialState from './drawerOrderSearch.initialState';
import * as action from './drawerOrderSearch.action';
import * as selector from './drawerOrderSearch.selector';

export default {
  reducer,
  action,
  constant,
  initialState,
  name: constant.NAME,
  selector,
};
