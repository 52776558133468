import { Switch, Route } from 'react-router-dom';

import AppLayout from '@giro-app/layouts/App.layout';

import HomeScreen from '@giro-app/modules/home/screens/Home.screen';

import ROUTES from '@giro-app/constants/routes.constant';

export default function Routes() {
  return (
    <AppLayout>
      <Switch>
        <Route exact path={ROUTES.HOME} component={HomeScreen} />
      </Switch>
    </AppLayout>
  );
}
