import { all } from 'redux-saga/effects';

import drawers from './drawers';
import dialogs from './drawers';

function* watch() {
  yield all([drawers.saga.watch(), dialogs.saga.watch()]);
}

export default {
  watch,
};
