import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'lodash';

import apps from '@giro-app/store/apps';
import CheckoutLayout from '@giro-app/layouts/Checkouthome.layout';
import ROUTES from '@giro-app/constants/routes.constant';

import auth from '@giro/shared-store/auth';

import list from '@giro-app/modules/debts/store/list';
import misc from '@giro-app/store/configs/misc';

import BoxBanner from '../components/BoxBanner.component';
import BoxDescription from '../components/BoxDescription.component';

import bg from '../assets/bg.png';
import car from '../assets/car.png';
import card from '../assets/card.png';
import placa from '../assets/placa.png';
import chat from '../assets/chat.png';
import cogs from '../assets/cogs.png';
import gift from '../assets/gift.png';
import money from '../assets/money.png';

const HomeScreen = () => {
  const dispatch = useDispatch();

  const [hasBillet, setHasBillet] = React.useState(false);

  const dispatchDebtsListRedux = {
    payDetran: flow(list.action.payDetran, dispatch),
    payBillet: flow(list.action.payBillet, dispatch),
    updatePartner: flow(list.action.updatePartner, dispatch),
    updateRemoveLogin: flow(misc.action.updateRemoveLogin, dispatch),
  };

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
    isPartner: useSelector(list.selector.selectIsPartner),
  };

  React.useEffect(() => {
    const shouldHaveApps = selectorAppsRedux?.data?.length > 0;
    const shouldHaveBillet =
      shouldHaveApps &&
      !!selectorAppsRedux.data.find((a) => a.filter_type === 3);

    setHasBillet(shouldHaveBillet);
  }, [selectorAppsRedux.data]);

  React.useEffect(() => {
    if (selectorAppsRedux.isPartner === true) {
      dispatchDebtsListRedux.updateRemoveLogin(false);
      dispatchDebtsListRedux.updatePartner(false);
      dispatch(auth.action.unsetToken());
    }
  }, [selectorAppsRedux.isPartner]);

  const feats = [
    {
      urlImage: '/assets/debitos.png',
      title: 'Débitos Veiculares',
      url: ROUTES.DEBTS.SELECTOR,
    },
    {
      urlImage: '/assets/contas.png',
      title: 'Contas e Boletos',
      url: ROUTES.DEBTS.SELECTOR_BILLET,
    },
  ];

  return (
    <Box
      display="grid"
      gridTemplateColumns={'1fr'}
      overflow="hidden"
      minHeight="calc(100vh - 412px)"
    >
      <CheckoutLayout feats={feats} />
    </Box>
  );
};

export default HomeScreen;
