import { combineReducers } from 'redux';

import drawerOrder from './drawerOrder';
import drawerOrderSearch from './drawerOrderSearch';
import dialogChangePassword from './dialogChangePassword';
import dialogSendReceipt from './dialogSendReceipt';
import history from './history';
import payment from './payment';
import user from './user';

const reducer = combineReducers({
  [drawerOrder.name]: drawerOrder.reducer,
  [drawerOrderSearch.name]: drawerOrderSearch.reducer,
  [history.name]: history.reducer,
  [user.name]: user.reducer,
  [payment.name]: payment.reducer,
  [dialogChangePassword.name]: dialogChangePassword.reducer,
  [dialogSendReceipt.name]: dialogSendReceipt.reducer,
});

export default reducer;
