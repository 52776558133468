import React from 'react';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';

import DebtPartnerComponent from '../components/DebtPartner.component';

import list from '../store/list';
import misc from '@giro-app/store/configs/misc';

const PartnerScreen = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    updatePartner: flow(list.action.updatePartner, dispatch),
    updateRemoveLogin: flow(misc.action.updateRemoveLogin, dispatch),
  };

  React.useEffect(() => {
    dispatchRedux.updatePartner(true);
    dispatchRedux.updateRemoveLogin(true);
    window.sessionStorage.setItem('isPartner', 'true');
  }, []);

  return <DebtPartnerComponent />;
};

export default PartnerScreen;
