// import dialog from './dialogs';
import checkout from './checkout';

const subscribe = (store) => {
  // dialog.subscriber.subscribe(store);
  checkout.subscriber.subscribe(store);
};

export default {
  subscribe,
};
