import fetch from '@giro/shared-utils/fetch.util';

type BodyType = {
  type: 'email' | 'phone';
  email: string;
  phone: string;
};

export default async function postApiOnboardingsConfirmationsService(
  body: BodyType
) {
  return fetch(`onboardings/confirmations`, {
    method: 'POST',
    ms: 'ONBOARDING',
    auth: true,
    body: JSON.stringify(body),
  });
}
