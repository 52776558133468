import ListAppsComponent from '@giro-app/components/ListApps.component';
import ListHistoryOrderComponent from '@giro-app/modules/orders/components/List/ListHistoryOrder.component';

import ListDebitsContainer from '@giro-app/modules/debts/containers/ListDebits.container';

import apps from '@giro-app/store/apps';

import debits from '@giro-app/modules/debts/store/list';

import history from '@giro-app/modules/profile/store/history';

const LISTS_ROOT = {
  APPS: 'apps',
};

const LISTS_COMPONENTS_ROOT = {
  [LISTS_ROOT.APPS]: ListAppsComponent,
};

const LISTS_STORES_ROOT = {
  [LISTS_ROOT.APPS]: apps,
};

const LISTS_PAYMENT = {
  DEBITS: 'paymentDebits',
};

const LISTS_COMPONENTS_PAYMENT = {
  [LISTS_PAYMENT.DEBITS]: ListDebitsContainer,
};

const LISTS_STORES_PAYMENT = {
  [LISTS_PAYMENT.DEBITS]: debits,
};

const LISTS_PROFILE = {
  HISTORY: 'profileHistory',
};

const LISTS_COMPONENTS_PROFILE = {
  [LISTS_PROFILE.HISTORY]: ListHistoryOrderComponent,
};

const LISTS_STORES_PROFILE = {
  [LISTS_PROFILE.HISTORY]: history,
};

const LISTS_CONSTANTS = {
  PAYMENT: LISTS_PAYMENT,
  PROFILE: LISTS_PROFILE,
  ROOT: LISTS_ROOT,
};

const LISTS_COMPONENTS = {
  ...LISTS_COMPONENTS_ROOT,
  ...LISTS_COMPONENTS_PAYMENT,
  ...LISTS_COMPONENTS_PROFILE,
};

const LISTS_STORES = {
  ...LISTS_STORES_ROOT,
  ...LISTS_STORES_PAYMENT,
  ...LISTS_STORES_PROFILE,
};

const LISTS = {
  CONSTANTS: LISTS_CONSTANTS,
  STORES: LISTS_STORES,
  COMPONENTS: LISTS_COMPONENTS,
};

export default LISTS;
