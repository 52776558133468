import React from 'react';

import DebtCREAComponent from '../components/DebtCrea.component';

const DebitsCREAScreen = () => {
  return (
    <>
      <DebtCREAComponent />
    </>
  );
};

export default DebitsCREAScreen;
