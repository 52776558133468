import reducer from './misc.reducer';
import * as action from './misc.action';
import * as selector from './misc.selector';
import * as constant from './misc.constant';
import initialState from './misc.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  name: constant.NAME,
};
