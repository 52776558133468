export const NAME = 'drawerOrderSearch';

const CONTEXT = 'MODULE::PROFILE::DRAWER_ORDER_SEARCH';

const RESET = `${CONTEXT}::RESET`;
const OPEN = `${CONTEXT}::OPEN`;
const CLOSE = `${CONTEXT}::CLOSE`;
const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_FINISH = `${CONTEXT}::FETCH::FINISH`;

const FETCH = {
  START: FETCH_START,
  FINISH: FETCH_FINISH,
};

export const ACTION_TYPES = {
  RESET,
  OPEN,
  CLOSE,
  FETCH,
};
