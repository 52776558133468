import { flow } from 'lodash';

import { NAME } from './dialogChangePassword.constant';

import { selectProfileState } from '../profile.selector';

const selectStateKey = (state) => state[NAME];
export const selectState = flow(selectProfileState, selectStateKey);

const selectVisibleKey = (state) => state.visible;
const selectVariantKey = (state) => state.variant;
const selectPayloadKey = (state) => state.payload;

export const selectVisible = flow(selectState, selectVisibleKey);
export const selectVariant = flow(selectState, selectVariantKey);
export const selectPayload = flow(selectState, selectPayloadKey);
