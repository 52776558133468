import React from 'react';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { PAYMENT_TYPE } from '@giro-app/modules/checkout/constants/paymentType.constant';

import theme from '@giro/client-app/src/constants/theme.constant';

import useCheckout from '@giro-app/modules/checkout/hooks/useCheckout.hook';

import FormCheckoutPaymentCardComponent, {
  FormCheckoutPaymentCardSchema,
} from './FormCheckoutPaymentCard.component';

const FormCheckoutPaymentDialog = ({ amount, handleRemove, max }) => {
  const { submitForm, values, errors } = useFormikContext();

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <FormCheckoutPaymentCardComponent
        name="firstCard"
        amount={(values as any)?.firstCard?.amount || amount}
        max={max}
      />

      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={3} mt={3}>
        <Button
          variant="outlined"
          size="large"
          onClick={handleRemove}
          sx={{
            ...theme.typography.body_lg,
            borderColor: 'error.main',
            color: 'error.main',
            fontWeight: 500,
            '&:hover': { borderColor: 'error.dark' },
          }}
        >
          Remover
        </Button>
        <Button
          variant="contained"
          size="large"
          disabled={Object.keys(errors).length > 0}
          onClick={submitForm}
          sx={{
            ...theme.typography.body_lg,
            backgroundColor: 'success.main',
            color: 'white',
            fontWeight: 500,
            '&:hover': { backgroundColor: 'success.dark' },
          }}
        >
          Salvar
        </Button>
      </Box>
    </Box>
  );
};

const FormCheckoutPaymentDialogComponent = ({ handleClose }) => {
  const checkout = useCheckout();

  const handleRemove = () => {
    checkout.handleResetCard();

    handleClose();
  };

  return (
    <Formik
      initialValues={{
        firstCard: checkout.formData.payment.firstCard,
      }}
      validationSchema={Yup.object({
        firstCard: Yup.object().concat(FormCheckoutPaymentCardSchema),
      })}
      enableReinitialize
      onSubmit={async (values) => {
        checkout.handleUpdateCard(values);

        handleClose();

        if (Number(values.firstCard.amount) === checkout.totalSelected) {
          checkout.handleUpdatePaymentType(PAYMENT_TYPE.CARD_ONE);
        }

        return true;
      }}
    >
      <FormCheckoutPaymentDialog
        amount={checkout.formData.payment.firstCard.amount}
        max={checkout.totalSelected}
        handleRemove={handleRemove}
      />
    </Formik>
  );
};

export default FormCheckoutPaymentDialogComponent;
