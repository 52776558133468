import reducer from './dialogPreAuth.reducer';
import * as action from './dialogPreAuth.action';
import * as selector from './dialogPreAuth.selector';
import * as constant from './dialogPreAuth.constant';
import initialState from './dialogPreAuth.initialState';
import subscriber from './dialogPreAuth.subscriber';
import saga from './dialogPreAuth.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
