import fetch from '@giro/shared-utils/fetch.util';

export default async function postApiCheckoutsReferenceIdVerificationsService(
  reference_id,
  token
) {
  const url = `checkouts/${reference_id}/verifications`;

  return fetch(url, {
    method: 'POST',
    auth: true,
    ms: 'CHECKOUT',
    body: JSON.stringify({ code: token }),
  });
}
