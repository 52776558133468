import { combineReducers } from 'redux';

import dialogCardDetail from './dialogCardDetail';
import dialogErrorPayment from './dialogErrorPayment';
import dialogSendReceipt from './dialogSendReceipt';
import dialogPreAuth from './dialogPreAuth';
import dialogConfirmEmail from './dialogConfirmEmail';

const reducer = combineReducers({
  [dialogCardDetail.name]: dialogCardDetail.reducer,
  [dialogErrorPayment.name]: dialogErrorPayment.reducer,
  [dialogPreAuth.name]: dialogPreAuth.reducer,
  [dialogSendReceipt.name]: dialogSendReceipt.reducer,
  [dialogConfirmEmail.name]: dialogConfirmEmail.reducer,
});

export default reducer;
