import React from 'react';
import { Formik, useFormikContext, Field } from 'formik';
import { Box } from '@mui/material';
import { TextField } from 'formik-mui';
import Button from '@mui/lab/LoadingButton';
import * as yup from 'yup';

import messages from '@giro/shared-constants/messagesSchema.constant';

const validationSchema = yup.object({
  password: yup.string().required(messages.REQUIRED),
  confirmPassword: yup
    .string()
    .required(messages.REQUIRED)
    .oneOf([yup.ref('password'), null], messages.PASSWORD_VERIFICATION_EQUALS),
});

const FormUpdatePassword = ({ loading }) => {
  const { submitForm } = useFormikContext();

  return (
    <Box display="grid" gap={3} gridTemplateColumns="1fr">
      <Box display="grid" gridTemplateColumns="1fr" gap={3}>
        <Field
          name="password"
          component={TextField}
          fullWidth
          label="Nova senha"
          type="password"
        />
        <Field
          name="confirmPassword"
          component={TextField}
          fullWidth
          label="Confirmar senha"
          type="password"
        />
      </Box>
      <Box mt={3}>
        <Button
          loading={loading}
          disabled={loading}
          fullWidth
          variant="contained"
          sx={{ backgroundColor: 'success.main' }}
          onClick={submitForm}
        >
          Atualizar senha
        </Button>
      </Box>
    </Box>
  );
};

const FormUpdatePasswordComponent = ({ handleUpdatePassword, loading }) => {
  return (
    <Formik
      initialValues={validationSchema.cast(undefined)}
      onSubmit={handleUpdatePassword}
      validationSchema={validationSchema}
    >
      <FormUpdatePassword loading={loading} />
    </Formik>
  );
};

export default FormUpdatePasswordComponent;
