import { useSelector } from 'react-redux';

import useListDebitsHook from '@giro-app/modules/debts/hooks/useListDebits.hook';
import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import appsStore from '@giro-app/store/apps';

import verifyOnboardingUtil from '@giro-app/utils/verifyOnboarding.util';

export default function useVerifyOnboardingHook(integration_id?: string): {
  checked_onboarding: boolean;
  items: any[];
} {
  const auth = useAuthHook();
  const apps = useSelector(appsStore.selector.selectState);
  const listDebits = useListDebitsHook();

  return verifyOnboardingUtil(
    integration_id || listDebits?.filters?.integration_id,
    apps,
    auth
  );
}
