import flow from 'lodash/flow';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import states from '@giro/shared-constants/states.constant';

import useQuery from '@giro/shared-hooks/useQuery.hook';

import FormSelectorDebitComponent from './Form/FormSelectorDebitDetran.component';

import apps from '../../../store/apps';
import { Typography } from '@mui/material';

const BoxSearchDebitComponent = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [currentDetran, setCurrentDetran] = useState(
    query.get('integration_id') || undefined
  );

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
  };

  const currentDetranImage = selectorAppsRedux?.data?.find(
    (a) => a.id === currentDetran
  )?.logo_path;

  const currentDetranDescription = selectorAppsRedux?.data?.find(
    (a) => a.id === currentDetran
  )?.description;

  const currentDetranObj = states.find(
    (e) => e.nome === currentDetranDescription
  );

  return (
    <Box
      width="100%"
      gridTemplateColumns={{ md: '3fr 4fr', xs: '1fr' }}
      display="grid"
      gap="20px"
    >
      <Box
        flexDirection="column"
        display="flex"
        bgcolor="white"
        borderRadius={1}
        gap={3}
      >
        <FormSelectorDebitComponent
          currentDetran={currentDetran}
          setCurrentDetran={setCurrentDetran}
        />
      </Box>

      <Box gridTemplateRows="2fr 4fr" display="grid" gap="20px">
        <Box
          px="32px"
          py="32px"
          sx={
            currentDetranObj
              ? {
                  border: '1px solid transparent',
                  borderColor: 'trasso_site.rosa',
                  borderRadius: 1,
                }
              : {}
          }
        >
          <img src={currentDetranImage} />

          {currentDetranObj && (
            <Typography color="trasso_site.roxo2" variant="h3" fontWeight={300}>
              <strong>
                Consulte seus débitos no DETRAN {currentDetranObj.sigla}
              </strong>
              <br />
              IPVA, Multas, Licenciamento, DPVAT e outras
            </Typography>
          )}
        </Box>

        {currentDetranObj && (
          <Box px="32px">
            <Typography color="trasso_site.roxo" variant="trasso_site_corpo">
              Empresa credenciada no DETRAN pela portaria N.750 de 18 de outubro
              de 2018.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BoxSearchDebitComponent;
