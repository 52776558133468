import fetch from '@giro/shared-utils/fetch.util';

export default async function getApiCheckoutsReferenceIdService(
  reference_id: string
) {
  return fetch(`checkouts/${reference_id}`, {
    method: 'GET',
    auth: true,
    ms: 'CHECKOUT',
  });
}
