export const NAME = 'payment';

const CONTEXT = 'MODULE::RECEIPTS::PAYMENT';

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const FETCH = {
  START: FETCH_START,
  END: FETCH_END,
  ERROR: FETCH_ERROR,
};

const RESET = {
  STATE: RESET_STATE,
};

export const ACTION_TYPES = {
  FETCH,
  RESET,
};
