import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

import useListDebitsHook from '../../hooks/useListDebits.hook';

const DialogSelectAllDebitsComponent = ({ visible, handleClose }) => {
  const { handleSelectAll, handleUpdateSelected, selected } =
    useListDebitsHook();

  const handleOnOk = () => {
    handleSelectAll();
    handleClose();
  };

  const handleOnCancel = () => {
    handleUpdateSelected(selected.slice(0, -1));
    handleClose();
  };

  return (
    <Dialog onClose={handleOnCancel} open={visible} maxWidth={'sm'}>
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <Box position="absolute" right={8} top={8}>
          <IconButton onClick={handleOnCancel}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box height={52} />

        <Box
          display="flex"
          flexDirection="column"
          minWidth={{ md: 400 }}
          gap={2}
        >
          <Typography align="center" variant="h3">
            Atenção
          </Typography>

          <Typography align="center">
            O débito que você selecionou necessita que você pague todos os
            outros débitos que estão em aberto.
          </Typography>

          <Box alignSelf="center" display="flex" gap={2}>
            <Button size="large" variant="contained" onClick={handleOnOk}>
              Ok
            </Button>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={handleOnCancel}
            >
              Desfazer
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSelectAllDebitsComponent;
