import { Fragment, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import BoxPersonalData from '../components/Box/BoxPersonalData.component';

const ProfileScreen = () => {
  return (
    <Fragment>
      <Box
        display="grid"
        gap={3}
        minHeight="calc(100vh - 409px)"
        alignItems="flex-start"
      >
        <Box
          position="sticky"
          top={{
            md: '20px',
            xs: '0px',
          }}
          sx={{
            position: {
              xs: 'relative',
              md: 'sticky',
            },
          }}
        >
          <BoxPersonalData />
        </Box>
      </Box>
    </Fragment>
  );
};

export default ProfileScreen;
