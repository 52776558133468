import { combineReducers } from 'redux';

import listDebts from './list';
import dialogs from './dialogs';

const reducer = combineReducers({
  [dialogs.name]: dialogs.reducer,
  [listDebts.name]: listDebts.reducer,
});

export default reducer;
