import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, addMinutes } from 'date-fns';
import { flow, pickBy, omit, sortBy } from 'lodash';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';

import postApiInstallmentsService from '@giro/shared-services/checkout/postApiInstallments.service';

import useAuth from '@giro/shared-hooks/useAuth.hook';
import useCurrentUrl from '@giro-app/hooks/useCurrentUrl.hook';
import useQuery from '@giro/shared-hooks/useQuery.hook';
import useVerifyOnboardingHook from '@giro-app/hooks/useVerifyOnboarding.hook';

import themeConstants from '@giro/client-app/src/constants/theme.constant';

import ROUTES from '@giro-app/constants/routes.constant';

import router from '@giro/shared-store/router';

import useListDebitsHook from '../hooks/useListDebits.hook';

import checkout from '../../checkout/store/checkout';

import apps from '@giro-app/store/apps';

import list from '../store/list';

const maskPhone = (phone) =>
  String(phone).replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');

const maskDocument = (document) => {
  const stringDocument = String(document);

  if (stringDocument.length === 11) {
    return stringDocument.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
      '$1.$2.$3-$4'
    );
  }
  if (stringDocument.length === 14) {
    return stringDocument.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
      '$1.$2.$3/$4-$5'
    );
  }
};

const formatLabelItem = (label) => {
  const normalize = {
    type: 'Tipo',
    document: 'Documento',
    total: 'Total',
    name: 'Nome',
    phone: 'Telefone',
    rg: 'RG',
    cpf: 'CPF',
    plate: 'Placa',
    company: 'Marca',
    model: 'Modelo',
    year: 'Ano',
    service: 'Serviço',
    material: 'Material',
    client: 'Cliente',
    renavam: 'Renavam',
  };

  return normalize[label] || label;
};

const formatValueItem = (label, value) => {
  const currency = (value) =>
    `${Number(value).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })}`;

  const normalize = {
    total: currency,
    service: currency,
    material: currency,
    document: maskDocument,
    cpf: maskDocument,
  };

  return normalize[label] ? normalize[label](value) : value;
};

const getTaxFormatted = (value) =>
  Number(value).toLocaleString('pt-br', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

function formatDate(date, formatter) {
  return format(addMinutes(date, date.getTimezoneOffset()), formatter);
}

const ItemBillet = ({ label, value, ...restProps }) => (
  <Box display="flex" flexDirection="column" {...restProps}>
    <Typography variant="subtitle2" color="gray.400">
      {label}
    </Typography>
    <Typography variant="subtitle1" color="primary.main">
      {value}
    </Typography>
  </Box>
);

const ReferenceComponent = () => {
  const { checked_onboarding } = useVerifyOnboardingHook();

  const query = useQuery();

  const [loadingInstallment, setLoadingInstallment] = React.useState(false);
  const [installments, setInstallments] = React.useState([]);

  const dispatch = useDispatch();
  const { results = [], filters, metadata } = useListDebitsHook();

  const isExternal = query.get('external') === 'true';

  const billet = results[0];

  const dispatchCheckoutRedux = {
    updateDebit: flow(checkout.action.updateDebit, dispatch),
    updateApp: flow(checkout.action.updateApp, dispatch),
  };

  const selectorAppsRedux = {
    data: useSelector(apps.selector.selectData),
    isPartner: useSelector(list.selector.selectIsPartner),
  };

  const dispatchRouterRedux = {
    push: flow(router.action.push, dispatch),
  };

  const dispatchRedux = {
    servicePost: flow(list.action.servicePostTokenPartner, dispatch),
  };

  const handleGetInstallments = async (value) => {
    setLoadingInstallment(true);

    try {
      const [success, result] = await postApiInstallmentsService({
        integration_id: query.get('integration_id') as any,
        amount: value,
        type: 'CARTAO',
      });

      if (success) {
        const resultParsed = result?.map((r) => ({
          ...r,
          tax_percent: getTaxFormatted((r.amount_total - value) / value),
        }));

        setInstallments(resultParsed);
      }
    } catch {
      return false;
    }

    setLoadingInstallment(false);
  };

  React.useEffect(() => {
    if (isExternal) {
      (document as any).querySelector('#btnpay').click();
    }
  }, []);

  React.useEffect(() => {
    handleGetInstallments(billet?.amount);
  }, []);

  React.useEffect(() => {
    if (selectorAppsRedux.isPartner) {
      dispatchRedux.servicePost();
    }
  }, []);

  const handleClickPay = () => {
    const isPartnerBool = selectorAppsRedux.isPartner === 'true';

    if (filters?.integration_id) {
      Cookies.set(
        process.env.REACT_APP_COOKIE_KEY_INTEGRATION_ID,
        filters?.integration_id,
        {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        }
      );
    }

    if (isPartnerBool && checked_onboarding === false) {
      (window as any).location.href = process.env.REACT_APP_ONBOARDING_URL;

      return null;
    }

    dispatchCheckoutRedux.updateDebit({
      integration_id: filters?.integration_id,
      reference_id: metadata?.reference_id,
      installment: installments[0],
      debits: results,
    });

    const app = selectorAppsRedux.data?.find(
      (a) => a.id === filters?.integration_id
    );

    if (app) {
      dispatchCheckoutRedux.updateApp(app);
    }

    return dispatchRouterRedux.push(ROUTES.CHECKOUT.ROOT);
  };

  const currentApp = selectorAppsRedux.data?.find(
    (a) => a.id === filters?.integration_id
  );

  return (
    <>
      <Box display="grid" gridTemplateColumns="1fr" gap={2}>
        <Box display="flex" alignItems="center" justifyContent={'end'}>
          <Box>
            <Button
              id="btnpay"
              variant="contained"
              onClick={handleClickPay}
              sx={{
                backgroundColor: 'success.main',
                height: 48,
                width: 155,
                fontSize: themeConstants.typography.body1.fontSize,
              }}
            >
              Pagar
            </Button>
          </Box>
        </Box>
        <Box
          width="100%"
          border="1px solid black"
          borderColor="gray.200"
          p={4}
          borderRadius={2}
          gap={2}
          display="grid"
          gridTemplateColumns="1fr"
        >
          {currentApp?.logo_path && (
            <Box mb={5} justifySelf="center">
              <img
                src={currentApp?.logo_path}
                alt="logo"
                style={{ maxHeight: 200, maxWidth: 300 }}
              />
            </Box>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1" fontWeight={700} color="primary.main">
              Total a pagar
            </Typography>
            <Box px={1} py={0.5} bgcolor="info.light3" borderRadius="2px">
              <Typography variant="body1" fontWeight={700} color="primary.main">
                {Number(billet.amount).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Box>
          </Box>
          <ItemBillet label="Descrição" value={currentApp?.description} />
          {sortBy(
            Object.keys(
              pickBy(
                omit(billet?.fields, [
                  'type_tax',
                  'segment',
                  'tax',
                  'fees',
                  'amount',
                  'total_base',
                  'integration_id',
                ])
              )
            )
          ).map((f) => (
            <ItemBillet
              key={f}
              label={formatLabelItem(f)}
              value={formatValueItem(f, billet?.fields[f])}
            />
          ))}
        </Box>
      </Box>

      {isExternal && (
        <Box
          display="flex"
          width="100%"
          height="100%"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          alignItems="center"
          justifyContent={'center'}
          bgcolor="white"
          zIndex={999}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ReferenceComponent;
