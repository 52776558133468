import React from 'react';

import SelectorComponent from '../components/Selector.component';

const SelectorScreen = () => {
  return (
    <>
      <SelectorComponent />
    </>
  );
};

export default SelectorScreen;
